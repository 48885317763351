
import QrCodeIcon from '@mui/icons-material/QrCode';
import contacts from "../../imgs/Contacts.svg";

const StatsCard = ({ total, title, bgColor }) => {

    return (
      <section
        className={`flex w-full h-52 rounded-3xl px-6 py-6 justify-between gap-2 text-primary-white ${bgColor}`}
      >
          <h3 className="text-white font-bold text-xl">{title}</h3>
          <h3 className="text-white font-bold text-2xl flex gap-2 items-center self-end">
            {total}
            {title === 'Nombre de Scans Total' ? <QrCodeIcon fontSize='medium'/> : <img src={contacts} alt="contact icon" width={25} height={25}/>}
          </h3>
      </section>
    );
  };
  
  export default StatsCard;
  