import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, LabelList } from 'recharts';

// Formatting functions
const formatUserPercentages = (userPercentages) => {
  if (!userPercentages) return [];
  
  const formattedData = [];
  Object.entries(userPercentages).forEach(([user, years]) => {
    Object.entries(years).forEach(([year, months]) => {
      Object.entries(months).forEach(([month, value]) => {
        formattedData.push({ user: user.split('-')[1], year, month, value, monthYear: `${month}/${year}` });
      });
    });
  });
  formattedData.sort((a, b) => {
    if (a.user !== b.user) return a.user.localeCompare(b.user);
    if (a.year !== b.year) return a.year - b.year;
    return a.month - b.month;
  });
  return formattedData;
};

const formatTotalPercentages = (percentagesPerMonth) => {
  if (!percentagesPerMonth) return [];
  
  const formattedData = [];
  Object.entries(percentagesPerMonth).forEach(([year, months]) => {
    Object.entries(months).forEach(([month, value]) => {
      formattedData.push({ year, month, value, monthYear: `${month}/${year}` });
    });
  });
  // Sort by year and month
  formattedData.sort((a, b) => {
    if (a.year !== b.year) return a.year - b.year;
    return a.month - b.month;
  });
  return formattedData;
};

const CustomizedYAxisTick = ({ x, y, payload, index, userData }) => {
  const user = userData[index]?.user;
  const month = userData[index]?.month;
  const words = user.split(' ');

   // Calculate vertical position for user names
   const textSpacing = 16; // Space between lines of text
  const monthBottomMargin = 20; // Space between month and user names

  return (
    <g transform={`translate(${x},${y})`}>
      {/* Month Text */}
      <text x={0} y={0} textAnchor="end" fill="#666" fontSize="10px">
        {month}
      </text>
      {/* User Name Text */}
      {(index === userData.length - 1 || user !== userData[index + 1]?.user) && (
        <text x={0} y={-40} textAnchor="end" fill="#000" fontSize="9px">
          {user}
        </text>
      )}
    </g>
  );
};

const LineChart = ({ data, isShortDuration, filter }) => {
  const userData = formatUserPercentages(data?.user_percentages); //data.user_percentages
  const totalData = formatTotalPercentages(data?.percenatges_per_month);

  if (!data) {
    // Display a loading indicator or a placeholder until the data is ready
    return <div>Loading...</div>;
  }

  return (
    <div style={{ width: '100%', height: 400 }}>
      {isShortDuration <= 3 ? (
        <div style={{ height: '100%', overflowY: 'auto', display:'flex', justifyContent:'flex-end' }}>
          <div style={{ width: '95%', height: '100%', minHeight: 400 }}>
            <ResponsiveContainer style={{maxWidth:'90%'}}>
              <BarChart data={userData} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis />
                <YAxis dataKey="user" type="category" tick={({ payload, ...props }) => <CustomizedYAxisTick {...props} payload={payload} userData={userData} />} />
                <Tooltip 
                  formatter={(value, name, props) => {
                    const { user } = props.payload;
                    return [`${value}%`, `Percentage - ${user}`];
                  }} 
                  labelFormatter={(label, payload) => {
                    if (payload.length > 0) {
                      const { monthYear } = payload[0].payload;
                      return monthYear;
                    }
                    return label;
                  }}
                />
                <Bar dataKey="value" fill="#8884d8">
                  <LabelList dataKey="value" position="right" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <ResponsiveContainer>
          <BarChart data={totalData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="monthYear" />
            <YAxis />
            <Tooltip 
              formatter={(value) => [`${value}%`, 'Percentage']} 
              labelFormatter={(label, payload) => {
                if (payload.length > 0) {
                  const { monthYear } = payload[0].payload;
                  return monthYear;
                }
                return label;
              }}
            />
            <Bar dataKey="value" fill="#82ca9d">
              <LabelList dataKey="value" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default LineChart;
