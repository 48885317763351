import { Typography, Box } from '@mui/material'
import { colors } from '../../theme/Colors';
import { useState } from 'react';

const LanguageToggler = ({bgColor, color, onToggle}) => {
    const [activeLanguage, setActiveLanguage] = useState('fr');

    const toggleLanguage = (language) => {
        setActiveLanguage(language);
        onToggle(language)
    };

    return ( 
        <Box 
            display={'flex'} 
            alignItems={'center'}
            justifyContent={'center'}
            width={'fit-content'}
            sx={{
                backgroundColor: colors.smokeGray,
                borderRadius: '18px',
                px:1,
                py:.5
            }}
        >
            {/* French */}
            <div onClick={() => toggleLanguage('fr')}>
                <Typography variant="body2" fontSize="12px" px={2} py={1} 
                    color={activeLanguage == 'fr' ? color : 'black'}
                    sx={{
                        display:'flex',
                        backgroundColor: activeLanguage == 'fr' ? bgColor : 'transparent',
                        alignItems:'center',
                        gap:1,
                        fontWeight:500,
                        borderRadius:'18px',
                        transition: 'background-color 0.3s ease, color 0.3s ease'
                    }}>
                    FR
                    {activeLanguage === 'fr' && (
                        <span 
                            style={{ 
                                display: 'inline-block', 
                                width: '12px', 
                                height: '12px', 
                                borderRadius: '50%',
                                backgroundColor: color 
                            }}>
                        </span>
                    )}
                </Typography>
            </div>
            
            {/* English */}
            <div onClick={() => toggleLanguage('en')}>
                <Typography variant="body2" fontSize="12px" px={2} py={1} 
                    color={activeLanguage === 'en' ? color : 'black'}
                    sx={{
                        display:'flex',
                        backgroundColor: activeLanguage === 'en' ? bgColor : 'transparent',
                        alignItems:'center',
                        gap:1,
                        fontWeight:500,
                        borderRadius:'18px',
                        transition: 'background-color 0.3s ease, color 0.3s ease'
                    }}>
                    EN
                    {activeLanguage === 'en' && (
                        <span style={{ 
                            display: 'inline-block', 
                            width: '12px', 
                            height: '12px', 
                            borderRadius: '50%',
                            backgroundColor: color 
                        }}></span>
                    )}
                </Typography>
            </div>
        </Box>
     );
}
 
export default LanguageToggler;
