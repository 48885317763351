import ButtonDataGrid from "../components/ui/ButtonDataGrid";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { chipSelector } from "../utils/chipSelector";
import React from "react";
import { colors } from "../theme/Colors";
function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  } else return "";
}
export const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 60,
    headerClassName: "grid-header",
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", height: "100%", color: "inherit", width: "100%" }} to={`/admin/gestion-des-cartes/${params.row.id}`}>
            <Box sx={{ width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}>{params.row.id}</Box>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "last_name",
    headerName: "Nom",
    description: "This column has a value getter and is not sortable.",
    width: 130,
    align: "left",
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", height: "100%", color: "inherit", width: "100%" }} to={`/admin/gestion-des-cartes/${params.row.id}`}>
            <Box sx={{ width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center"}}>
              {params.row.last_name?.toUpperCase()}
              </Box>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "first_name",
    headerName: "Prénom",
    width: 130,
    renderCell: (params) => {
      return capitalizeFirstLetter(params.row.first_name?.split(" ")[0]) + " " + capitalizeFirstLetter(params.row.first_name?.split(" ")[1]);
    },
  },
  {
    field: "email",
    headerName: "Email",
    description: "Email du collaborateur.",
    width: 200,
  },

  {
    field: "phone_number",
    headerName: "Téléphone",
    description: "Numéro de téléphone du collaborateur.",
    width: 130,
  },

  {
    field: "department",
    headerName: "Département",
    description: "Département du collaborateur.",
    width: 130,
    align: "left",
  },

  {
    field: "function",
    headerName: "Fonction",
    description: "Fonction du collaborateur.",
    width: 130,
    align: "left",
  },

  {
    field: "expiration_date",
    headerName: "Date d'expiration",
    description: "Date d'expiration de la carte.",
    width: 130,
    align: "left",
    renderCell: (params) => {
      return params.row.expiration_date ? params.row?.expiration_date?.split("-")[2] + "/" + params.row?.expiration_date?.split("-")[1] + "/" + params.row?.expiration_date?.split("-")[0] : null;
    },
  },

  {
    field: "status_card",
    headerName: "Status carte",
    description: "Statut de la carte collaborateur.",
    width: 130,
    align: "center",
    renderCell: (params) => {
      return chipSelector(params.row.status_card);
    },
  },

  {
    field: "action",
    headerName: "Action",
    description: "This column has a value getter and is not sortable.",
    align: "center",

    renderCell: (params) => {
      return (
        <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
          <Link to={`/admin/gestion-des-cartes/${params.row.id}`}>
            <ButtonDataGrid action={"consult"} onClick={() => {}} />
          </Link>
        </Box>
      );
    },

    width: 130,
  },
];

export const rows = [
  {
    id: 1,
    collaborated_enterprise_commercial_name: "TELECOMMUNICATION",
    first_name: "DJEZZY",
    last_name: "DJEZZY",
    phone_number: "65412319848961532",
    department: "123123564941532123",
    function: "Algiers",
    expiration_date: "Algérie",
    action: ["consult"],
    email: "e@bdigitall.com",
    status_card: "active",
  },
];
