import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Api from "../../api/Api";
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from "@mui/icons-material";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast,ToastContainer } from "react-toastify";
import "./styles/StyledTable.css";
import ArrowBack from "@mui/icons-material/ArrowBack";

function Services() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const [services, setServices] = useState([]);
  const [i, setI] = useState(false);
  const [pageId, setPageId] = useState("");
  const location = useLocation();
  const [serviceToDelete, setServiceToDelete] = useState(null);

  async function getServices() {
    try {
      const pathname = location.pathname.split("/");
      const pageId = pathname[2];
      setPageId(pathname[2]);
      const response = await Api.get(`/pages/${pageId}`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      });

      setServices(response.data.services ?? []);
    } catch (error) {
      console.log(error);
    }
  }
  const HandleDelete = async (id) =>{
    try {
      const res = await Api.delete('services/' + id,{
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      })
      const data = res.data
      if(data.status === 'success'){
        getServices()
        toast.success('Deleted Successfully !', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress:undefined ,
          theme: "dark",
        });
        
    }
    } 
    catch (error) {
      toast.error(error.message)
    }
}

  useEffect(() => {
    if (!i) {
      getServices()
      setI(true);
    }
  }, []);

  return (
    <div>
      <div className="title-button">
        <div className="flex items-center justify-center gap-2">
        <NavLink
            to={"/admin/pages/"}
            className="bg-[#7B3FFF] p-1 text-white rounded-full "
          >
            <ArrowBack/>
          </NavLink>
          <h1 className="font-bold text-xl">
          Services
        </h1>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            justifyItems: "center",
            alignItems:'center',
            gap: 10,
          }}
          className="mb-4"
        >
          <NavLink
            to={"/admin/page/" + pageId + "/service"}
            className="bg-[#7B3FFF] p-2 text-white rounded-lg "
          >
            Ajouter un Service
          </NavLink>
        </div>
       
      </div>
      <div className="grid gap-3 grid-cols-1 order-last md:grid-cols-2 lg:grid-cols-3">
        {services?.length === 0 ? (
          <div>No Service found</div>
        ) : (
          services?.map((service) => {
            return (
              <div
                key={service.id}
                style={{
                  color: "#353535",
                  textDecoration: "none",
                  padding: 12,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className=" drop-shadow-lg shadow-gray-400 shadow-lg rounded-xl"
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={service?.url_image}
                      alt="service_picture"
                      style={{
                        backgroundSize: "cover",
                        width: 100,
                        height: 100,
                        borderRadius: 99,
                        marginTop:'1.5rem'
                      }}
                    />
                  </div>
                  <div className="page-inner-section relative flex justify-between items-center">
                    <div className="flex flex-col gap-1">
                      <h3 className="font-bold text-lg pt-7 pr-2">{service.name}</h3>
                      <p className="font-bold text-sm">{service.display_type === 'INTERNAL' ? 'Interieur' : 'Exterieur'}</p>
                    </div>
                    {service.status === 'ACCEPTED' ? <h3 className= "text-white p-1.5 bg-green-600 w-auto h-auto rounded-full text-sm  font-bold">
                      Active
                    </h3>
                    : 
                    <h3 data-tooltip-target="tooltip-inactive" className= "text-white p-1.5 bg-red-600 w-auto h-auto rounded-full text-sm font-bold">
                      Inactive
                    </h3>}
                  </div>                  
                </div>
                <div
                  style={{
                    marginTop: 6,
                    width:'100%',
                    display: "flex",
                    alignItems:'center',
                    justifyContent: "end",
                    gap:'1rem'
                  }}
                >

              <NavLink
                to={"/admin/page/" + pageId + "/service/" + service.id}
                className={'bg-gray-600 text-white p-1 rounded-full'}
              >
               <EditIcon/>
              </NavLink>
                  <button onClick={() => setServiceToDelete(service.id)} className="bg-red-500 text-white p-1 rounded-full font-bold">
                    <Delete/>
                  </button>
                </div>
                <ConfirmationModal
                    title="Supprimer une page"
                    message="Êtes-vous sûr de bien vouloir supprimer cette page ?"
                    acceptLabel="Oui"
                    refuseLabel="Non"
                    open={serviceToDelete === service.id}
                    onClose={()=> setServiceToDelete(null)}
                    acceptMethod={()=>HandleDelete(service.id)}
                />
              </div>
            );
          })
        )}
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Services;
