import { Container, TextField, Checkbox, useMediaQuery, ListItem, List, Collapse, CircularProgress, Tabs, Tab, FormControl, Switch } from "@mui/material";
import React from "react";
import MetaTags from 'react-meta-tags';
import MzErrorText from "../../components/ui/MzErrorText";
import { useState, useEffect, useRef } from "react";
import { Box, Button, Typography, Avatar, Modal } from "@mui/material";
import { colors } from "../../theme/Colors";
import Api from "../../api/Api";
import API from "../../api/Api_1_3";
import { Urls } from "../../api/Urls";
import bdigitall from "../../imgs/BDIGITALL.svg";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LanguageIcon from '@mui/icons-material/Language';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ShareIcon from '@mui/icons-material/Share';
import Links from "../../components/ui/IconLinkPublicProfile";
import { ModalStyleQRCODE } from "../../theme/ModalStyleQRCODE";
import IconLinkRow from "../../components/ui/IconLinkRow";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Add, ExpandLess } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Send from '@mui/icons-material/SendOutlined';
import ContactList from '@mui/icons-material/ContactsOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Mosaic } from "react-loading-indicators";
import { useTheme } from "@mui/material/styles";
import userIcon from "../../imgs/user.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CompanyIcon from '../../imgs/logo.png'
import { MenuItem, Select } from "@mui/material"; // Add Select and MenuItem imports
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import LinkIcon from '@mui/icons-material/Link';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LaunchIcon from '@mui/icons-material/Launch';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import EditIcon from '@mui/icons-material/Edit';
import WebIcon from '@mui/icons-material/Web';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import PlaceIcon from '@mui/icons-material/Place';
import * as Yup from 'yup';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton'
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LanguageToggler from "../../components/ui/LanguageToggler";

function AbonneeApercue() {
  //const { user } = useAuthContext();
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [image, setImage] = useState();
  const [data, setData] = useState();
  const [links, setLinks] = useState();
  const [docs, setDocs] = useState();
  const [isEng, setIsEng] = useState('fr')
  const [currentProfile, setCurrentProfile] = useState(null);
  const [defaultProfile, setDefaultProfile] = useState(); // New state for current profile
  const [profiles, setProfiles] = useState([]); // New state for profiles
  const [showProfileModal, setShowProfileModal] = useState(false);

  const [policy, setPolicy] = useState(false);

  //loading state
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false)
  //modal config
  const [error, setError] = useState(false);
  const [errornotfound, setErroNotFound] = useState(false);
  const imageProfile = useRef();
  

  //on page load (load user details)
  useEffect(() => {
    Api.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${id}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        setData(response.data?.user);
        setDefaultProfile(response.data?.user)
        if(response.data?.user?.profiles && response.data?.user?.profiles.length > 0 ){
            setProfiles(response.data?.user?.profiles);
        }
        setLinks(response.data?.user?.user_links || []); // Default to an empty array if undefined
        setDocs(response.data?.user?.documents || []); // Default to an empty array if undefined
      })
      .catch((error) => {
        if (error.response.status === 405) {
          setError(true);
        }
        if (error.response.status === 404) {
          setErroNotFound(true);
        }
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
      })
      .finally(()=>{
        setLoading(false);
      })
  }, []);


  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const share = () => {
    var browser = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "edge";
        case agent.indexOf("edg") > -1:
          return "chromium based edge (dev or canary)";
        case agent.indexOf("opr") > -1 && !!window.opr:
          return "opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome:
          return "chrome";
        case agent.indexOf("trident") > -1:
          return "ie";
        case agent.indexOf("firefox") > -1:
          return "firefox";
        case agent.indexOf("safari") > -1:
          return "safari";
        default:
          return "other";
      }
    })(window.navigator.userAgent.toLowerCase());

    const shareData = {
      title: "Partage de code QR",
      url: `${window.location.href}`,
      text: "Venez consulter mon profil sur BdigitAll !",
    };
    if (
      navigator.share &&
      navigator.canShare(shareData) &&
      browser !== "chromium based edge (dev or canary)" &&
      window.location.href.split("/")[0] === "https:"
    ) {
      navigator.share(shareData);
    } else {
      navigator["clipboard"].writeText(`${shareData.url}`);
      toast.success("Le lien a été copié !");
    }
  };



  const showForm = () =>{
    setOpenForm(!openForm)
  }

  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } else return "";
  }
  // funciton to get the image on base64
  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  const getvcf = () => {
    window.open(data?.url_vcf_file, "download");
  };

  const [form, setFormData] = useState({
    contact_group: "",
    first_name: "",
    last_name: "",
    email: "",
    function:"",
    phone: "",
    company_name: "",
    brochure: "",
    school: "",
    pre_notification: ""
  });

  const [progress, setProgress] = useState(0);

  const checkEmptyProperties = (obj) => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] === '' || obj[key] === null) {
          setIsError(true)
          return ; // Stop checking and return true if any property is empty string or null
        }
      }
    }
    setIsError(false)
    return false; // Return false if no property is empty string or null
  };

  async function onSubmitContact(values, { resetForm }) {
    const url = window.location;
    const formData = new FormData(); // Create FormData object
    const formDataEmail = new FormData()

    formData.append('contact_group',values.contact_group)
    formDataEmail.append('contact_group',values.contact_group)
    formDataEmail.append('email', values.email);
    formData.append('id',id)
    formDataEmail.append('id',id)
    // Check and append non-empty fields to formData
    if (values.phone) formData.append('phone', values.phone);
    if (values.last_name) formData.append('last_name', values.last_name);
    if (values.first_name) formData.append('first_name', values.first_name);
    if (values.email) formData.append('email', values.email);
    if (values.company_name) formData.append('company_name', values.company_name);
    if (values.function) formData.append('function', values.function);

    if (values.school){
      if(value === false) formData.append('school', values.school);
      if(value === true) formDataEmail.append('school', values.school);
    } 
    if (values.pre_notification){
      if(value === false) formData.append('pre_notification', values.pre_notification);
      if(value === true) formDataEmail.append('pre_notification', values.pre_notification);
    }

    if (values.brochure) {
      if(value === false) formData.append('brochure', values.brochure);
      if(value === true) formDataEmail.append('brochure', values.brochure);
    }

    setSending(true);

    let response;
    try {
        if (value === false) {
            response = await Api.post(Urls.ADD_CONTACTS, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set content type for FormData
                },
            });
        } else if (value === true) {
            response = await API.post('/contacts/store-existing-user-as-contact', formDataEmail);
        }

        if (response.status === 200) {
            toast.success("vous avez ajouté votre contact!");
            setProgress(0);
            resetForm();
        }

        setOpenForm(false);
    } catch (error) {
        if (value === true) {
            setError(error.response.data.message);
        }
        toast.error(error.response.data.message);
        setSending(false);
    } finally {
        setSending(false);
    }
  }
  
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isError,setIsError] = useState(false)

  const modalStyle = {
    position: 'absolute',
    top: isSmallScreen ? '50%' : '50%',
    left: isSmallScreen ? '50%' : '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? '90vw' : '400px',
    maxWidth: '500px',
    padding: '1.5rem',
    backgroundColor: '#FAFAFA',
    borderRadius: '5px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    maxHeight: '90vh',
    overflowY: 'auto',
  };

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [value, setValue] = useState(false);
  const [language, setLanguage] = useState('fr');


  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  const ctaText = language === 'fr' 
  ? { saveContact: `Enregistrer le contact de ${data?.first_name}`, sendInfo: `Envoyer vos coordonnées à ${data?.first_name}` }
  : { saveContact: `Save ${data?.first_name}'s contact`, sendInfo: `Send your info to ${data?.first_name}` };

const linksAndDocsText = language === 'fr' ? "Liens & Document" : "Links & Documents";

  const handleChangeTab = (event) => {
    setValue(event.target.checked);
  };

  const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#964CFC',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));


  const getImageStyle = () => {
    let width, height;

    if (isXsScreen || isSmScreen) {
      width = 110;
      height = 110;
    } else if (isMdScreen) {
      width = 120;
      height = 120;
    } else if (isLgScreen) {
      width = 135;
      height = 135;
    }

    return {
      width,
      height,
      objectFit: "cover",
    };
  };

  const getAvatarStyle = () => {
    let width, height;

    if (isXsScreen || isSmScreen) {
      width = 100;
      height = 100;
    } else if (isMdScreen) {
      width = 110;
      height = 110;
    } else if (isLgScreen) {
      width = 115;
      height = 115;
    }

    return {
      width,
      height,
      objectFit: "cover",
    };
  };

  const getProfilePictureStyle = () => {
    let width, height;

    if (isXsScreen || isSmScreen) {
      width = '140px';
      height = '140px';
    } else if (isMdScreen) {
      width = '200px';
      height = '200px';
    } else if (isLgScreen) {
      width = '200px';
      height = '200px';
    }

    return {
      width,
      height,
      objectFit: "contain",
      objectPosition: "50% 50%",
    };
  };

  const [isDefault,setIsDefault] = useState(true)
  const [selectedFileName, setSelectedFileName] = useState('')

  const handleProfileSwitch = (profile) => {
    setCurrentProfile(profile)
    setLinks(profile.links);
    setDocs(profile.documents);
    setShowProfileModal(false);
    setIsDefault(false)
    handleBackToMenu()
    handleMenuClose()
  };

  const handleSetDefault = () =>{
    setCurrentProfile(null)
    setLinks(defaultProfile?.user_links);
    setDocs(defaultProfile?.documents);
    setShowProfileModal(false);
    setIsDefault(true)
    handleBackToMenu()
    handleMenuClose()
  }

  const openProfileModal = () => {
    setShowProfileModal(true); // Open profile switch modal
  };

  const modalStyleProfile = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', 
    padding: '1.5rem',
    backgroundColor: colors.profile,
    borderRadius: '5px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    width: '400px', // Set a fixed width for the modal
    height:'fit-content'
  };

  const setCoverPicture = () => {
    if(currentProfile === null){
        return data?.url_cover_picture ?? data?.collaborated_enterprise?.url_cover_picture
    }
    else if(currentProfile !== null){
        return currentProfile?.url_business_cover_picture
    }
  }
  const coverPictureSrc = setCoverPicture();

  const validationSchema = Yup.object().shape({
    contact_group: Yup.string().required('Groupe de contact est requis'), // Add validation for contact_group
    first_name: (value === false ? Yup.string().required('Prénom est requis') : Yup.string().optional()),
    last_name: (value === false ? Yup.string().required('Nom est requis') : Yup.string().optional()),
    email: (value === false ? Yup.string().email('Email invalide').optional() : Yup.string().email('Email invalide').required('Email est requis')),
    phone: (value === false ? Yup.string().required('Numéro de téléphone est requis') : Yup.string().optional()),
    company_name: Yup.string().optional(),
    function: Yup.string().optional(),
    brochure: Yup.mixed().optional(),
    school: Yup.mixed().optional(),
    pre_notification: Yup.mixed().optional(),
  });

  const iconOptions = [
    { value: 'DatasetLinked', label: 'Dataset Linked', icon: <DatasetLinkedIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'Link', label: 'Link', icon: <LinkIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'LeakAdd', label: 'Leak Add', icon: <LeakAddIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'OpenInNew', label: 'Open In New', icon: <OpenInNewIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'Launch', label: 'Launch', icon: <LaunchIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'OfflineShare', label: 'Offline Share', icon: <OfflineShareIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'PhoneLinkRing', label: 'Phone Link Ring', icon: <PhonelinkRingIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'PhoneLink', label: 'Phone Link', icon: <PhonelinkIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'Web', label: 'Web', icon: <WebIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'Pen', label: 'Pen', icon: <EditIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'Card', label: 'Card', icon: <CreditCardIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'Profile', label: 'Profile', icon: <AccountCircleIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
    { value: 'Page', label: 'Page', icon: <DescriptionIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple }} /> }, // Updated icon color
  ];

  const getIcon = (url) => {
    return iconOptions.find(option => option.value === url.icon_tag)?.icon || null; // Return the icon if found, otherwise return null
  }

  const formText = language === 'fr' 
? {
    activity:'Mes autres Activités',
    commercial:'Commercial & Prospect',
    supplier:'Fournisseur',
    candidate:'Candidat de recrutement',
    student:'Etudiant',
    share:'Partager mon profil',
    qrcode:'Afficher mon code QR',
    title:'Laissez votre contact',
    isClient:'Déja Client',
    contactGroup: "Groupe de contact*",
    firstName: "Prénom*",
    lastName: "Nom*",
    phone: "Numéro de téléphone*",
    email: "Email*",
    emailOpt: "Email (optionnel)",
    companyName: "Nom de l'entreprise (optionnel)",
    function: "Fonction (optionnel)",
    school: "École",
    more:'Afficher Plus de détails',
    preNotification: "Pré-notification",
    brochure: "Votre Brochure",
    policy: "En soumettant, j'accepte la politique de confidentialité et le traitement de mes coordonnées. Vous pouvez révoquer ce consentement à tout moment.",
    submit: "Envoyer"
  }
: {
    activity:'My other Activities',
    commercial:'Commercial',
    supplier:'Supplier',
    candidate:'Recruiting Candidate',
    student:'Student',
    share:'Share my profile',
    qrcode: 'Show my QR code',
    title:'Share your contact',
    isClient:'Already a Client',
    contactGroup: "Contact Group*",
    firstName: "First Name*",
    lastName: "Last Name*",
    phone: "Phone Number*",
    email: "Email*",
    emailOpt: "Email (optional)",
    companyName: "Company Name (optional)",
    function: "Function (optional)",
    school: "School",
    more:'More details',
    preNotification: "Pre-notification",
    brochure: "Your Document",
    policy: "By submitting, I agree to the privacy policy and to the processing of my personal data. You may revoke this consent at any time.",
    submit: "Submit"
  };

const [anchorEl, setAnchorEl] = useState(null); 
const [showProfilesMenu, setShowProfilesMenu] = useState(false); 

// Function to handle menu opening
const handleMenuClick = (event) => {
  setAnchorEl(event.currentTarget);
};

// Function to handle back to main menu
const handleBackToMenu = () => {
  setShowProfilesMenu(false);
};

// Function to handle menu closing
const handleMenuClose = () => {
  setAnchorEl(null);
};

  return (
    <>
    <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
     { loading ? 
      <Box display={'flex'} alignItems={'center'} sx={{width:'100%',height:'100vh'}} justifyContent={'center'}>
        <Mosaic color="#8c31cc" size="medium" text="" textColor="" />
      </Box>
        :
        <Box
                  gap="1rem"
                  width="725px"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    position:'relative',
                  }}
                >
                  <MetaTags>
                    <title>{currentProfile !== null ? `${currentProfile?.business_commercial_name}` : `${defaultProfile?.first_name} ${defaultProfile?.last_name}`}</title>
                    <meta name="description" content={currentProfile !== null ? `${currentProfile?.function}` : `${defaultProfile?.function}`} />
                    <meta property="og:title" content={currentProfile !== null ? `${currentProfile?.business_commercial_name}` : `${defaultProfile?.first_name} ${defaultProfile?.last_name}`} />
                    <meta property="og:image" content={currentProfile ? currentProfile?.url_business_logo : defaultProfile?.url_profile_picture} />
                  </MetaTags>

                  {/* Upper Actions */}
                  <Box
                  display={'flex'}
                  gap={'.5rem'}
                  alignItems={'center'}
                  justifyContent={'end'}
                  sx={{
                    position: 'absolute',
                    top:9,
                    zIndex:100,
                    left:{
                      xs:'83%',
                      sm:'76%',
                      md:'90%',
                      lg:'90%',
                    }
                  }}
                 >
                    <IconButton onClick={handleMenuClick}>
                      <MenuIcon style={{ fontSize: 35, color: colors.carbon_black, backgroundColor: colors.textWhite, borderRadius:'18px', padding:'2px'  }} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      PaperProps={{
                        sx: {
                          borderRadius: '20px',
                          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                        },
                      }}
                    >
                      {showProfilesMenu ? (
                        <>
                          <MenuItem 
                            sx={{
                              padding: '6px 10px',
                              fontSize: '14px'
                            }} 
                            onClick={handleBackToMenu}>
                            <KeyboardArrowLeftIcon
                              style={{
                                fontSize: 31,
                                color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple,
                                paddingRight: 4,
                              }}
                            />
                            <Typography variant="body1" fontSize="14px">{formText.activity}</Typography>
                          </MenuItem>
                            {profiles && profiles.length > 0 && profiles.map(profile => (
                            <MenuItem key={profile.id} onClick={() => handleProfileSwitch(profile)} sx={{transition: 'background-color 0.3s ease, color 0.3s ease'}}>
                              <Box display="flex" flexDirection="column" alignItems={'flex-start'} justifyContent={'center'} gap={2}>
                                {/* profiles list */}
                                <Box key={profile.id} display="flex" flexDirection="column" alignItems="flex-start" justifyContent={'space-between'} position="relative">
                            <Box display="flex" flexDirection="row" gap={14} sx={{'cursor':'pointer'}} alignItems="center" justifyContent={'space-between'} onClick={() => handleProfileSwitch(profile)}>
                             <Box display={'flex'} gap={2}>
                             <img
                                src={profile.url_business_logo || CompanyIcon}
                                alt={profile.business_commercial_name}
                                style={{ width: '32px', height: '32px', objectFit: 'cover', borderRadius:'50%' }}
                              />
                              <Box sx={{'width':{
                                    xs:'8.5rem',
                                    sm:'8.5rem',
                                    md:'10rem',
                                    lg:'10rem',
                                    xl:'10rem'
                                  }}} 
                                gap={4} 
                                display={'flex'} 
                                alignItems={'center'} >
                                <Box>
                                  <Typography variant="body1" fontSize={'14px'}>{profile.business_commercial_name}</Typography>
                                  <Typography variant="body2" fontSize={'12px'} fontWeight={300}>{profile.function}</Typography>
                                </Box>
                              </Box>
                             </Box>

                              <Box display="flex" alignItems="center">
                                  {currentProfile?.id === profile.id && (
                                    <CheckCircleIcon
                                        style={{ color: colors.bd_Purple }} // Adjust color as needed
                                    />
                                  )}
                              </Box>
                            </Box>
                                </Box>
                              </Box>
                            </MenuItem>
                            ))}
                          <MenuItem>
                            {/* main profile for collaborator */}
                            <Box display="flex" flexDirection="row" gap={10} sx={{'cursor':'pointer'}} alignItems="center" onClick={() => handleSetDefault()}>
                              <Box display={'flex'} gap={2}>
                                <img
                                  src={defaultProfile?.collaborated_enterprise?.url_logo || CompanyIcon}
                                  alt={defaultProfile?.collaborated_enterprise?.commercial_name}
                                  style={{ width: '32px', height: '32px', objectFit: 'cover', borderRadius:'50%' }}
                                />
                                <Box sx={{'width':'100%'}} gap={4} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                  <Box>
                                    <Typography variant="body1"  fontSize={'14px'}>{defaultProfile?.collaborated_enterprise?.commercial_name}</Typography>
                                    <Typography variant="body2"  fontSize={'12px'}fontWeight={300}>{defaultProfile?.function}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box display="flex" alignItems="center">
                                  {isDefault && (
                                    <CheckCircleIcon
                                        style={{ color: colors.bd_Purple }} // Adjust color as needed
                                    />
                                  )}
                                </Box>
                            </Box>
                          </MenuItem>
                        </>
                      ) : (
                        <>
                          {(data?.hasOwnProperty('profiles') && profiles.length > 0) &&
                          <MenuItem
                            sx={{
                              padding: '6px 10px',
                              fontSize: '14px'
                            }}
                            onClick={() => setShowProfilesMenu(true)} // Show profiles menu
                          >
                            <FolderCopyIcon
                              style={{
                                fontSize: 35,
                                color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple,
                                padding: '4px 3px',
                                marginRight: '5px'
                              }}
                            />
                            {formText.activity} 
                            <KeyboardArrowRightIcon
                              style={{
                                fontSize: 31,
                                color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple,
                                paddingRight: 4,
                                marginLeft: '15px'
                              }}
                            />
                          </MenuItem>}

                          <MenuItem onClick={() => share()} 
                            sx={{
                              padding: '6px 10px',
                              fontSize: '14px'
                            }}
                          >
                            <ShareIcon
                              style={{
                                fontSize: 35,
                                color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple,
                                padding: '4px',
                                marginRight: '5px'
                              }}
                            />
                            {formText.share}
                          </MenuItem>
                          
                          <MenuItem onClick={() => setOpen(true)}
                            sx={{
                              padding: '6px 10px',
                              fontSize: '14px'
                            }}
                          >
                            <QrCodeIcon
                              style={{
                                fontSize: 35,
                                color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple,
                                padding: '4px',
                                marginRight: '5px'
                              }}
                            />
                            {formText.qrcode}
                          </MenuItem>
                          
                          {/* language toggler */}
                          <MenuItem
                            sx={{
                              padding: '6px 10px',
                              cursor:'normal'
                            }}
                          >
                            <Box display="flex" alignItems="center">
                              <LanguageToggler bgColor={data?.collaborated_enterprise?.default_background_color ?? colors.light_purple} color={data?.collaborated_enterprise?.default_text_color ?? colors.textWhite} onToggle={(language)=> handleLanguageChange(language)}/>
                            </Box>
                          </MenuItem>
                        </>
                      )}
                    </Menu>

                  </Box>

                  <Modal open={open} onClose={() => setOpen(false)}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={ModalStyleQRCODE}
                    >
                      <Avatar
                        sx={{
                          position: "relative",
                          width: "17rem",
                          height: "17rem",

                          marginBottom: "0rem",
                        }}
                        variant="rounded"
                      >
                        {data?.url_qrcode ? (
                          <img
                            alt="url_logo"
                            src={data?.url_qrcode}
                            style={{
                              width: "17rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        ) : (
                          <img
                            src={null}
                            alt="placeholder"
                            style={{
                              width: "17rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        )}
                      </Avatar>
                    </Box>
                  </Modal>
                  
                  {/* Contact Assets */}
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >

                      {/* Company logo */}
                      {
                        (typeof data?.url_cover_picture === 'string' || typeof currentProfile?.url_business_cover_picture === 'string' || typeof data?.collaborated_enterprise?.url_cover_picture === 'string') ?
                        // Custom Cover
                        <Box
                          display="flex"
                          sx={{
                            position: 'relative',
                            height: {
                              xs: 'auto',
                              sm: 'auto',
                              md: 'calc(725px * (72 / 145))',
                              lg: 'calc(725px * (72 / 145))',
                              xl: 'calc(725px * (72 / 145))'
                            },
                            width: {
                              xs: '100%',
                              sm: '100%',
                              md: '725px',
                              lg: '725px',
                              xl: '725px'
                            },
                            justifyContent: 'space-between',
                            marginBottom: 2
                          }}
                        >
                        <img
                          alt="company_cover"
                          width={'100%'}
                          height={'100%'}
                          src={coverPictureSrc}
                          style={{ objectFit: {
                              xs:'contain',
                              sm:'contain',
                              md:'cover',
                              lg:'cover',
                              xl:'cover'
                            }                         
                          }} // Ensure correct object fit and height
                        />
                        </Box>
                        :   
                        // Default Cover
                        <Box
                      display="flex"
                      width={'100%'}
                      sx={{
                        backgroundColor: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple_cover,
                        position: 'relative',
                        height: '224px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '1rem 1rem 0rem 1rem',
                      }}
                    >
        
                      <>
                        <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        color="white"
                      >
                        {/* Cover Data */}
                        <Box display={'flex'} mt={{
                          xs:-9,
                          sm:-9,
                          md:-10,
                          lg:-10,
                          xl:-10
                        }} 
                        flexDirection={'column'} gap={2} justifyContent={'flex-end'}>
                          <Typography variant="h4" fontWeight={400} color={data?.collaborated_enterprise?.default_text_color ??colors.textYellow}>
                            { currentProfile ? currentProfile?.business_commercial_name : data?.collaborated_enterprise?.commercial_name}
                          </Typography>
                        </Box>
                        </Box>
                        {/* Company Photo */}
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                        <Avatar
                          alt="company_logo"
                          sx={[getAvatarStyle(),{
                            position: "relative",
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            objectPosition: "50% 50%",
                            border: '1px solid white'
                          }]}
                          variant="rounded"
                        >
                          <img
                            alt="company_logo"
                            src={currentProfile ? currentProfile?.url_business_logo : data?.collaborated_enterprise?.url_logo !== null ? data?.collaborated_enterprise?.url_logo : (data?.collaborated_enterprise?.url_logo === null && currentProfile?.url_business_logo) ? CompanyIcon : 'https://placehold.co/500'}
                            style={getImageStyle()}
                          />
                        </Avatar>
                        </Box>
                      </>
                    
                        </Box>
                      }

                  {/* Contact profile picture */}
                  <Box
                    display="flex"
                    justifyContent={'center'}
                    sx={{
                      position: 'relative',
                      top:{
                        xs:-80,
                        sm:-80,
                        md:-105,
                        lg:-105,
                        xl:-105
                      }
                    }}
                  > 
                      <Avatar
                        sx={{
                          position: "relative",
                          borderRadius: "50%",
                          width: {
                            xs:'140px',
                            sm:'140px',
                            md:'200px',
                            lg:'200px',
                            xl:'200px'
                          },
                          height: {
                            xs:'140px',
                            sm:'140px',
                            md:'200px',
                            lg:'200px',
                            xl:'200px'
                          },
                          marginBottom: "1rem",
                          backgroundColor: "white",
                          border: '2px solid white'
                        }}
                        variant="rounded"
                      >
                        {data?.url_profile_picture === null ? (
                          <img
                            alt="logo"
                            src={userIcon}
                            style={getProfilePictureStyle()}
                          />
                        ) : (
                          <img
                            ref={imageProfile}
                            alt="logo"
                            src={data?.url_profile_picture}
                            style={getProfilePictureStyle()}
                          />
                        )}
                      </Avatar>
                  </Box>

                  </Box>
                  
                  <Box mt={{
                    xs:-12,
                    sm:-12,
                    md:-16,
                    lg:-16,
                    xl:-16
                  }} display={'flex'} flexDirection={'column'}>

                  {/* Contact data */}
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={{
                      xs:"3px",
                      sm:"3px",
                      md:"8px",
                      lg:"8px",
                      xl:"8px"
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: {
                          xs: "center",
                          sm: "center",
                          md: "center",
                          lg: "start",
                        },
                      }}
                      variant="h5"
                      fontWeight={600}
                      fontSize={{
                        xs: '18px',
                        sm: '18px',
                        md: '24px',
                        lg: '24px',
                      }}
                    >
                      {data?.first_name + ' ' + data?.last_name}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: {
                          xs: "center",
                          md: "center",
                          sm: "center",
                          lg: "center",
                        },
                        width:'80%'
                      }}
                      variant="h6"
                      fontWeight={300}
                      fontSize={{
                        xs: '16px',
                        sm: '16px',
                        md: '20px',
                        lg: '20px',
                      }}
                    >
                      {currentProfile ? currentProfile?.function : data?.function}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: {
                          xs: "center",
                          md: "center",
                          sm: "center",
                          lg: "center",
                        },
                        width:'80%'
                      }}
                      variant="body2"
                      fontWeight={500}
                      fontSize={{
                        xs: '12px',
                        sm: '12px',
                        md: '15px',
                        lg: '15px',
                      }}
                    >
                    {currentProfile?.address || currentProfile?.city_name || currentProfile?.country_name ? (
                        <>
                          <PlaceIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.light_purple }} />
                          {[
                            currentProfile.address,
                            currentProfile.city_name,
                            currentProfile.country_name
                          ].filter(Boolean).join(', ')}
                        </>
                      ) : data?.address || data?.city_name || data?.country_name ? (
                        <>
                          <PlaceIcon sx={{ color: data?.collaborated_enterprise?.default_background_color ?? colors.light_purple }} />
                          {[
                            data.address,
                            data.city_name,
                            data.country_name
                          ].filter(Boolean).join(', ')}
                        </>
                      ) : null}
                    </Typography>
                  </Box>

                  {links && (
                    <Box
                      gap="0.5rem"
                      width="100%"
                      display="flex"
                      mt={{
                        xs:'10px',
                        sm:'10px',
                        md:'16px',
                        lg:'16px',
                        xl:'16px'
                      }}
                      sx={{
                        alignItems: "center",
                        justifyContent: {
                          xs: "center",
                          md: "center",
                          sm: "center",
                          lg: "center",
                        },
                      }}
                    >
                      {links.map((l) => (
                        <IconLinkRow
                          key={l.id}
                          hide={l.link.title === "Site Web" ? true : false}
                          value={l.value}
                          icon={l.link.url_logo}
                          slug={l.link.slug}
                        />
                      ))}
                    </Box>
                  )}
                  
                  {/* Contact CTA */}
                  <Box
                    gap="1rem"
                    width="100%"
                    display="flex"
                    my={{
                      xs:'24px',
                      sm:'24px',
                      md:'30px',
                      lg:'30px',
                      xl:'30px'
                    }}
                    sx={{
                      alignItems: "center",
                      flexDirection: {
                        sm: "column-reverse",
                        md: "column-reverse",
                        lg: "row",
                        xs: "column-reverse",
                      },
                      justifyContent: {
                        lg: "space-between",
                        sm: "center",
                        md: "center",
                        xs: "center",
                      },
                      padding: '0px 16px'
                    }}
                  >
                    <Box
                      display="flex"
                      sx={{
                        marginTop: {
                          xs: ".5rem",
                          sm: ".5rem",
                          md: "1rem",
                          lg: "0rem",
                        },
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: {
                          xs: "column",
                          md: "column",
                          sm: "column",
                          lg: "row",
                        },
                        width: {
                          xs: "100%",
                          md: "100%",
                          sm: "100%",
                          lg: "100%",
                        },
                      }}
                      gap="1rem"
                    >
                      <a
                        href={data?.url_vcf_file}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          'display':'flex',
                          'alignItems':'center',
                          'gap':8,
                          'justifyContent':'center',
                          'borderRadius':'8px',
                          'width':'100%',
                          'padding':'14px',
                          'color': data?.collaborated_enterprise?.default_text_color ??'#FFF',
                          'backgroundColor':data?.collaborated_enterprise?.default_background_color ?? '#964CFC',
                          'textDecoration':'none',
                        }}
                      >
                        <ContactList sx={{color: data?.collaborated_enterprise?.default_text_color ?? colors.textWhite }}/>
                        <Typography fontSize="1rem">
                          {ctaText.saveContact}
                        </Typography>
                      </a>

                      <Button
                        onClick={showForm}
                        variant="secondary"
                        sx={{
                          alignItems: "center",
                          width: {
                            xs: "100%",
                            md: "100%",
                            sm: "100%",
                            lg: "100%",
                          },
                          borderColor: data?.collaborated_enterprise?.default_background_color ?? '#964CFC',
                          color: data?.collaborated_enterprise?.default_background_color ?? '#964CFC',
                          borderRadius: '8px',
                          padding:'11px',
                          gap:.5
                        }}
                      >
                        <Send sx={{color: data?.collaborated_enterprise?.default_background_color ?? colors.light_purple}}/>
                        {ctaText.sendInfo}
                      </Button>
                    </Box>
                  </Box>

                  {/* Links & Document */}
                  <Box sx={{ maxWidth: { xs: "100%" }, width: { md: "100%" }, padding: '0px 16px' }}>
                    <Typography variant="subtitle1" color="initial" fontSize={'20px'} fontWeight={600} mb={'12px'}>{linksAndDocsText}</Typography>
                    {(links?.length !== 0 || docs?.length !== 0) && (
                      <Box
                        width="100%"
                        sx={{
                          padding: "0.5rem",
                        }}
                      >
                        {links && (
                          <Box>
                            {/* Modal add link */}
                            {/* modal update link */}
                            {links?.map((l) => (
                              <Box
                                key={l.id}
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                sx={{ alignItems: "center" }}
                              >
                                <Links
                                  hide={
                                    l.link.slug === "other" ? false : true
                                  }
                                  title={l.title}
                                  icon={(l.link.slug === "other" && l.icon_tag !== null) ? getIcon(l) : <LanguageIcon sx={{color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple}}/>}
                                  value={l.value}
                                />

                              </Box>
                            ))}
                            {docs &&
                              docs?.map((d) => (
                                <Box
                                  key={d.id}
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  sx={{ alignItems: "center" }}
                                >
                                  <Links
                                    title={d.title}
                                    icon={<PictureAsPdfIcon sx={{color: data?.collaborated_enterprise?.default_background_color ?? colors.main_purple}}/>}
                                    value={d.url_file}
                                  />
                                </Box>
                              ))}

                            {/* Modal add DOC */}

                            {/* end modal update link  */}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>


                  {/* Footer */}
                  <Box
                    gap="0.5rem"
                    width="100%"
                    display="flex"
                    sx={{
                      alignItems: "center",
                      flexDirection: {
                        sm: "column-reverse",
                        md: "column-reverse",
                        lg: "row",
                        xs: "column-reverse",
                      },
                      justifyContent: {
                        sm: "center",
                        md: "center",
                        xs: "center",
                      },
                    }}
                  >
                    <Box
                      onClick={() => {
                          const logoExists = data?.collaborated_enterprise?.url_logo;
                          const websiteExists = data?.collaborated_enterprise?.website;
                          const link = logoExists && !websiteExists ? null : (websiteExists || "https://bdigitall.co");
                          if(link !== null){
                            window.open(link);
                          }
                        }}                     
                      sx={{
                        marginTop: {
                          xs: "1rem",
                          sm: "1rem",
                          md: "1rem",
                          lg: "0",
                        },
                        marginBottom: 1,
                        cursor: "pointer",
                      }}
                    >
                      <img 
                        alt="icon"
                        src={data?.collaborated_enterprise?.url_logo ? data?.collaborated_enterprise?.url_logo : bdigitall}
                        width={100}
                        height={100}
                        style={{
                          borderRadius:'8px',
                          width: {
                            xs: '10px',
                            sm: '10px',
                            md: '30px',
                            lg: '30px',
                            xl: '30px'
                          },
                          height: {
                            xs: '10px',
                            sm: '10px',
                            md: '30px',
                            lg: '30px',
                            xl: '30px'
                          }
                        }}
                      />
                    </Box>
                  </Box>

                  </Box>

                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      contact_group: form.contact_group,
                      email: form.email,
                      function:form.function,
                      last_name: form.last_name,
                      first_name: form.first_name,
                      phone: form.phone,
                      company_name: form.company_name,
                      brochure: form.brochure,
                      school: form.school,
                      pre_notificaition: form.pre_notification,
                      policy: policy,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                      onSubmitContact(values, actions);
                    }}
                  >
                    {({ values, handleChange, setFieldValue }) => (
                      <Modal open={openForm} onClose={() => setOpenForm(false)}>
                        <Form>
                        <Box
                          style={modalStyle}
                        >
                          {/* title + close icon */}
                          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="h6" fontWeight={400}>
                              {formText.title}
                            </Typography>
                            <Box onClick={() => setOpenForm(false)} sx={{cursor:'pointer'}}>
                              <CloseIcon/>
                            </Box>
                          </Box>

                          <Box mt={2} mb={3} >
                          <FormControl>
                            <Box display={'flex'} gap={1} alignItems={'center'}>
                              <IOSSwitch
                                checked={value}
                                onChange={handleChangeTab}
                                inputProps={{ 'aria-label': 'iOS style switch' }}
                              />
                              <Typography variant='subtitle1' sx={{fontSize:'1rem', fontWeight:300, color: colors.carbon_black}} id="value">{formText.isClient}</Typography>
                            </Box>
                            </FormControl>
                          <ErrorMessage name="value" component={MzErrorText} />
                          </Box>

                          <Box 
                            marginRight="1rem" 
                            sx={{ display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap: 1,
                            }}
                          >
                            <label style={{ fontSize: 15, color: colors.carbon_black }}>{formText.contactGroup}</label>
                            <Field
                              name="contact_group"
                              as={Select}
                              variant="outlined"
                              onChange={handleChange}
                              value={values.contact_group}
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                  width:'100%'
                                },
                              }}
                            >
                              <MenuItem value="" disabled selected>Selectionner Votre Group</MenuItem> 
                              <MenuItem value="GROUP_COMMERCIAL">{formText.commercial}</MenuItem>
                              <MenuItem value="GROUP_SUPLIER">{formText.supplier}</MenuItem>
                              <MenuItem value="GROUP_CONDIDAT">{formText.candidate}</MenuItem>
                              <MenuItem value="GROUP_STUDENT">{formText.student}</MenuItem>
                            </Field>
                            <ErrorMessage name="contact_group" component={MzErrorText} />
                          </Box>
                          

                        { value === false && <>

                          {/* prenom */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15, color: colors.carbon_black}}>{formText.firstName}</label>
                            <Field
                              name="first_name"
                              as={TextField}
                              variant="outlined"
                              placeholder={formText.firstName}
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="first_name"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* nom */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15, color: colors.carbon_black}}>{formText.lastName}</label>
                            <Field
                              name="last_name"
                              as={TextField}
                              variant="outlined"
                              placeholder={formText.lastName}
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="last_name"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* phone */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15, color: colors.carbon_black}}>{formText.phone}</label>
                            <Field
                              name="phone"
                              as={TextField}
                              variant="outlined"
                              placeholder={formText.phone}
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="phone"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* School */}
                          {values.contact_group === 'GROUP_STUDENT' && (
                            <Box
                              marginRight="1rem"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "1.3rem",
                                gap: 1
                              }}
                            >
                              <label style={{ fontSize: 15, color: colors.carbon_black }}>{formText.school}</label>
                              <Field
                                name="school"
                                as={TextField}
                                variant="outlined"
                                placeholder={formText.school}
                                size="small"
                                InputProps={{
                                  style: {
                                    backgroundColor: '#F5F5F5',
                                  },
                                }}
                              />
                              <ErrorMessage
                                name="school"
                                component={MzErrorText}
                              />
                            </Box>
                          )}

                          {/* Pre notification */}
                          {values.contact_group === 'GROUP_CONDIDAT' && (
                            <Box
                              marginRight="1rem"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "1.3rem",
                                gap: 1
                              }}
                            >
                              <label style={{ fontSize: 15, color: colors.carbon_black }}>{formText.preNotification}</label>
                              <Field
                                name="pre_notification"
                                as={Select}
                                variant="outlined"
                                onChange={handleChange}
                                value={values.pre_notification}
                                size="small"
                                InputProps={{
                                  style: {
                                    backgroundColor: '#F5F5F5',
                                    width: '100%'
                                  },
                                }}
                              >
                                <MenuItem value="" disabled selected>Selectionner une durée</MenuItem>
                                <MenuItem value="15 jours">15 jours</MenuItem>
                                <MenuItem value="1 mois">1 mois</MenuItem>
                                <MenuItem value="2 mois">2 mois</MenuItem>
                                <MenuItem value="3 mois ou plus">3 mois ou plus</MenuItem>
                              </Field>
                              <ErrorMessage name="pre_notification" component={MzErrorText} />
                            </Box>
                          )}

                          {/* Brochure */}
                          { (values.contact_group === 'GROUP_SUPLIER' || values.contact_group === 'GROUP_CONDIDAT') && <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15, color: colors.carbon_black}}>{formText.brochure}</label>
                            <Button
                              component="label"
                              role={undefined}
                              sx={{'width':'100%'}}
                              variant="upload"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              onChange={(event) => {
                                const file = event.target.files[0];
                                if (file) {
                                  setFieldValue('brochure', file);
                                  setSelectedFileName(file.name);
                                }
                              }}
                            >
                              {selectedFileName ? selectedFileName : 'Upload'}
                              <VisuallyHiddenInput type="file" />
                            </Button>
                          </Box>}

                          {/* more */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <List>
                              <ListItem onClick={toggleCollapse} sx={{cursor:'pointer'}}>
                                <Typography variant="p" fontSize={'.8rem'} color={colors.gray}>
                                  {formText.more}
                                </Typography>
                                {isCollapsed ? <Add sx={{'color':colors.gray}} /> : <ExpandLess sx={{'color':colors.gray}} />}
                              </ListItem>
                              <Collapse sx={{width:'100%', margin:0, padding:0}} in={!isCollapsed} timeout="auto" unmountOnExit>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'center', gap:1}}>
                                <label style={{fontSize:15, color: colors.carbon_black}}>{formText.emailOpt}</label>
                                <Field
                                  name="email"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder={formText.emailOpt}
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'start', gap:1}}>
                                <label style={{fontSize:15, color: colors.carbon_black}}>{formText.companyName}</label>
                                <Field
                                  name="company_name"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder={formText.companyName}
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'start', gap:1}}>
                                <label style={{fontSize:15, color: colors.carbon_black}}>{formText.function}</label>
                                <Field
                                  name="function"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder={formText.function}
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                              </Collapse>
                            </List>
                          </Box>

                          {/* policy */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              marginBottom: "1.3rem",
                            }}
                          >
                            <Checkbox
                              name="policy"
                              variant="outlined"
                              label="Condition et Politique"
                              size="large"
                              required
                              sx={{
                                '&.Mui-checked': {
                                  color: '#964CFC',
                                },
                                '&.Mui-checked .MuiSvgIcon-root': {
                                  fill: '#964CFC',
                                },
                              }}
                            />
                            <Typography variant="subtitle1" fontSize={13} fontWeight={300}>
                              {formText.policy}
                            </Typography>
                          </Box>
                          <ErrorMessage
                              name="policy"
                              component={MzErrorText}
                          />

                          {isError && <p style={{'color':`${colors.mjRedOutline}`}} className=" col-start-[span_1] md:col-start-[span_2] lg:col-start-[span_2]">Veuillez remplir les champs obligatoire </p>}
                          </>
                        }

                        { value === true && <>
                          {/* email */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15}}>{formText.email}</label>
                            <Field
                              name="email"
                              as={TextField}
                              variant="outlined"
                              placeholder={formText.email}
                              size="small"
                              fullWidth
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="email"
                              component={MzErrorText}
                            />                        
                          </Box>

                          {/* Brochure */}
                          {(values.contact_group === 'GROUP_SUPLIER' || values.contact_group === 'GROUP_CONDIDAT') && <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15, color: colors.carbon_black}}>{formText.brochure}</label>
                            <Button
                              component="label"
                              role={undefined}
                              sx={{'width':'100%'}}
                              variant="upload"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              onChange={(event) => {
                                const file = event.target.files[0];
                                if (file) {
                                  setFieldValue('brochure', file);
                                  setSelectedFileName(file.name);
                                }
                              }}
                            >
                              {selectedFileName ? selectedFileName : 'Upload'}
                              <VisuallyHiddenInput type="file" />
                            </Button>
                          </Box>}

                          {/* School */}
                          {values.contact_group === 'GROUP_STUDENT' && (
                            <Box
                              marginRight="1rem"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "1.3rem",
                                gap: 1
                              }}
                            >
                              <label style={{ fontSize: 15, color: colors.carbon_black }}>{formText.school}</label>
                              <Field
                                name="school"
                                as={TextField}
                                variant="outlined"
                                placeholder={formText.school}
                                size="small"
                                InputProps={{
                                  style: {
                                    backgroundColor: '#F5F5F5',
                                  },
                                }}
                              />
                              <ErrorMessage
                                name="school"
                                component={MzErrorText}
                              />
                            </Box>
                          )}

                          {/* Pre notification */}
                          {values.contact_group === 'GROUP_CONDIDAT' && (
                            <Box
                              marginRight="1rem"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "1.3rem",
                                gap: 1
                              }}
                            >
                              <label style={{ fontSize: 15, color: colors.carbon_black }}>{formText.preNotification}</label>
                              <Field
                                name="pre_notification"
                                as={Select}
                                variant="outlined"
                                onChange={handleChange}
                                value={values.pre_notification}
                                size="small"
                                InputProps={{
                                  style: {
                                    backgroundColor: '#F5F5F5',
                                    width: '100%'
                                  },
                                }}
                              >
                                <MenuItem value="" disabled selected>Selectionner une durée</MenuItem>
                                <MenuItem value="15 jours">15 jours</MenuItem>
                                <MenuItem value="1 mois">1 mois</MenuItem>
                                <MenuItem value="2 mois">2 mois</MenuItem>
                                <MenuItem value="3 mois ou plus">3 mois ou plus</MenuItem>
                              </Field>
                              <ErrorMessage name="pre_notification" component={MzErrorText} />
                            </Box>
                          )}
                          
                          {/* policy */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              justifyContent:'start',
                              marginBottom: "1.3rem",
                            }}
                          >
                            <Checkbox
                              name="policy"
                              required
                              variant="outlined"
                              label="Condition et Politique"
                              size="large"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#964CFC',
                                },
                                '&.Mui-checked .MuiSvgIcon-root': {
                                  fill: '#964CFC',
                                },
                              }}
                            />
                            <Typography variant="subtitle1" fontSize={13} fontWeight={300}>
                              {formText.policy}
                            </Typography>                           
                            <ErrorMessage
                              name="policy"
                              component={MzErrorText}
                            />
                          </Box>
                          </>
                        }

                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              flexDirection: "column",
                            }}
                          >
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                { sending ?
                                <CircularProgress/>
                                :
                                <Button
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: {
                                    xs: "100%",
                                    md: "100%",
                                    sm: "100%",
                                    lg: "100%",
                                  },
                                  backgroundColor: data?.collaborated_enterprise?.default_background_color ?? '#964CFC',
                                  color: data?.collaborated_enterprise?.default_text_color ?? '#FFFFFF',
                                  border: 'none',
                                  borderRadius: '16px',
                                  padding: '10px 0px',
                                  fontWeight:500
                                }}
                                disabled={sending}
                                variant="primary"
                                type="submit"
                              >
                                {formText.submit}
                              </Button> 
                                }
                            </Box>
                          </Box>
                        </Box>
                        </Form>
                      </Modal>
                    )}
                  </Formik>

        </Box>}
    </>
  );
}


export default AbonneeApercue;
