import React, { useEffect, useRef, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { Box, Typography, Button, TextField, FormControl, Autocomplete } from '@mui/material';
import * as Yup from 'yup';
import API from '../../api/Api_1_3';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { ToastContainer, toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import MzErrorText from './MzErrorText';
import Gauge from './Gauge.jsx'
import { colors } from '../../theme/Colors.js';

const BarChartComponent = ({ values, users }) => {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const type = user.user.role;
  const id = user.user.id;
  const [selectedUser, setSelectedUser] = useState('');
  const [data, setFilled] = useState({});
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const navigate = useNavigate();
  const formRef = useRef();

  const initialValuesCartes = {
    start_date: values.start_date,
    end_date: values.end_date,
    min_percentage: '',
    max_percentage: '',
  };

  // Validation schema
  const validationSchema = Yup.object({
    start_date: Yup.date().nullable(),
    end_date: Yup.date().nullable(),
    min_percentage: Yup.number().min(0).max(100).nullable(),
    max_percentage: Yup.number().min(0).max(100).nullable(),
  });

  const getProfileFilled = (values) => {
    const params = {
      start_date: values.start_date || null,
      end_date: values.end_date || null,
    };
    if (values.min_percentage !== '' && values.min_percentage !== null) params.min_percentage = values.min_percentage;
    if (values.max_percentage !== '' && values.max_percentage !== null) params.max_percentage = values.max_percentage;

    API.get('/report/bcard/profiles-filling-per-user', {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        authorization: `Bearer ${access_token}`,
      },
      params,
    })
      .then((response) => {
        const profiles = Object.entries(response.data.data.profiles).map(([name, percentage]) => ({
          name: name.split('-')[1],
          percentage,
        }));
        setFilled(response.data.data);
        setFilteredProfiles(profiles);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleApiError = (error) => {
    if (error.response.status === 403) toast.error('Une erreur est survenue');
    else toast.error(error.response.data.message);
    if (error.response.status === 401) {
      localStorage.removeItem('user');
      navigate('/login', { replace: true });
      navigate(0, { replace: true });
    }
  };

  const onSubmitSearchUsers = (values) => {
    if (values.start_date > values.end_date) {
      toast.error('Start date must be less than end date');
    } else {
      getProfileFilled(values);
    }
  };

  const handleUserChange = (event, value) => {
    setSelectedUser(value ? value.id : '');
    const profiles = Object.entries(data.profiles)
      .filter(([key]) => key.includes(`${value.id} -`))
      .map(([name, percentage]) => ({
        name: name.split('-')[1],
        percentage,
      }));
    setFilteredProfiles(profiles);
  };

  useEffect(() => {
    getProfileFilled(initialValuesCartes);
  }, []);

  return (
    <Box p={3} mt={4}>
      
      {/* Percentage */}
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
        <Typography variant="h6" mb={4}>
          Pourcentage du Profils remplis:
        </Typography>
        <Typography variant="h6" mb={4} fontWeight={600} color={colors.bd_Purple}>{data.filling_percentage_avg}%</Typography>
      </Box>

      <Box
        className="filter"
        display="flex"
        alignItems={{
          xs: 'start',
          sm: 'start',
          md: 'center',
          lg: 'center',
          xl: 'center',
        }}
        flexDirection={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
          xl: 'row',
        }}
        justifyContent={{
          xs: 'start',
          sm: 'start',
          md: 'center',
          lg: 'center',
          xl: 'center',
        }}
        gap={2}
      >
        {type === 'CLIENT_ADMIN' && (
          <FormControl sx={{ width: '33%' }}>
            <Autocomplete
              options={users}
              getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
              value={users.find((user) => user.id === selectedUser) || null}
              onChange={handleUserChange}
              renderInput={(params) => (
                <TextField {...params} label="Selectionner Un Utilisateur" variant="outlined" />
              )}
            />
          </FormControl>
        )}
        <Formik
          innerRef={formRef}
          initialValues={initialValuesCartes}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchUsers(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  maxWidth="1400"
                  sx={{
                    flexDirection: { md: 'column', xs: 'column', sm: 'column', lg: 'row' },
                  }}
                  gap={{
                    xs: 3,
                    sm: 3,
                    md: 3,
                    lg: 0,
                    xl: 0,
                  }}
                >
                  <Box flex={2} alignItems="center" marginRight="1rem">
                    <Field
                      fullWidth
                      name="min_percentage"
                      type="number"
                      as={TextField}
                      variant="outlined"
                      label="Minimum Percentage"
                      size="small"
                    />
                    <ErrorMessage name="min_percentage" component={MzErrorText} />
                  </Box>
                  <Box flex={2} alignItems="center" marginRight="1rem">
                    <Field
                      fullWidth
                      name="max_percentage"
                      type="number"
                      as={TextField}
                      variant="outlined"
                      label="Maximum Percentage"
                      size="small"
                    />
                    <ErrorMessage name="max_percentage" component={MzErrorText} />
                  </Box>
                  <Box display="flex" flex={0.5} marginRight="1rem" gap={2} mb>
                    <Button type="submit" variant="primary">
                      Filtrer
                    </Button>
                    <Button
                      onClick={() => getProfileFilled(initialValuesCartes)}
                      variant="secondary"
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>

      <Box width="90%" height={300} sx={{ overflowY: 'auto' }}>
        <ResponsiveContainer width="100%" height={filteredProfiles.length * 100}>
          <BarChart
            data={filteredProfiles}
            layout="vertical"
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: selectedUser ? 0 : 60,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" interval={0} tick={{ textAnchor: 'end', fontSize: '12px' }} />
            <Tooltip />
            <Bar dataKey="percentage" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default BarChartComponent;
