import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { ToastContainer, toast } from "react-toastify";
import API from '../../api/Api_1_3';
import { Box, Button, CircularProgress, colors } from '@mui/material';
import { CalendarMonth, Call, CoPresent, Delete, Edit, Email, LinkedIn } from '@mui/icons-material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

const CommentsTypes = () => {
    const { user } = useAuthContext();
    const access_token = user.access_token;
    const navigate = useNavigate();
    const [companies,setCompanies] = useState([])
    const [group,setGroup] = useState([])

    const getTypes = ()=>{
        API.get('/comment-types', {
            headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
            },
          })
            .then((response) => {
              setGroup(response.data.data);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
              }
              // console.log(error);
              if (error?.response?.status === 403) {
                toast.error("Une erreur est survenue");
              } else {
                toast.error(error?.response?.data?.message);
              }
            });
    }

    const deleteType = async (id)=>{
       await API.delete('/comment-types/' + id, {
            headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
            },
          })
            .then((response) => {
              if(response.status === 200){
                toast.success('operation success')
              }
              else{
                toast.success('operation success')
              }
              getTypes()
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
              }
              // console.log(error);
              if (error?.response?.status === 403) {
                toast.error("Une erreur est survenue");
              } else {
                toast.error(error?.response?.data?.message);
              }
            });
    }

    const getIconByType = (type,color) => {
      switch (type) {
        case 'Call':
          return <Call sx={{color:color}} />;
        case 'Email':
          return <Email  sx={{color:color}}/>;
        case 'LinkedIn':
          return <LinkedIn  sx={{color:color}}/>;
        case 'RDV Présentation':
          return <CalendarMonth sx={{color:color}}/>;
        case 'Démo':
          return <CoPresent sx={{color:color}} />;
        default:
          return <DesignServicesIcon sx={{color:color}} />;
      }
    };

      //on page load
      useEffect(() => {
        getTypes()
      }, []);
    return ( 
        <main className='flex flex-col gap-4 w-full mt-12'>
          <ToastContainer
            style={{ top: "5rem", width: "300px" }}
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
            <Box maxWidth={'lg'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginBottom={2} mt={3}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'start'} justifyContent={'center'} gap={3}>
                    <Typography variant="h6" fontWeight={500}>List Des Types</Typography>
                </Box>
                <Button variant='primary' onClick={()=> navigate('/admin/types/add')}>Nouveau Type</Button>
            </Box>
            {!group ? <h3><CircularProgress/></h3> : group.length === 0 ? <h3>Aucun Type</h3> :
            
            group.map((grp,index) =>(
                <span key={index} style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:' 12px 24px',borderRadius:'8px',boxShadow: '4px 17px 21px -4px rgba(0,0,0,0.15)','backgroundColor':colors.blueGrey, marginTop:6}}>
                
                <h4 className='font-medium flex gap-2 items-center'>
                    {getIconByType(grp.type_designation, colors.purple[800])}
                    {grp.type_designation}
                </h4>
                <div className='flex items-center gap-2'>
                    <button onClick={()=> navigate('/admin/types/'+ grp.id)} style={{backgroundColor: colors.purple[800],padding:'8px',color:'white',borderRadius:'8px'}}>
                          <Edit/>
                    </button>
                    <button onClick={()=>deleteType(grp.id)} style={{backgroundColor: colors.purple[600],padding:'8px',color:'white',borderRadius:'8px'}}>
                          <Delete/>
                    </button>                
                </div>
            </span>
            ))}
        </main>
     );
}
 
export default CommentsTypes;