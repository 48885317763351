import React from "react";
import qrIconin from "../imgs/Pagenotfound.svg";
import bdigitall from "../imgs/BDIGITALL.svg";
import { Box, Avatar, Container, Button, Typography } from "@mui/material";
function NotFoundPage() {
  return (
    <Box>
      <Container sx={{ minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: { xs: "center", md: "center", sm: "center", lg: "center" } }} maxWidth="sm">
        <img style={{ margin: "1rem 0rem" }} src={bdigitall} alt="bdigitall logo" />
        <Box>
          <Box display="flex" flexDirection="column" sx={{ alignItems: "center", backgroundColor: "#F9F6FF", borderRadius: "2rem", padding: "3rem" }}>
            <Typography marginBottom="1rem" sx={{ textAlign: "center" }}>
              Page introuvable
            </Typography>
            <Avatar
              sx={{
                position: "relative",
                borderRadius: "20px",
                width: "12rem",
                height: "11rem",
                backgroundColor: "transparent",
                marginBottom: "1rem",
              }}
              variant="rounded"
            >
              {qrIconin ? <img src={qrIconin} style={{ width: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} /> : null}
            </Avatar>
          </Box>
        </Box>
        <a href="https://www.bdigitall.co" style={{ textDecoration: "none", color: "white" }}>
          <Button sx={{ margin: "1rem 0rem" }} variant="primary">
            Redirection vers notre site
          </Button>
        </a>
      </Container>
    </Box>
  );
}

export default NotFoundPage;
