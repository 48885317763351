import React from "react";
import prodIcon from "../../imgs/INPROD.svg";

function InProdClose() {
  return (
    <div>
      <img src={prodIcon} style={{ height: "50px", width: "50px" }} />
    </div>
  );
}

export default InProdClose;
