import React from "react";
import unauthorizedIcon from "../../imgs/unauthorized.svg";
import BDIGITALL from "../../imgs/BDIGITALL.svg";
import { Box, Avatar, Container, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Unauthorized() {
  return (
    <Box>
      <Container sx={{ minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: { xs: "center", md: "center", sm: "center", lg: "center" } }} maxWidth="sm">
        <img style={{ margin: "1rem 0rem" }} src={BDIGITALL} alt="bdigitall logo" />
        <Box>
          <Box display="flex" flexDirection="column" sx={{ alignItems: "center", backgroundColor: "#F9F6FF", borderRadius: "2rem", padding: "3rem" }}>
            <Typography marginBottom="1rem" sx={{ textAlign: "center", fontWeight: "500", fontSize: "1rem" }}>
              Page introuvable
            </Typography>

            {unauthorizedIcon ? <img src={unauthorizedIcon} style={{ width: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} /> : null}
            <Box sx={{ marginTop: "2rem" }}>
              <Link to="/">
                <Button sx={{ margin: "1rem 0rem" }} variant="primary">
                  Revenir au login
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Unauthorized;
