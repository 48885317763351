import React from "react";
import { Box, Typography } from "@mui/material";
import FB from '@mui/icons-material/Facebook';
import IG from '@mui/icons-material/Instagram';
import YTB from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import LIN from '@mui/icons-material/LinkedIn';


function IconLinkRow({ icon, slug, title, value, hide, color }) {
  const iconPicker = () => {
  
    switch (slug) {
      case 'facebook':
        return <FB sx={{color: '#1877F2'}}/>;
      case 'instagram':
        return <IG sx={{color: '#D300C5'}}/>;
      case 'youtube':
        return <YTB sx={{color: '#FF0000'}}/>;
      case 'linkedin':
        return <LIN sx={{color: '#0072B1'}}/>;
      case 'twitter':
        return <XIcon sx={{color: '#000000'}}/>
    }
  };
  return (
    <Box sx={{ display: `${hide ? "none" : "flex"}` }} flexDirection="row" gap="0.5rem" alignItems="center" >
      <a target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }} href={value}>
        <Box className="well" display="flex" sx={{ alignItems: "center", justifyContent: "center", border:'.3px solid #E0E0E0', padding: "12px", borderRadius: "50%" }}>
            {iconPicker(slug)}
        </Box>
      </a>

      <Box>
        <Typography>
          <a target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }} href={value}>
            {title}
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default IconLinkRow;
