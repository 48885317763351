import React from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { colors } from "../../theme/Colors";
import IconBdigitall from "../../imgs/BDIGITALL.svg";
import * as Yup from "yup";
import MzLabel from "../../components/ui/MzLabel";
import MzErrorText from "../../components/ui/MzErrorText";
import AlertError from "../../components/ui/AlertError";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import bg from "../../imgs/BG_LOGIN.png";

function Login() {
  const navigate = useNavigate();

  //initialize error, isLoading, and login function
  const { login, errorLogin, isLoading } = useLogin();

  const onSubmitLogin = async (values, errorLogin, isLoading) => {
    await login(values.email, values.password);
  };

  //initial values form state
  const initialValues = {
    email: "",
    password: "",
  };

  //validators form state
  const validationSchema = Yup.object({
    email: Yup.string().email("Format invalide de l'email").required("Ce champ est obligatoire"),
    password: Yup.string().required("Ce champ est obligatoire"),
  });

  return (
    <Box sx={{ backgroundImage: `url(${bg})`, backgroundSize: "cover", width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ padding: "2.5rem", width: "450px", maxHeight: "560px", borderRadius: "24px", margin: "1rem" }} backgroundColor="white">
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "4rem" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <img src={IconBdigitall} height={50} alt="moonz logo" style={{ marginRight: "1rem" }} />
            </Box>
          </Box>
        </Box>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitLogin}>
          {({ values }) => (
            <Form>
              <Box className="field" sx={{ marginBottom: "1rem" }}>
                <MzLabel htmlFor="email" name="email">
                  Email
                </MzLabel>
                <Field
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& .MuiInputBase-input": {
                        padding: "16px 10px",
                      },
                    },
                  }}
                  autoComplete="off"
                  name="email"
                  type="email"
                  placeholder="Email"
                  id="email"
                  as={TextField}
                  fullWidth
                />
                <ErrorMessage name="email" component={MzErrorText} />
              </Box>

              <Box className="field" sx={{ marginBottom: "1rem" }}>
                <MzLabel htmlFor="password" name="password">
                  Mot de passe
                </MzLabel>
                <Field
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& .MuiInputBase-input": {
                        padding: "16px 10px",
                      },
                    },
                  }}
                  autoComplete="off"
                  name="password"
                  type="password"
                  placeholder="Mot de passe"
                  id="password"
                  as={TextField}
                  fullWidth
                />
                <ErrorMessage name="password" component={MzErrorText} />
              </Box>
              <Box display="flex" justifyContent="end">
                <Typography onClick={() => navigate("/mot-de-passe-oublier")} marginBottom="1rem" color={colors.bd_DarkPurple} sx={{ textDecoration: "underline", cursor: "pointer" }}>
                  Mot de passe oublié
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button sx={{ height: "3.5rem" }} disabled={isLoading} className="submit" type="submit" variant="primary" fullWidth>
                  Se connecter
                </Button>
              </Box>
              {errorLogin && <AlertError>{errorLogin}</AlertError>}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default Login;
