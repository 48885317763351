import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from "react-toastify";
import { ContactsOutlined, DownloadSharp, VisibilityOutlined } from "@mui/icons-material";
import ContactsIcon from '@mui/icons-material/Contacts';
import { MenuItem, Box, Button, Tab, Tabs, Menu, Switch, Modal, Avatar, Typography } from "@mui/material";
import { ConvertCard, Profile2User, Sms, Whatsapp, MonitorMobbile } from 'iconsax-react'
import "./style/SharedLayout.css";
import Api from "../../api/Api";
import bdigitall from "../../imgs/BDIGITALL.svg";
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton'
import ContactModal from '../../components/ui/ContactModal'
import QrCodeIcon from '@mui/icons-material/QrCode';
import ShareIcon from '@mui/icons-material/Share';
import IconLink from "../../components/ui/IconLink_bdisplay";
import DefaultProfilePic from "../../imgs/user.png";
import LanguageToggler from "../../components/ui/LanguageToggler";
import API from "../../api/Api_1_3";
import { Urls } from "../../api/Urls";
import { colors } from "../../theme/Colors";
import { ModalStyleQRCODE } from "../../theme/ModalStyleQRCODE";
import { modalStyle } from "../../theme/ModalStyle";
import CloseIcon from '@mui/icons-material/Close';



function Bdisplay() {

  const [services, setServices] = useState([]);
  const [page, setPage] = useState([]);
  const [i, setI] = useState(false);
  const location = useLocation();
  const [display, setDisplay] = useState("")
  const [clickedButton, setClickedButton] = useState("");
  const [serviceToConsult, setServiceToConsult] = useState(null);
  const [currentServiceUrl, setCurrentServiceUrl] = useState(null);
  const [currentServiceUrlFile, setCurrentServiceUrlFile] = useState(null);
  const [brochureId, setBrochureId] = useState('')
  const [canSaveContacts, setCanSaveContacts] = useState(null)
  const [canSaveContactsOnConsult, setCanSaveContactsOnConsult] = useState(null)
  const [possibleLinksList, setPossibleLinksList] = useState();
  const [links, setLinks] = useState([]);
  const pathname = location.pathname.split("/");
  const search = location.search.split("=");
  const pageId = pathname[2];
  const navigate = useNavigate();
  const [language, setLanguage] = useState('fr');


  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  const share = () => {
    var browser = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "edge";
        case agent.indexOf("edg") > -1:
          return "chromium based edge (dev or canary)";
        case agent.indexOf("opr") > -1 && !!window.opr:
          return "opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome:
          return "chrome";
        case agent.indexOf("trident") > -1:
          return "ie";
        case agent.indexOf("firefox") > -1:
          return "firefox";
        case agent.indexOf("safari") > -1:
          return "safari";
        default:
          return "other";
      }
    })(window.navigator.userAgent.toLowerCase());
    //console.log(browser);
    const url = page.url_qrcode;
    const shareData = {
      title: "Partage de code QR",
      url: `${window.location.href}`,
      text: "Venez consulter ma Bdisplay sur BdigitAll !",
    };
    if (
      navigator.share &&
      navigator.canShare(shareData) &&
      browser !== "chromium based edge (dev or canary)" &&
      window.location.href.split("/")[0] === "https:"
    ) {
      navigator.share(shareData);
    } else {
      navigator["clipboard"].writeText(`${shareData.url}`);
      toast.success("Le lien a été copié !");
    }
  };

  const pageText = language === 'fr' 
? {
    consult:'Consulter',
    share:'Partager mon profile',
    qrcode:'Afficher mon code QR',
    services:'Nos Services',
    team:'Notre Equipe',
    saveContact: "Enregistrer notre contact",
    download: "Brochure",
    viewProfile: "Consulter le profile",
    addContact: "Ajouter contact",
    CTA:"Tout d'abord, apprenons à vous connaître !"
  }
: {
    consult:'Consult',
    share:'Share My Profile',
    qrcode:'Show My QR Code',
    services:'Our Services',
    team:'Our Team',
    saveContact: "Save our contact",
    download: "Brochure",
    viewProfile: "View profile",
    addContact: "Add contact",
    CTA:"Let's get to know you First !"
  };

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#964CFC',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  async function getServices() {
    try {
      
      setDisplay(search[1])
      const response = await Api.get(`/bdisplay/${pageId}`, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          // authorization: `Bearer ${access_token}`,
        },
      });

      if(response.status === 200){
        setServices(response.data.services);
        setPage(response.data.page ?? {});
        setCanSaveContacts(response.data?.page?.can_save_contacts)
        setCanSaveContactsOnConsult(response.data?.page?.can_save_contacts_on_consultation)
        setLinks(response.data.page.links ?? [])
        setUsers(response.data.page.users ?? [])


      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!i) {
      getServices();
      setI(true);
    }
    Api.get(Urls.GET_POSSIBLE_LINKS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        setPossibleLinksList(response.data.links);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };


  const handleSendStats = async (id) => {
    const data = {
      service_id : id,
      type_action: clickedButton === 'CONSULT_URL' ? 'CONSULT_URL' : 'DOWNLOAD_BROCHURE'
    }
    try {
    await Api.post(`/bdisplay/services/store-consultation`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    });

  } catch (error) {
    console.log(error);
  }
  }

  const handleSendLinkVisit = async (id) => {
    try {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
  
        await API.post(`/consultations/bdisplay-link/${id}`, 
          {
            location: {
              lat: String(latitude),
              lng: String(longitude),
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            },
          }
        );
      }, (error) => {
        console.error('Error getting geolocation:', error);
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleSuccess = (url, file) => {
    setCurrentServiceUrl(url);
    setCurrentServiceUrlFile(file);


    if(clickedButton === 'CONSULT_URL' && canSaveContactsOnConsult === true){
        window.open(url, "_blank");
        setClickedButton('');
    }
    else if (clickedButton === 'download' && canSaveContacts === true) {
        setTimeout(()=>{
            handleDownload(file, 'brochure.pdf')
        },1000)
        setClickedButton('');
    }

    setCanSaveContacts(false)
    setCanSaveContactsOnConsult(false)
}

  const handleError = (error) =>{
    console.log(error)
  }

  const getLogoUrl = (title) => {
    const link = possibleLinksList?.find((link) => link.title === title);
    return link ? link.url_logo : "";
  };
  

  useEffect(() => {
    if (clickedButton && serviceToConsult && services.length > 0) {
        const currentService = services.find(service => service.id === serviceToConsult);
        if (currentService) {
            setCurrentServiceUrl(currentService.url);
            setCurrentServiceUrlFile(currentService.url_file);
        }
        if(canSaveContacts === true){
          return;
        }
    }
}, [clickedButton, serviceToConsult, services]);

    // NEW : page contacts

  const [value, setValue] = useState('one');
  const [users, setUsers] = useState([])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const downloadOptions = [
    // {
    //   id:0,
    //   title:'Recevoir par email',
    //   icon:<Sms color={colors.textWhite}/>,
    //   action:'send_email'
    // },
    // {
    //   id:1,
    //   title:'Recevoir par WhatsApp',
    //   icon:<Whatsapp color={colors.light_purple}/>,
    //   action:'share_to_whatsapp'
    // },
    {
      id:2,
      title:'Télécharger sur l\'appareil',
      icon:<MonitorMobbile color={colors.light_purple}/>,
      action:'download'
    }
  ]

  const [selectedOption, setSelectedOption] = useState(null);


  const [openBrochure, setOpenBrochure] = useState(false)

  const handleSetOption = (opt) =>{
    setClickedButton(opt)
    setOpenBrochure(false)
  }

  return (
    
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "100vh"
      }}
    >
      <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      {!page.title ? <h3 className="text-2xl font-bold py-24">Loading ...</h3>
      :
      <div className="w-full flex flex-col">
        {/* Bdisplay banner */}
        <div className="w-full flex justify-center relative h-72 md:h-80 lg:h-96 overflow-hidden">
          <img alt="banner" className=" object-cover md:object-cover lg:object-cover bg-center w-full md:w-full lg:w-full h-full" src={page?.url_banner} />
        </div>
                  {/* Upper Actions */}
                  <Box
                  display={'flex'}
                  gap={'.5rem'}
                  alignItems={'center'}
                  justifyContent={'end'}
                  sx={{
                    position: 'absolute',
                    top:9,
                    zIndex:10,
                    left:{
                      xs:'83%',
                      sm:'90%',
                      md:'90%',
                      lg:'95%',
                    }
                  }}
                 >
                    <IconButton onClick={handleMenuClick}>
                      <MenuIcon style={{ fontSize: 35, color: colors.carbon_black, backgroundColor: colors.textWhite, borderRadius:'18px', padding:'2px'  }} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      PaperProps={{
                        sx: {
                          borderRadius: '20px',
                          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                        },
                      }}
                    >
                        <>
                          <MenuItem onClick={() => share()} 
                            sx={{
                              padding: '6px 10px',
                              fontSize: '14px'
                            }}
                          >
                            <ShareIcon
                              style={{
                                fontSize: 35,
                                color: page?.enterprise?.default_background_color ?? colors.main_purple,
                                padding: '4px',
                                marginRight: '5px'
                              }}
                            />
                            {pageText.share}
                          </MenuItem>
                          
                          <MenuItem onClick={() => setOpen(true)}
                            sx={{
                              padding: '6px 10px',
                              fontSize: '14px'
                            }}
                          >
                            <QrCodeIcon
                              style={{
                                fontSize: 35,
                                color: page?.enterprise?.default_background_color ?? colors.main_purple,
                                padding: '4px',
                                marginRight: '5px'
                              }}
                            />
                            {pageText.qrcode}
                          </MenuItem>
                          
                          {/* language toggler */}
                          <MenuItem
                            sx={{
                              padding: '6px 10px',
                              cursor:'normal'
                            }}
                          >
                            <Box display="flex" alignItems="center">
                              <LanguageToggler bgColor={page?.enterprise?.default_background_color ?? colors.light_purple} color={page?.enterprise?.default_text_color ?? colors.textWhite} onToggle={(language)=> handleLanguageChange(language)}/>
                            </Box>
                          </MenuItem>
                        </>
                    </Menu>

                  </Box>

                  <Modal open={open} onClose={() => setOpen(false)}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={ModalStyleQRCODE}
                    >
                      <Avatar
                        sx={{
                          position: "relative",
                          width: "17rem",
                          height: "17rem",

                          marginBottom: "0rem",
                        }}
                        variant="rounded"
                      >
                        {page?.url_qrcode ? (
                          <img
                            alt="url_logo"
                            src={page?.url_qrcode}
                            style={{
                              width: "17rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        ) : (
                          <img
                            src={null}
                            alt="placeholder"
                            style={{
                              width: "17rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        )}
                      </Avatar>
                    </Box>
                  </Modal>

        {/* Services */}
        <div>
          <h1 className="text-center mt-4 md:mt-12 lg:mt-12 mb-12 font-bold text-3xl">{page.title}</h1>

          <Box display={'flex'} justifyContent="center" alignItems="center" >
          {links?.map((l) => (
                <Box key={l.id} flexDirection={'column'} display="flex" justifyContent="center" alignItems="center">
                    <IconLink icon={getLogoUrl(l.title)} value={l.value} title={l.title} />
                </Box>
          ))}
          </Box>

          {/* Tabulation */}
          <Box mt={2} mb={12} display={'flex'} justifyContent={'center'} sx={{width:'100%'}} >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tabs"

            >
              <Tab
                value="one"
                label={pageText.services} 
                sx={{fontSize:'.9rem'}}
                icon={<ConvertCard variant="Bold"/>} 
                iconPosition="start"
              />
              <Tab value="two" icon={<Profile2User variant="Bold"/>} iconPosition="start" label={pageText.team} sx={{fontSize:'.9rem'}} />
            </Tabs>
          </Box>

          {/* Template 1 */}

          { page.card_theme === 'TYPE1' && value === 'one' && <div className="sevices-grid gap-12 md:gap-8 lg:gap-0">
            {services?.length === 0 ? (
              <div>No service</div>
            ) : (
              services?.map((service) => {
                 return (
                  <div key={service.id} className={`flex flex-col items-start w-[75%] md:w-full lg:w-[75%] ${(service.url && service.url_file) ? 'h-[27rem]' : 'h-[24rem]'} md:h-[24rem] lg:h-[27rem] mb-12 self-center rounded-xl relative overflow-visible shadow-2xl`}>
                    <img alt="banner" width={70} className="absolute w-[85%] md:w-[15rem] lg:w-[19rem] object-cover h-60 md:h-60 lg:h-64 self-center rounded-lg top-5" src={service.url_image} />
                    <h1 className={`text-lg lg:text-xl px-6 text-black font-bold absolute ${(service.url && service.url_file) ? 'top-[62%]' : 'top-[70%]'} md:top-[70%] lg:top-[70%]`}>{service.name}</h1>
                    <div className={`flex gap-3 w-full flex-col md:flex-row lg:flex-row items-center justify-between absolute ${(service.url && service.url_file) ? 'top-[73%]' : 'top-[83%]'} md:top-[83%] lg:top-[85%] px-4`}>
                      {service.url ? <p
                        onClick={() => {
                          if (canSaveContactsOnConsult === true) {
                            setClickedButton('CONSULT_URL');
                            setServiceToConsult(service.id);
                            setCurrentServiceUrl(service.url);
                            setCurrentServiceUrlFile(service.url_file);
                          } else {
                            handleSendStats(service.id)
                            window.open(service.url, "_blank");
                          }
                        }}
                        style={{ color: colors.textWhite, borderColor: colors.light_purple, backgroundColor: colors.light_purple, fontWeight:"normal"}}
                        className="w-full flex gap-2 cursor-pointer border-2 justify-center items-center px-3 py-2 rounded-xl text-center"
                      >
                        <span className="extraSmall">
                          <InsertLinkOutlinedIcon/>
                        </span>
                        <p>{pageText.consult}</p>

                      </p>
                      : null }
                      {service.url_file ? <button
                        style={{ color: colors.light_purple, borderColor:colors.light_purple, fontWeight:"normal" }}
                        onClick={() => {
                          if (canSaveContacts) {
                            setOpenBrochure(true)
                            setServiceToConsult(service.id);
                            setCurrentServiceUrl(service.url);
                            setCurrentServiceUrlFile(service.url_file);
                          } else {
                            handleSendStats(service.id)
                            handleDownload(service.url_file);
                          }
                        }}
                        className="w-full px-3 py-2 flex gap-2 justify-center items-center rounded-xl border-2"
                      >
                       <span className="extraSmall">
                        <FileDownloadOutlinedIcon/>
                       </span>
                        <p>{pageText.download}</p>
                      </button>
                      : null }
                      { canSaveContacts === true || canSaveContactsOnConsult === true ? <ContactModal
                        title={pageText.CTA}
                        language={language}
                        refuseLabel="Fermer"
                        clickedButton={clickedButton}
                        onSuccess={() => handleSuccess(service.url,service.url_file)}
                        onError={(error)=> handleError(error)}
                        open={serviceToConsult === service.id && openBrochure === false}
                        onClose={()=> setServiceToConsult(null)}
                        service_id={service.id}
                      /> : null}

                    </div>
                  </div>
                  
                )
                
              })
              
            )}
          </div>}

          {/* Template 2 */}
          
           { page.card_theme === 'TYPE2' && value === 'one' && <div className="sevices-grid gap-12 md:gap-8 lg:gap-0">
            {services?.length === 0 ? (
              <div>No service</div>
            ) : (
              services?.map((service) => {
                 return (
                  <div key={service.id} className={`flex flex-col items-start w-4/5 border md:w-full lg:w-4/5 h-[22rem] md:h-72 lg:h-[27rem] mb-12 self-center rounded-xl relative overflow-hidden`}>
                    <img alt="banner" className=" object-cover bg-center w-full h-full " src={service.url_image} />
                    <span className=" w-full h-96 md:h-72 lg:h-[27rem] bg-zinc-900 to-transparent absolute top-0 opacity-45 "></span>
                    <h1 className={`text-lg lg:text-2xl px-6 text-white font-bold absolute  ${(service.url && service.url_file) ? 'top-[53%] md:top-[45%]' : 'top-[67%] md:top-[55%]'} lg:top-[70%]`}>{service.name}</h1>
                    <div className={`flex gap-3 w-full flex-col md:flex-col lg:flex-row items-center justify-between absolute ${(service.url && service.url_file) ? 'top-[67%] md:top-[60%]' : 'top-[82%] md:top-[80%]'} lg:top-[85%] px-4`}>
                      { service.url ? <p
                        onClick={() => {
                          if (canSaveContactsOnConsult === true) {
                            setClickedButton('CONSULT_URL');
                            setServiceToConsult(service.id);
                            setCurrentServiceUrl(service.url);
                            setCurrentServiceUrlFile(service.url_file);
                          } else if (canSaveContactsOnConsult === false) {
                            handleSendStats(service.id)
                            window.open(service.url, "_blank");
                          }
                        }}
                        style={{ color: colors.textWhite, borderColor: colors.light_purple, backgroundColor: colors.light_purple, fontWeight:"normal"}}
                        className="w-full flex gap-2 cursor-pointer border-2 justify-center items-center px-3 py-2 rounded-xl text-center"
                      >
                      <InsertLinkOutlinedIcon/>
                        <p>{pageText.consult}</p>
                      </p>
                      : null }

                      {service.url_file ? <button
                        style={{ color: '#FFFFFF', borderColor:'#FFFFFF', fontWeight:"normal" }}
                        onClick={() => {
                          if (canSaveContacts) {
                            setOpenBrochure(true)
                            setServiceToConsult(service.id);
                            setCurrentServiceUrl(service.url);
                            setCurrentServiceUrlFile(service.url_file);
                          } else {
                            handleSendStats(service.id)
                            handleDownload(service.url_file);
                          }
                        }}
                        className="w-full px-3 py-2 flex gap-2 justify-center items-center rounded-lg border-2"
                      >
                      <FileDownloadOutlinedIcon/>
                        <p>{pageText.download}</p>
                      </button>
                      : null }

                      { canSaveContacts === true || canSaveContactsOnConsult === true ? <ContactModal
                        title={pageText.CTA}
                        language={language}
                        refuseLabel="Fermer"
                        clickedButton={clickedButton}
                        onSuccess={() => handleSuccess(service.url,service.url_file)}
                        onError={()=> handleError()}
                        open={serviceToConsult === service.id && openBrochure === false}
                        onClose={()=> setServiceToConsult(null)}
                        service_id={service.id}
                      /> : null}

                      
                    </div>

                  </div>
                )
              })
            )}
            </div>}


            {/* Team members */}
            {value === 'two' && 
              <Box display={'flex'} px={5} mt={4} sx={{width:'100%'}} gap={10} flexWrap={'wrap'} alignItems={'center'} justifyContent={{
        xs: 'center',
        sm: 'center',
        md: 'center',
        lg: 'center',
      }}>
        {users.length !== 0 && users.map((user) => (
          <div key={user.id} className="flex items-center justify-center gap-2">
             <div
                key={user.id}
                style={{
                  color: "#353535",
                  textDecoration: "none",
                  padding: 16,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className=" drop-shadow-sm shadow-gray-400 shadow-lg rounded-xl"
              >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={user?.url_profile_picture ?? DefaultProfilePic}
                      alt="profile_picture"
                      style={{
                        objectFit: "cover",
                        width: 250,
                        height: 250,
                        borderRadius: 10,
                        marginTop:'0'
                      }}
                    />
                  </div>
                    <div className="flex flex-col my-4 items-start">
                      <h3 className="font-bold text-lg pt-3">{user.first_name + " " + user.last_name}</h3>
                      <h5 className="font-medium pt-2">{user.function}</h5>
                    </div>                  
                <div
                  style={{
                    marginTop: 6,
                    width:'100%',
                    display: "flex",
                    alignItems:'center',
                    justifyContent: "end",
                    gap:'.5rem',
                  }}
                  className="flex-col md:flex-row lg:flex-row"
                >
                  <NavLink to={user.url_vcf_file} style={{width:'100%'}}>
                    <Button variant="primary" startIcon={<ContactsOutlined/>} sx={{backgroundColor: colors.light_purple, border: 'none', padding:1, borderRadius:'8px', width:'100%'}} >{pageText.addContact}</Button>
                  </NavLink>
                  <NavLink to={user.url_profile} style={{width:'100%'}} target="_blank">
                    <Button variant="secondary" startIcon={<VisibilityOutlined/>} sx={{borderRadius:'8px', width:'100%', border:'1px solid' + colors.light_purple}} >{pageText.viewProfile}</Button>
                  </NavLink>
                </div>
              </div>
          </div>
        ))}
              </Box>
            }

        </div>
        
        {/* save contact floating button */}
        <div className="fixed bottom-1 right-2 shadow-2xl text-white font-bold w-auto text-base my-6 mx-2 z-50">
            {
            page.hot_line_number && <a href={page.vcf_file} style={{'backgroundColor':colors.light_purple}} className="flex gap-1.5 items-center p-3 rounded-xl">
              <ContactsIcon/>
              <p className="text-sm font-normal md:text-base lg:text-base">{pageText.saveContact}</p>
            </a>
            }
        </div>
      </div>

      }
      <a href="https://bdigitall.co" target="_blank" rel="noreferrer" className="my-4">
        <img src={bdigitall} alt="bdigitall-logo" width={130} height={130} />
      </a>

      <Modal open={openBrochure === true} close={openBrochure === false}>
        <Box style={modalStyle} sx={{backgroundColor:'background.paper', paddingX:2, paddingY:4}}>
          <Box display={'flex'} gap={3} alignItems={'flex-start'} justifyContent={'space-between'} mb={3}>
            <Typography variant="subtitle2" fontSize={'16px'} width={'70%'}>
              Choisissez votre méthode de téléchargement
            </Typography>
            <CloseIcon onClick={()=> {
              setOpenBrochure(false)
              setServiceToConsult(null)
            }} 
            sx={{cursor:'pointer'}}/>
          </Box>
          {
            downloadOptions.map((opt, index) => (
              <div key={opt.id} className={`flex items-center px-2 justify-between mb-4 ease-in-out duration-100 w-full `} onClick={() => handleSetOption(opt.action)}>
                <Button variant={opt.id === 0 ? "form" : "secondary"} startIcon={opt.icon} sx={{width:'100%', borderRadius:'8px', border: '1px solid' + colors.light_purple, paddingY:1.5}}>
                  <Typography variant="body1" fontWeight={450}>{opt.title}</Typography>
                </Button>
              </div>
            ))
          }
        </Box>
      </Modal>
    </div>
  );
}

export default Bdisplay;
