import React, { useEffect, useRef, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import "./styles/StyledTable.css";
import { CircularProgress, Select, MenuItem, Button, Box, TextField, FormControl, Checkbox, Menu, IconButton, Modal, Autocomplete, Typography, TextareaAutosize, Tabs, Tab } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { Add, CalendarMonth, Call, Check, Close, CoPresent, Comment, ContactsOutlined, Download, Edit, Email, LinkedIn, List, MoreHoriz, Upload, AirplayOutlined } from "@mui/icons-material";
import API from "../../api/Api_1_3";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ModalStyle } from "../../theme/ModalStyles";
import MzErrorText from "../../components/ui/MzErrorText";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from 'axios';
import { colors } from "../../theme/Colors";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';


function Contacts() {
  const { user } = useAuthContext();
  const access_token = user.access_token;

  const [contacts, setContacts] = useState([]);
  const [selectedContactType, setSelectedContactType] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReject, setOpenReject] = useState(false);
  const [contactId, setContactId] = useState("");
  const navigate = useNavigate()

  const userObj = JSON.parse(localStorage.getItem('user'))
  const [role, setRole] = useState('')

  const [tabValue, setTabValue] = useState('COLLECTED'); // Default tab

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchUserRole = async () => {
    if(userObj?.access_token){
      try {
        const response = await API.get('/user/who-am-i', {
          headers: { "content-type": "application/json", authorization: `Bearer ${userObj?.access_token}` },
        });
        const userRole = response.data?.user?.role;
        setRole(userRole)
        console.log(role);
      } 
      catch (error) {
        console.error("Error fetching user role:", error);
      }
    }
  };


  const handleCloseReject = () => {
    setOpenReject(false);
    setContactId('');
  };

  const exportContacts = async () =>{
    try {
      const response = await API.get('/export-contacts', {
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          authorization: `Bearer ${access_token}`,
        },
        responseType: 'blob', // Ensure Axios treats the response as a blob
      });
  
      const blob = response.data; // Use response.data to access the blob
  
      const url = window.URL.createObjectURL(new Blob([blob]));
  
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', `Contacts.xlsx`);
  
      document.body.appendChild(link);
  
      setTimeout(() => {
        link.click();
        // Clean up resources
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }, 100); // Delay the click to ensure the link is appended
    } catch (error) {
      toast.error('Failed to export contacts');
      console.error('Error fetching the file:', error);
    }
  }

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: '',
    phone: '',
    company: ''
  });

  const validationSchema = Yup.object({
    first_name: Yup.string(),
    last_name: Yup.string(),
    phone: Yup.string(),
    company: Yup.string(),
  });

  const onSubmitSearchBdisplay = async (values, token) => {
    Api.get("/get-contacts", {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
      params: {
        first_name: values.first_name !== '' ? values.first_name : null,
        last_name: values.last_name !== '' ? values.last_name : null,
        phone: values.phone !== '' ? values.phone : null,
        company_name: values.company !== '' ? values.company : null
      }
    })
      .then((response) => {
        setContacts(response.data?.contacts)
      })
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedContacts(contacts.map((contact) => contact.id));
    } else {
      setSelectedContacts([]);
    }
  };

  const handleAddComment = async (id, values) => {
    const { comment_type, comment } = values;
    let payload = {
      contact_id: id,
      comment: comment,
    };

    if (comment_type.type_designation !== 'Autre') {
      payload.comment_type_id = comment_type.id;
    }
    try {
      await API.post(
        "/contacts/comments",
        payload,
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success('Operation success');
      getContacts()
    } catch (error) {
      console.error("Error adding contact:", error);
      toast.error("Failed to add comment");
    }
    handleCloseReject();
  };

  const [vcfUrlFile, setVcfUrlFile] = useState(null);

  const handleShowMenu = (event, url_vcf_file) => {
    setAnchorEl(event.currentTarget);
    setVcfUrlFile(url_vcf_file);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setVcfUrlFile(null);
  };

  const handleNavLinkClick = () => {
    if (vcfUrlFile) {
      window.open(vcfUrlFile, "_blank");
    }
    handleCloseMenu();
  };

  const showMenu = Boolean(anchorEl);

  const pageNumbers = [10, 25, 50, 100];
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumbers[1]);
  const [page, setPage] = useState(1);


  const getContacts = () => {
    const params = {
      perPage: perPage,
      page: page
    };
  
    if (role === "COLLABORATOR") {
      params.assignment_status = tabValue || 'COLLECTED'
    }
    Api.get(Urls.GET_CONTACTS,{
      headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      authorization: `Bearer ${access_token}`,
    },
    params: params
  })
    .then((response) => {
      const contacts = response.data?.contacts ?? [];
      setTotal(response.data.meta.total);
      setContacts(contacts);
    })
    .catch((error) => {
      console.log('Error fetching contacts:', error);
    });
  };

  const [possibleActionsList,setList] = useState([
    {
      id: 9999,
      type_designation: 'Autre',
    },
  ])

  const getTypes = () => {
    API.get('/comment-types', {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      const apiData = response.data.data ?? [];
      // Filter out any item with id 9999 to avoid duplication
      const filteredData = apiData.filter(item => item.id !== 9999);
      // Combine the default value with the filtered API data
      setList([
        ...filteredData,
        {
          id: 9999,
          type_designation: 'Autre',
        },
      ]);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const [editedComments, setEditedComments] = useState({});

  const handleEditComment = (id, newComment) => {
    setEditedComments({ ...editedComments, [id]: newComment });
  };

  const handleEditCommentOnSubmit = async (id, newComment) => {
    const trimmed = newComment.trim()
    handleShowEdit(null)
    try {
      await API.patch(
        "/contacts/comments/" + id,
        {
          comment: trimmed,
        },
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success('Operation success');
      getContacts();
    } catch (error) {
      console.error("Error editing comment:", error);
      toast.error("Failed to edit comment");
    }
  };

  const [openComments,setOpenComments] = useState(false)
  const handleClose = () => setOpenComments(false)
  const handleOpen = () => setOpenComments(true)
  
  const [selectedContactId, setSelectedContactId] = useState(null);

  const handleOpenComments = () => {
    setSelectedContactId(contactId);
  };

  const getIconByType = (type, color) => {
    switch (type) {
      case 'Call':
        return <Call sx={{color: color}} />;
      case 'Email':
        return <Email sx={{color: color}} />;
      case 'LinkedIn':
        return <LinkedIn sx={{color: color}} />;
      case 'RDV Présentation':
        return <CalendarMonth sx={{color: color}}/>;
      case 'Démo':
        return <CoPresent sx={{color: color}} />;
      default:
        return <DesignServicesIcon sx={{color: color}} />;
    }
  };

  const [editCommentId, setEditCommentId] = useState(null);

  const handleShowEdit = (commentId) =>{
    setEditCommentId(commentId);
  }
  
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [editCommentId]);


  const columns = [

    { field: 'name', headerName: 'Nom', width: 200, valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}` },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Mobile', width: 200, valueGetter: (params) => params.row.phone ?? '---' },
    { field: 'company', headerName: 'Entreprise', width: 250, valueGetter: (params) => params.row.company_name ?? '---' },
    { field: 'owner', headerName: 'Owner', width: 200, valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}` ?? '---' },
    { field: 'source', headerName: 'Source', width: 100, renderCell: (params) => (
      // Check if the row has the property 'bdisplay' or 'user'
      params.row.hasOwnProperty('bdisplay') ? <AirplayOutlined/> : 
      params.row.hasOwnProperty('user') ? <BadgeOutlinedIcon/> : null
    )},
    { field: 'type', headerName: 'Type', width: 200, renderCell: (params) => (
    <div>
                  <Select
                    id={`select-${params.row.id}`}
                    name="contact_type"
                    value={params.row.contact_type}
                    onChange={(event) => {
                      const { value } = event.target;
                      const updatedContacts = contacts.map((c) =>
                        c.id === params.row.id ? { ...c, contact_type: value } : c
                      );
                      setContacts(updatedContacts);

                      try {
                        API.patch(`/contacts/update-type/${params.row.id}`, { contact_type: value }, {
                          headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${access_token}`,
                          },
                        });
                        toast.success('Contact mis à jour');
                      } catch (error) {
                        toast.error('Une erreur est survenue');
                      }
                    }}
                    displayEmpty
                    sx={{ width: "150px", height: "30px", borderRadius: "15px", borderColor: "rgba(0, 0, 0, 0.23)" }}
                  >
                    <MenuItem value={"CLIENT"}>Client</MenuItem>
                    <MenuItem value={"HOT_LEAD"}>Hot Lead</MenuItem>
                    <MenuItem value={"CALLED_LEAD"}>Called Lead</MenuItem>
                    <MenuItem value={"LEAD"}>Lead</MenuItem>
                    <MenuItem value={"TO_BE_INITIATED"}>To Be Initiated</MenuItem>
                  </Select>
    </div>
    )},
    { field: 'actions', headerName: 'Action', width: 150, renderCell: (params) => (
      <div>
        <IconButton onClick={(event) => {
          handleShowMenu(event, params.row.url_vcf_file);
          setContactId(params.row.id);
        }}>
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={showMenu}
          onClose={handleCloseMenu}
          PaperProps={{ elevation: 2 }}
        >
          <MenuItem onClick={() => handleNavLinkClick(params.row.url_vcf_file)} sx={{display:'flex', gap:1}}>
            <ContactsOutlined sx={{width:25}}/>
            Ajouter Contact
          </MenuItem>
          <MenuItem onClick={() => {
            handleOpenComments(params.row.id);
            handleOpen();
          }} sx={{display:'flex', gap:1}}>
            <List/>
            List des Commentaires
          </MenuItem>
        </Menu>
      </div>
    )
  }
  ];

  useEffect(()=>{
    fetchUserRole()
  },[])


  useEffect(() => { 
    if(role !== ''){
      getContacts();
    }
    getTypes();
  }, [selectedContactType, tabValue, perPage, page, role]);

  return (
    <div>
      <ToastContainer
        style={{ top: "5rem", width: "300px" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex flex-col items-start justify-between mb-4 mt-4">
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box className="filter" display="flex" flexDirection="row">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmitSearchBdisplay(initialValues, access_token);
              }}
            >
              {({ values, setFieldValue }) => (
                <FormControl>
                  <Box display="flex">
                    {/*Search fields */}
                    <Box display="flex" maxWidth="1200px" sx={{ flexDirection: { md: "row", xs: "column" } }} marginBottom="1rem">
                      <Box flex={1} alignItems="center" marginRight="1rem" mb>
                        <TextField
                          placeholder="Full Name"
                          name="first_name"
                          onChange={(event) => {
                            setInitialValues((prevValues) => ({
                              ...prevValues,
                              first_name: event.target.value,
                            }));
                            onSubmitSearchBdisplay(
                              {
                                ...initialValues,
                                first_name: event.target.value,
                              },
                              access_token
                            );
                          }}
                          sx={{ '::placeholder': { 'fontWeight': 600 } }}
                        />
                        <ErrorMessage name="first_name" component={MzErrorText} />
                      </Box>

                      <Box flex={1} alignItems="center" marginRight="1rem" mb>
                        <TextField
                          placeholder="Téléphone"
                          name="phone"
                          onChange={(event) => {
                            setInitialValues((prevValues) => ({
                              ...prevValues,
                              phone: event.target.value,
                            }));
                            onSubmitSearchBdisplay(
                              {
                                ...initialValues,
                                phone: event.target.value,
                              },
                              access_token
                            );
                          }}
                          sx={{ '::placeholder': { 'fontWeight': 600 } }}
                        />
                        <ErrorMessage name="phone" component={MzErrorText} />
                      </Box>

                      <Box flex={1} alignItems="center" marginRight="1rem" mb>
                        <TextField
                          placeholder="Entreprise"
                          name="company"
                          onChange={(event) => {
                            setInitialValues((prevValues) => ({
                              ...prevValues,
                              company: event.target.value,
                            }));
                            onSubmitSearchBdisplay(
                              {
                                ...initialValues,
                                company: event.target.value,
                              },
                              access_token
                            );
                          }}
                          sx={{ '::placeholder': { 'fontWeight': 600 } }}
                        />
                        <ErrorMessage name="company" component={MzErrorText} />
                      </Box>

                      <Box marginRight="1rem" display={'flex'} alignItems={'center'} gap={1} mb>
                        {user.user.role === 'CLIENT_ADMIN' && <Button startIcon={<Upload/>} variant="primary" sx={{ fontWeight: 500 }} onClick={exportContacts}>Exporter</Button>}
                      </Box>
                    </Box>
                  </Box>
                </FormControl>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
      {role === "COLLABORATOR" && (
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Collected" value="COLLECTED" />
          <Tab label="Affected" value="AFFECTED" />
        </Tabs>
      )}
      <div style={DataGridHeaderStyle}>
        <StripedDataGrid
          rows={contacts}
          columns={columns}
          pageSize={perPage}
          rowsPerPageOptions={pageNumbers}
          paginationMode="server"
          rowCount={total}
          page={page}
          checkboxSelection
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
          }}
          onSelectionModelChange={(newSelection) => {
            setSelectedContacts(newSelection);
          }}
          onPageChange={(newPage) => {
            //console.log(newPage);
            setPage(newPage);
          }}
        />
      </div>
      {openReject && (
        <Formik
          initialValues={{ comment: '', comment_type_id: null }}
          onSubmit={(values) => handleAddComment(contactId, values)}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => (
            <Modal
              open={openReject}
              onClose={handleCloseReject}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ModalStyle}>
                <h2 className="text-2xl mb-4">Ajouter un Commentaire</h2>
                <Form>
                  <Box className="field" sx={{ marginBottom: "1rem" }}>
                    <Field
                      name="comment_type"
                      component={Autocomplete}
                      options={possibleActionsList}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      getOptionLabel={(option) => option.type_designation}
                      onChange={(event, value) => {
                        setFieldValue("comment_type", value);
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Action" />}
                      size="small"
                      error={touched.comment_type_id}
                    />
                    <ErrorMessage name="comment_type" component={MzErrorText} />
                  </Box>
                  <Field
                    as={TextField}
                    label="Commentaire"
                    name="comment"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    error={touched.comment && Boolean(errors.comment)}
                    helperText={<ErrorMessage name="comment" component="div" className="error" />}
                  />
                  <div className="flex justify-end">
                    <Button variant="secondary" onClick={handleCloseReject} sx={{ mr: 2 }}>Annuler</Button>
                    <Button type="submit" variant="primary">Ajouter</Button>
                  </div>
                </Form>
              </Box>
            </Modal>
          )}
        </Formik>
      )}

      {
        openComments && (
          <Modal
          open={openComments}
          onClose={handleClose}
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openComments}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem>
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant="h6" color="initial">Commentaires</Typography>
                  <Box onClick={handleClose}>
                    <Close/>
                  </Box>
                </Box>
              </MenuItem>
              {contacts.length !== 0 ? contacts.map((contact, index) => (
              <div key={index}>
                {selectedContactId === contact.id && contact.comments && contact.comments.map((comment, commentIndex) => (
                  <MenuItem key={commentIndex} sx={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '1rem', alignItems: 'center', borderBottom: '.5px solid #232323', gap: '.5rem' }}>
                    <Box>
                      {getIconByType(comment.comment_type,colors.bd_Purple)}
                    </Box>
                    <Box width={500} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                      {editCommentId === comment.id ? 
                      <TextareaAutosize
                      rowsMin={3}
                      maxRows={5}
                      value={editedComments[comment.id] || comment.comment}
                      onChange={(e) => handleEditComment(comment.id, e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault(); 
                          handleEditCommentOnSubmit(comment.id, editedComments[comment.id]);
                        }
                      }}
                      autoFocus
                      ref={textAreaRef}
                      style={{ width: '100%', fontSize: '1rem', padding:3, fontWeight: 450, backgroundColor:'transparent' }}
                    />
                      : <Typography variant="body1">{editedComments[comment.id] || comment.comment}</Typography>
                    }
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                      <Typography variant="body1" color="initial" sx={{ whiteSpace: 'break-spaces', fontSize: '.9rem', opacity: 0.7 }}>{comment.user} - {comment.is_updated ? comment.updated_at : comment.created_at} {comment.is_updated && '(Modifié)'}</Typography>
                      <Box display={'flex'} gap={1}>
                        {
                        editCommentId === comment.id &&
                        <>
                          <Check sx={{color: colors.mjGreenText}} onClick={() => handleEditCommentOnSubmit(comment.id, editedComments[comment.id])}/>
                          <Close sx={{color: colors.mj_red}} onClick={()=> handleShowEdit(null)}/>
                        </>
                        }
                      </Box>
                    </Box>
                    </Box>
                    <Box>
                      <Button variant={ editCommentId === comment.id ? "disabled" : "secondary"} onClick={() => handleShowEdit(comment.id)}><Edit/></Button>
                    </Box>
                  </MenuItem>
          ))}
              </div>
              )) : <div>Aucun contact ajoutée.</div>}


            </Menu>

          </Modal>
        )
      }
    </div>
  );
}

export default Contacts;