export const Urls = {
  //auth
  LOGIN_URL: "/auth/login",

  //get users
  USERS: "/admin/users",

  //logout
  LOGOUT: "/auth/logout",

  //create entreprise account
  CREATE_CLIENT_ADMIN: "/admin/clientAdmins",

  //get datagrid entreprise list
  GET_ENTREPRISE_TABLE: "/admin/enterprises",

  //get detail entreprise + user infos
  GET_ENTREPRISE_DETAILS: "/enterprises/",

  //update user infos in entreprise details
  UPDATE_USER_INFOS_ENTREPRISE: "/users/",

  //update user infos in entreprise details
  UPDATE_ENTREPRISE_INFOS_ENTREPRISE: "/enterprises/",

  //update entreprise logo in entreprise details
  UPDATE_ENTREPRISE_LOGO: "/enterprises/",

  //update entreprise logo in entreprise details
  UPDATE_PROFILE_PICTURE: "/users",

  //generer les collaborateurs (comptes)
  GENERATE_FUTURE_COLLABS: "/enterprises/",

  //get liste des collaborateurs (tableau)
  GET_COLLABS: "/users",

  //update card status
  UPDATE_CARD_STATUS: "/update-status-card-users",

  //get user infos (detail)
  GET_USER_ACCOUNT_DETAILS: "/users",

  //update user infos (detail)
  UPDATE_USER_INFOS_COLLAB: "/users/",

  //get user links (detail)
  GET_USER_LINKS: "/users",

  //get user documents
  GET_USER_DOCUMENTS: "/users",

  //get possible links list
  GET_POSSIBLE_LINKS: "/links",

  //create user link
  CREATE_USER_LINK: "/users",

  //delete user link
  DELETE_USER_LINK: "/userLinks",

  //update user link
  UPDATE_USER_LINK: "/userLinks",

  //delete user doc
  DELETE_USER_DOCUMENT: "/documents",

  //add user doc
  ADD_USER_DOCUMENT: "/documents",

  //get ticket table
  GET_TICKETS_TABLE: "/tickets",

  // get bdisplays
  GET_BDISPLAYS:"/pages",

  
  GET_BDISPLAY_CONTACTS:"/bdisplay-contacts",

  //update password
  UPDATE_USER_PASSWORD: "/users/",
  
  //get  ticket  details
  GET_TICKET_DETAILS: "/tickets",

  //get messages for specific ticket
  GET_TICKET_MESSAGES: "/tickets",

  //Create message
  CREATE_MESSAGE: "/messages",

  //create ticket
  CREATE_TICKET: "/enterprises",

  //password reset
  PASSWORD_RESET: "/auth/reset-password",

  //get commande list
  GET_COMMANDE_LIST: "/orders",

  //get order details
  GET_ORDER_DETAILS: "/orders/",

  //create order
  CREATE_ORDER: "/enterprises/",

  //upload bon de commande
  UPLOAD_BON_DE_COMMANDE: "/orders/",

  //create collaborators
  UPLOAD_LIST_COLLABORATORS_ORDER: "/orders/",

  //create payement
  CREATE_PAYMENT: "/invoices/",

  // delete profil pic
  DELETE_PROFILE_PIC: "/users/",

  // get contacts
  GET_CONTACTS: "/get-contacts",

  // create contact
  ADD_CONTACTS: "/add-contact",

  // export contact
  EXPORT_CONTACTS: "/export-contacts",

};
