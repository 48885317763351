import axios from "axios";

const createAPI = () => {

  const API = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
  });

  API.interceptors.request.use(config => {
    config.headers['ngrok-skip-browser-warning'] = true;
    config.headers['access-control-allow-origin'] = '*'
    config.headers['cache-control'] = 'no-cache';
    return config;
  });
  
return API;

}
const API = createAPI();

export default API;
