//ALL COLORS
export const colors = {
  main: "#7B3FFF",
  light: "#9c6eff",
  dark: "#4d25a1",
  carbon_black:'#1A171B',
  main_mj: "#F07F3C",
  light_mj: "#f18c52",
  dark_mj: "#cf692e",
  gray: "#7b7b7b",
  smokeGray:'#F0F0F0',
  mj_text: "#212121",
  mj_navbar: "#2C2C2C",
  mj_drawer: "#FBDFCE",
  mj_sidebar: "#FBDFCE",
  mj_red: "#D30000",
  mj_red_light: "#d42020",
  mj_green: "#53B630",
  mj_green_light: "#56be30",
  mj_preselected: "#D4C500",
  mj_confirmed: "#F07F3C",
  mj_error: "#ec5656",
  mj_blue: "#4f85eb",
  mj_refused: "#707070",
  mj_pending: "#707070",
  paperLightGray: "#F9F9F9",
  paperLightGrayBorder: "#DADADA",
  paperLightOrange: "#FFF1EA",
  paperLightOrangeHover: "#fce4d9",
  tFieldGray: "#E2E2E2",
  bd_commanded: "#FFBE16",
  bd_suspended: "#F37070",
  bd_suspended_hover: "#f55151",
  bd_active: "#7B3FFF",
  bd_text_secondary: "#6D6D6D",
  bd_contact_form_gray:'#E2E2E2',
  textWhite:'#FFFFFF',
  textYellow: '#FFCB00',
  profile:'#F5EDFF',

  main_purple: "#7B3FFF",
  light_purple:'#964CFC',
  main_purple_cover: '#6237AB',
  main_purple_hover: "#6739ca",
  main_purple_hover_light: "#f2ebff",
  main_purple_hover_light_hover: "#e2d2ff",
  bd_DarkPurple: "#2D1C52",
  bd_DarkPurple_hover: "#342457",
  bd_Purple: "#7B3FFF",
  bd_Purple_hover: "#8e59ff",
  mz_blue: "#51E1D0",

  //colors for alert
  mjRedOutline: "#f0625f",
  mjRedText: "#632726",
  mjGreenOutline: "#5bb65f",
  mjGreenText: "#1e4620",
  mjYellowOutline: "#af6906",
};
