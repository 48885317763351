import ButtonDataGrid from "../components/ui/ButtonDataGrid";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { chipSelector } from "../utils/chipSelector";
import React from "react";
import { colors } from "../theme/Colors";

export const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 60,
    headerClassName: "grid-header",
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "inherit", height: "100%", width: "100%" }} to={`/admin/service-apres-vente/${params.row.id}`}>
            <Box sx={{ width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}>{params.row.id}</Box>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "motif",
    headerName: "Motif",
    description: "Motif du ticket.",
    width: 350,
    align: "left",
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "inherit", height: "100%", width: "100%" }} to={`/admin/service-apres-vente/${params.row.id}`}>
            <Box sx={{ width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}>{params.row.motif}</Box>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "enterprise_commercial_name",
    headerName: "Entreprise",
    description: "Nom commercial de l'entreprise.",
    width: 350,
  },
  {
    field: "status",
    headerName: "Statut",
    description: "Statut du ticket.",
    width: 350,
    align: "center",
    renderCell: (params) => {
      return chipSelector(params.row.status);
    },
  },

  {
    field: "action",
    headerName: "Action",
    description: "This column has a value getter and is not sortable.",
    align: "center",

    renderCell: (params) => {
      return (
        <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
          <Link to={`/admin/service-apres-vente/${params.row.id}`}>
            <ButtonDataGrid action={"consult"} onClick={() => {}} />
          </Link>
        </Box>
      );
    },
    width: 350,
  },
];

export const rows = [
  {
    id: 1,
    motif: "TELECOMMUNICATION",
    enterpriseCommercialName: "DJEZZY",
    status: "DJEZZY",
  },
];
