import React, { useEffect, useState } from "react";
import { NavLink,useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Api from "../../api/Api";
import { useAuthContext } from "../../hooks/useAuthContext";
import "./styles/StyledTable.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

function CreateGroup() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const groupId = pathname[3];
  const idEntreprise = user?.user?.enterprise?.id;
  const [companies, setCompanies] = useState([])
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false)
  const [companySelected, setCompanySelected] = useState("")
  const [formData, setFormData] = useState({
    name: "",
    enterprise_id: idEntreprise
  });

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  async function getGroup() {
    try {

      const response = await Api.get(`/bdisplay-groups/${groupId}`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      });

      setFormData(response.data.data ?? []);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const pathname = location.pathname.split("/");
    
    try {
      if(groupId === 'add'){
        const response = await Api.post(
          "/bdisplay-groups",
          {
              ...formData,
              enterprise_id: idEntreprise 
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 200 || response.status === 201) {
          // Handle success, e.g., show a success message or redirect
          toast.success("Operation success");
          setTimeout(() => {
            navigate("/admin/settings");
          }, 2000);
        } else {
          // Handle errors, e.g., display an error message
          toast.error("Operation echoué");
        }
      }
      else {
        const response = await Api.patch(
          "/bdisplay-groups/"+ groupId,
          {
              ...formData,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 200 || response.status === 201) {
          // Handle success, e.g., show a success message or redirect
          toast.success("Operation success");
          setTimeout(() => {
            navigate("/admin/settings");
          }, 2000);
        } else {
          // Handle errors, e.g., display an error message
          toast.error("Operation echoué");
        }
      }

    } catch (error) {
      toast.error(error.response.data.message);
    }
    setLoading(false)
  };

  useEffect(()=>{
    getGroup()
  },[])

  return (
    <div>
      <ToastContainer
          style={{ top: "5rem", width: "300px" }}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      <div className="flex items-center gap-3 w-full mb-6">
      <h1 className="text-xl font-bold">{groupId !== 'add' ? 'Modifier' : 'Ajouter'} un group</h1>
      </div>
      <form onSubmit={handleSubmit} className="table-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ fontSize: 14, fontWeight: 700 }}>
            Nom du group *
          </label>
          <input
            placeholder="Nom du group"
            name="name"
            className="default-input"
            value={formData.name}
            onChange={handleTextChange}
            required
          />
        </div>

        <div className=" flex justify-center md:justify-end lg:justify-end items-center w-full mt-16 gap-4">
            { loading === true ? <CircularProgress/> : <button
                type="submit"
                className="bg-[#7B3FFF] px-4 py-2 text-white rounded-lg"
            >
                Enregistrer
            </button>}
            <NavLink
                to={"/admin/settings" }
                style={{ backgroundColor: "#9A9A9A", width: "auto" }}
                className=" px-4 py-2 text-white rounded-lg"
            >
                Annuler
            </NavLink>
                    
        </div>
      </form>
    </div>
  );
}

export default CreateGroup;
