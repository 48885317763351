import { Category, FacebookOutlined, Instagram, Link, LinkedIn, YouTube } from '@mui/icons-material';
// import XIcon from '@mui/icons-material/X';
import { Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import X from '../../imgs/logo.svg'

const MultiStatsCard = ({ total, links, title, type, jobTitle, bgColor, style, current}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/').length;

  const getIcon = (linkName) => {
    switch (linkName) {
      case 'facebook':
        return <FacebookOutlined />;
      case 'instagram':
        return <Instagram />;
      case 'twitter':
        return <img src={X} width={18} alt="Xcorp logo"/>;
      case 'linkedin':
        return <LinkedIn />;
      case 'youtube':
        return <YouTube />;
      case 'url_services':
        return <Category/>;
      default:
        return <Link />;
    }
  };

  const handleLinkDetails = () => {
    if (path === 4) {
      return;
    }
    if(current === 'one'){ 
      navigate('/admin/stats/links');
    }
    if(current === 'two'){ 
      navigate('/admin/stats/links/pages');
    }
    else{
      return;
    }
  };

  return (
    <section
      className={`flex ${type === 'CLIENT_ADMIN' ? 'col-start-[span_2]' : path === 4 || path === 5 ? 'col-start-[span_1]' : 'col-start-[span_2]'} flex-col items-center ${path < 4 ? 'h-[15rem]' : 'h-[11rem]'} rounded-3xl px-6 py-6 justify-between gap-2 text-primary-white ${bgColor} ${path < 4 && current !== 'three' ? 'cursor-pointer' : ''}`}
      style={style}
      onClick={handleLinkDetails}
    >
      <h4 className="text-white font-bold text-xl text-center">{title} <Link /> </h4>
      <h2 className={`text-white font-bold text-[4rem] text-center`}>{total}</h2>
      <section className='flex justify-around items-center w-full'>
        {Object.entries(links).map(([linkName, linkValue], index) => {
          return (
            <Tooltip key={index} title={linkName === 'url_services' ? 'service bdisplay' : linkName === 'twitter' ? 'X (ex twitter)' : linkName} arrow>
              <span
                className={`flex items-center gap-1 mx-2 text-white font-bold text-2xl`}
              >
                <h3 className="text-[1.5rem]">{linkValue}</h3>
                <span>{getIcon(linkName)}</span>
              </span>
            </Tooltip>
          );
        })}
      </section>
    </section>
  );
};

export default MultiStatsCard;
