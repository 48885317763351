import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet, useNavigate } from "react-router-dom";
import { colors } from "../../theme/Colors";
import IconBdigitalWhite from "../../imgs/BDIGITALL_WHITE.svg";
import MenuIcon from "@mui/icons-material/Menu";
import DefaultProfilePic from "../../imgs/user.png";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconCommande from "../../imgs/commande.svg";
import IconApresVente from "../../imgs/apresVente.svg";
import IconEntreprise from "../../imgs/entreprise.svg";
import IconCarte from "../../imgs/carte.svg";
import IconPlus from "../../imgs/plus.svg";
import BdigitalWhite from "../../imgs/BDIGITALL_WHITE.svg";

import { NavLink } from "react-router-dom";
import SharedLayoutStyle from "./style/SharedLayout.css";

const drawerWidth = 300;
const navigation = [
  { title: "Mon entreprise", path: "/admin/mon-entreprise" },
  { title: "Gestion des BCARDs", path: "/admin/gestion-des-cartes" },
  { title: "Gestion des commandes", path: "/admin/gestion-des-commandes" },
  { title: "Service après vente", path: "/admin/service-apres-vente" },
];

const icons = {};
icons["Mon entreprise"] = IconEntreprise;
icons["Gestion des BCARDs"] = IconCarte;
icons["Gestion des commandes"] = IconCommande;
icons["Service après vente"] = IconApresVente;

function SharedLayoutAbonnee(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const access_token = user?.access_token;
  const id = user?.user?.id;

  const imageSrc = null;
  const [data, setData] = React.useState();
  const imageProfile = React.useRef();
  React.useEffect(() => {
    Api.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${id}/account-details`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setData(response.data.user);
        const img = new Image();
        img.onload = function () {
          if (this.width > this.height) {
            imageProfile.current.style.height = "3rem";
            imageProfile.current.style.maxWidth = "auto";
            imageProfile.current.style.display = "block";
          } else {
            imageProfile.current.style.height = "auto";
            imageProfile.current.style.width = "3rem";
            imageProfile.current.style.display = "block";
          }
        };
        img.src = response?.data?.user?.url_profile_picture;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, []);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    setAnchorElUser(null);

    Api.post(Urls.LOGOUT, null, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  // const handleNavigation = (index) => {
  //   switch (index) {
  //     case 0:
  //       navigate("/admin/gestion-des-utilisateurs");
  //       break;
  //   }
  // };

  const drawer = (
    <Box>
      <Toolbar sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <Box>
          <Box sx={{ padding: "2.5rem 0rem" }}>
            <Box>
              <img src={IconBdigitalWhite} width={150} alt="moonz logo" />
            </Box>
          </Box>
        </Box>
      </Toolbar>

      <List>
        {navigation.map((l, index) => (
          <ListItem key={index} disablePadding>
            <NavLink id="navlink" className={({ isActive }) => (isActive ? "active" : "inactive")} to={l.path}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <ListItemIcon>
                  <img style={{ width: "20px", marginLeft: "20px" }} src={icons[`${l.title}`]} />
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary={l.title} />
              </Box>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } else return "";
  }
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
        }}
      >
        <Toolbar sx={{ backgroundColor: `${colors.bd_DarkPurple}`, color: "white", display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ justifyContent: "space-between", width: "100%", alignItems: "center" }} display="flex" gap="1.5rem">
            <Box sx={{ display: { xs: "none", md: "block", lg: "block", sm: "block" } }}>
              <img src={BdigitalWhite} alt="imagebdigital" />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none", lg: "none", sm: "none" } }}>
              <img style={{ height: "30px", width: "120px" }} src={BdigitalWhite} alt="imagebdigital" />
            </Box>
            <Box display="flex" gap="1.5rem" alignItems="center">
              <Box display="flex" flexDirection="column" textAlign="right">
                <Typography noWrap component="div">
                  {capitalizeFirstLetter(user?.user?.first_name?.split(" ")[0]) + capitalizeFirstLetter(user?.user?.first_name?.split(" ")[1])}
                </Typography>
                <Typography noWrap component="div">
                  {user?.user?.last_name?.toUpperCase()}
                </Typography>
              </Box>
              <IconButton sx={{ width: "3rem", height: "3rem", p: 0, m: 0 }} onClick={handleOpenUserMenu}>
                <Avatar sx={{ width: "3rem", height: "3rem", borderRadius: "50%" }}>
                  {data?.url_profile_picture === null ? <img style={{ height: "3rem", width: "3rem" }} src={DefaultProfilePic} alt="" /> : <img ref={imageProfile} style={{ objectFit: "fill", objectPosition: "50% 50%", display: "none" }} id="imgNavbar" src={data?.url_profile_picture} alt="" />}
                </Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem>
                  <Typography onClick={handleLogout} textAlign="center">
                    Se déconnecter
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/*
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. 
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
        */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Toolbar />

        <Box sx={{ padding: "1rem" }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

SharedLayoutAbonnee.propTypes = {
  window: PropTypes.func,
};

export default SharedLayoutAbonnee;
