
import QrCodeIcon from '@mui/icons-material/QrCode';
import contacts from "../../imgs/Contacts.svg";
import { AccountCircle, Co2Outlined, Image, PaidOutlined, SpaOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const TotalStatsCard = ({ total, title, type, bgColor, current, isAvg }) => {

  const navigate = useNavigate()
  const handleBrochureDetails = () => {
    if(type === 'brochure' && !current){
      navigate('/admin/stats/brochures')
    }
    else if(type === 'contact' && !current){
      navigate('/admin/stats/contacts')
    }
  }
  const getIcon = (type) =>{
    switch(type){
      case 'scan':
        return <QrCodeIcon fontSize='medium'/>;
      case 'Brochure':
        return <Image fontSize='medium'/>;
      case 'money':
        return <PaidOutlined fontSize='medium'/>;
      case 'co2':
        return <SpaOutlined fontSize='medium'/>;
      case 'profile':
        return <AccountCircle fontSize='medium'/>;
      default:
        return <img src={contacts} alt="icon" width={25} height={25}/>
    }
  }
    return (
      <section
        className={`flex flex-col w-full h-48 rounded-3xl px-6 py-6 justify-between gap-2 text-primary-white ${bgColor}`}
        onClick={()=> handleBrochureDetails()}
        style={{cursor: (type === 'brochure' || type === 'contact') && !current ? 'pointer' : 'default'}}
      >
          <h3 className="text-white font-medium text-md">
            {title}
            {type === 'co2' && <h5 className=' text-xs mt-2'>(Kg CO2eq/kg de papier)</h5>}
            {type === 'money' && <h5 className=' text-xs mt-2'>(DZD)</h5>}
            {isAvg && <h5 className=' text-xs mt-2'>scan/user</h5>}
          </h3>
          <h3 className="text-white font-bold text-3xl flex gap-2 items-center self-start">
            {total}
            {getIcon(type)}
          </h3>
      </section>
    );
  };
  
  export default TotalStatsCard;
  