import React from "react";
import { Chip } from "@mui/material";
import { colors } from "../../theme/Colors";

function MzChip({ name }) {
  switch (name) {
    case "COMMANDED":
      return (
        <Chip
          size="small"
          label="Commandée"
          variant="filled"
          sx={{
            backgroundColor: `${colors.bd_commanded}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "SUSPENDED":
      return (
        <Chip
          size="small"
          label="Suspendue"
          variant="filled"
          sx={{
            backgroundColor: `${colors.bd_suspended}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "ACTIVE":
      return (
        <Chip
          size="small"
          label="Activée"
          variant="filled"
          sx={{
            backgroundColor: `${colors.bd_active}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "INIT":
      return (
        <Chip
          size="small"
          label="Initiale"
          variant="filled"
          sx={{
            border: "2px solid #7B7B7B",
            backgroundColor: `white`,
            "& .MuiChip-label": {
              color: "#7B7B7B",
            },
          }}
        />
      );
    case "ESTIMATE_UPLOADED":
      return (
        <Chip
          size="small"
          label="Validation de devis"
          variant="filled"
          sx={{
            border: "2px solid green",
            backgroundColor: `white`,
            "& .MuiChip-label": {
              color: "green",
            },
          }}
        />
      );
    case "PURCHASE_ORDER_UPLOADED":
      return (
        <Chip
          size="small"
          label="Bon de commande téléchargé"
          variant="filled"
          sx={{
            border: "2px solid #93D045",
            backgroundColor: `white`,
            "& .MuiChip-label": {
              color: "#93D045",
            },
          }}
        />
      );
    case "PURCHASE_ORDER_CONFIRMED":
      return (
        <Chip
          size="small"
          label="Bon de commande confirmé"
          variant="filled"
          sx={{
            backgroundColor: `#93D045`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "LIST_COLLABORATORS_UPLOADED":
      return (
        <Chip
          size="small"
          label="Collaborateurs téléchargés"
          variant="filled"
          sx={{
            border: "2px solid #5CAFCA",
            backgroundColor: `white`,
            "& .MuiChip-label": {
              color: "#5CAFCA",
            },
          }}
        />
      );
    case "PURCHASE_ORDER_REFUSED":
      return (
        <Chip
          size="small"
          label="Bon de commande refusé"
          variant="filled"
          sx={{
            border: "2px solid red",
            backgroundColor: `white`,
            "& .MuiChip-label": {
              color: "red",
            },
          }}
        />
      );
    case "PURCHASE_ORDER_REFUSED":
      return (
        <Chip
          size="small"
          label="Bon de commande refusé"
          variant="filled"
          sx={{
            border: "2px solid red",
            backgroundColor: `white`,
            "& .MuiChip-label": {
              color: "red",
            },
          }}
        />
      );
    case "PAYMENT_REFUSED":
      return (
        <Chip
          size="small"
          label="Paiement refusé"
          variant="filled"
          sx={{
            border: "2px solid red",
            backgroundColor: `white`,
            "& .MuiChip-label": {
              color: "red",
            },
          }}
        />
      );
    case "LIST_COLLABORATORS_ACCEPTED":
      return (
        <Chip
          size="small"
          label="Collaborateurs confirmés"
          variant="filled"
          sx={{
            backgroundColor: `#5CAFCA`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "INVOICE_UPLOADED":
      return (
        <Chip
          size="small"
          label="Facture téléchargée"
          variant="filled"
          sx={{
            border: "2px solid #1F64CD",
            backgroundColor: `white`,
            "& .MuiChip-label": {
              color: "#1F64CD",
            },
          }}
        />
      );
    case "PAYMENT_MADE":
      return (
        <Chip
          size="small"
          label="Paiement effectué"
          variant="filled"
          sx={{
            backgroundColor: `#1F64CD`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "PAYMENT_ACCEPTED":
      return (
        <Chip
          size="small"
          label="Paiement accepté"
          variant="filled"
          sx={{
            backgroundColor: `#7B3FFF`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "IN_PRODUCTION":
      return (
        <Chip
          size="small"
          label="En production"
          variant="filled"
          sx={{
            backgroundColor: `#F37070`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "DELIVERY":
      return (
        <Chip
          size="small"
          label="En cours de livraison"
          variant="filled"
          sx={{
            border: "2px solid #F37070",
            backgroundColor: `white`,
            "& .MuiChip-label": {
              color: "#F37070",
            },
          }}
        />
      );
    case "DELIVERED":
      return (
        <Chip
          size="small"
          label="Livrée"
          variant="filled"
          sx={{
            backgroundColor: `#2D1C52`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "C_ACTIVE":
      return (
        <Chip
          size="small"
          label="Carte activée"
          variant="filled"
          sx={{
            backgroundColor: `${colors.bd_active}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "C_SUSPENDED":
      return (
        <Chip
          size="small"
          label="Carte suspendue"
          variant="filled"
          sx={{
            backgroundColor: `${colors.bd_suspended}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "C_COMMANDED":
      return (
        <Chip
          size="small"
          label="Carte commandée"
          variant="filled"
          sx={{
            backgroundColor: `${colors.bd_commanded}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "CLOSED":
      return (
        <Chip
          size="small"
          label="Fermé"
          variant="filled"
          sx={{
            backgroundColor: `${colors.bd_suspended}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "OPEN":
      return (
        <Chip
          size="small"
          label="Ouvert"
          variant="outlined"
          sx={{
            border: `2px solid ${colors.main_purple}`,
            "& .MuiChip-label": {
              color: colors.main_purple,
              fontWeight: "600",
            },
          }}
        />
      );
  }
}

export default MzChip;
