import React from 'react';
import { Box, colors } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

const getColor = (name) => {
    switch (name) {
      case 'First Name':
      case 'Function':
        return colors.blue[600]; 
      case 'Last Name':
      case 'Country':
        return colors.purple[900];
      case 'Phone Number':
      case 'City':
        return colors.purple[300];
      case 'Email':
      case 'Postal Code':
        return colors.purple[200];
      case 'Department':
      case 'Address':
        return colors.purple[100];
      default:
        return '#8884d8';
    }
  };

  const CustomBarShape = (props) => {
    const { x, y, width, height } = props;
    const color = getColor(props.payload.name); // Determine color based on data
    
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color} // Use dynamic color
      />
    );
  };

  const CustomizedXAxisTick = ({ x, y, payload }) => {
    return (
      <text
        x={x - 33}
        y={y + 15}
        textAnchor="middle"
        fill="#666"
        fontSize={11} // Adjust font size here
        transform={`rotate(-45, ${x}, ${y})`} // Rotate 45 degrees
      >
        {payload.value}
      </text>
    );
  };

const FilledFields = ({ data }) => {
  return (
    <Box flexGrow={1} mt={4} textAlign={'center'} width={'100%'}>

      <BarChart
        width={550}
        height={400}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 75 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tick={<CustomizedXAxisTick />} // Use custom tick component
          interval={0} // Ensure all ticks are rendered
        />
        <YAxis />
        <Tooltip />
        
        {/* Conditionally rendering bars based on filtered data */}
        <Bar dataKey="value" barSize={30} shape={<CustomBarShape />} />
      </BarChart>
    </Box>
  );
};

export default FilledFields;
