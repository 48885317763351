import { Box, Container, FormControl, InputLabel, MenuItem, Select, Typography, colors } from "@mui/material";
import { useEffect, useState } from "react";
import API from "../../api/Api_1_3";
import Api from "../../api/Api";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";
import { BarChart } from "@mui/x-charts";
import { Urls } from "../../api/Urls";

const ContactStatsDetailsPerType = () => {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [colls, setRowsAPI] = useState([]);
  const [contactStats, setContactStats] = useState({
    CLIENT: 0,
    HOT_LEAD: 0,
    CALLED_LEAD: 0,
    LEAD: 0,
    TO_BE_INITIATED: 0
  });

  const getStats = () => {
    API.get('/report/bcard/collaborator-contacts', {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const getColls = () => {
    Api.get(Urls.GET_COLLABS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setRowsAPI(response.data.users);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const handleChangeUser = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUser(selectedUserId);
    const selectedUserData = users.find(user => user.collaborator_id === selectedUserId);
    if (selectedUserData) {
      setContactStats(selectedUserData.contacts);
    }
  };

  useEffect(() => {
    getStats();
    getColls();
  }, []);

  return (
    <Container>
      <ToastContainer
        style={{ top: "5rem", width: "300px" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Typography variant="h5" fontWeight={500} mb={3}>
        Statistiques Du Contacts
      </Typography>
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
          <Box p={3}>
            <Typography variant="h6" mb={4}>
              Percentage Per Month 
            </Typography>
            <FormControl sx={{ width: '33%' }}>
              <InputLabel id="user-select-label">Utilisateur</InputLabel>
              <Select
                labelId="user-select-label"
                value={selectedUser}
                label="Utilisateur"
                onChange={handleChangeUser}
              >
                {colls.map(user => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.first_name} {user.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box flexGrow={1} mt={4} textAlign={'center'} width={'100%'}>
              <BarChart
                xAxis={[{ scaleType: 'band', data: ['Total De Contacts'] }]}
                series={[
                  { data: [contactStats.CLIENT], label: 'CLIENT', color: colors.blue[600] },
                  { data: [contactStats.LEAD], label: 'OPPORTUNITE', color: colors.purple[900] },
                  { data: [contactStats.HOT_LEAD], label: 'HOT LEAD', color: colors.purple[300] },
                  { data: [contactStats.CALLED_LEAD], label: 'COLD LEAD', color: colors.purple[200] },
                  { data: [contactStats.TO_BE_INITIATED], label: 'TO BE INITIATED', color: colors.purple[100] },
                ]}
                width={1000}
                height={300}
                dataKey="name"
              />
              <Typography fontWeight={600}>Nombre Total De contacts (par type)</Typography>
            </Box>
          </Box>
        </Box>
      </section>
    </Container>
  );
};

export default ContactStatsDetailsPerType;
