import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useAuthContext } from "../../hooks/useAuthContext";
import CircularProgress from '@mui/material/CircularProgress';
import API from "../../api/Api_1_3";
import "./styles/StyledTable.css";
import "react-toastify/dist/ReactToastify.css";

function CreateType() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const groupId = pathname[3];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    type_designation: ""
  });

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  async function getGroup() {
    try {
      const response = await API.get(`/comment-types`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      });

      const data = response.data.data ?? [];
      // Filter the data based on the groupId
      const filtered = data.filter(item => item.id == groupId);
      if (filtered.length > 0) {
        setFormData(filtered[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const url = groupId === 'add' ? "/comment-types" : `/comment-types/${groupId}`;
      const method = groupId === 'add' ? API.post : API.patch;

      const response = await method(
        url,
        {
          type_designation: formData.type_designation,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Operation success");
        setTimeout(() => {
          navigate("/admin/settings");
        }, 2000);
      } else {
        toast.error("Operation echoué");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (groupId !== 'add') {
      getGroup();
    }
  }, [groupId]);

  return (
    <div>
      <ToastContainer
        style={{ top: "5rem", width: "300px" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex items-center gap-3 w-full mb-6">
        <h1 className="text-xl font-bold">{groupId !== 'add' ? 'Modifier' : 'Ajouter'} un type</h1>
      </div>
      <form onSubmit={handleSubmit} className="table-container">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ fontSize: 14, fontWeight: 700 }}>
            Nom du type *
          </label>
          <input
            placeholder="Nom du type*"
            name="type_designation"
            className="default-input"
            onChange={handleTextChange}
            value={formData.type_designation}
            required
          />
        </div>

        <div className="flex justify-center md:justify-end lg:justify-end items-center w-full mt-16 gap-4">
          {loading ? <CircularProgress /> : (
            <button type="submit" className="bg-[#7B3FFF] px-4 py-2 text-white rounded-lg">
              Enregistrer
            </button>
          )}
          <NavLink
            to={"/admin/settings"}
            style={{ backgroundColor: "#9A9A9A", width: "auto" }}
            className="px-4 py-2 text-white rounded-lg"
          >
            Annuler
          </NavLink>
        </div>
      </form>
    </div>
  );
}

export default CreateType;
