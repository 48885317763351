import React from "react";
import DeliveryIcon from "../../imgs/DELIVERY.svg";
function DeliveryOpen() {
  return (
    <div>
      <img src={DeliveryIcon} style={{ opacity: "0.5", height: "50px", width: "50px" }} />
    </div>
  );
}

export default DeliveryOpen;
