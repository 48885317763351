import React, { useEffect, useState, useRef } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { NavLink, useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import "./styles/StyledTable.css";
import { Box, Button, TextField, Tooltip, Autocomplete, FormControl, CircularProgress, Typography } from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import MzErrorText  from '../../components/ui/MzErrorText'
import { toast,ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { Close, FilterList, VisibilityOutlined } from "@mui/icons-material";
import { colors } from "../../theme/Colors";
import BarChartIcon from '@mui/icons-material/BarChart';
import CategoryIcon from '@mui/icons-material/Category';
function Pages() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const [pages, setPages] = useState([]);
  const [initialValues, setInitialValues] = useState({
    display_type: "",
    title:''
  });

  const onSubmitSearchBdisplay = async (values,token) => {
    
    const display_type = values.display_type === 'Interieur' ? 'INTERNAL' : values.display_type === 'Exterieur' ? 'EXTERNAL' : null

    console.log('====================================');
    console.log(values);
    console.log('====================================');
    Api.get("/pages/"  , {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
      params: { 
        display_type: display_type,
        title: values.title !== '' ? values.title : null
       } 
    })
      .then((response) => {
        setPages(response.data?.pages)
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const validationSchema = Yup.object({
    display_type: Yup.string(),
    title: Yup.string(),
  });

  useEffect(() => {
    Api.get("/pages", {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setPages(response.data.pages ?? []);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="title-button">
        <h1 className="font-bold text-2xl">Vos Displays</h1>
        <ToastContainer
          style={{ top: "5rem", width: "300px" }}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
      <Box className="filter" display="flex" flexDirection="row">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchBdisplay(initialValues, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <FormControl>
              <Box display="flex">

                {/*Search fields */}

                <Box display="flex" maxWidth="1200px" sx={{ flexDirection: { md: "row", xs: "column" } }} marginBottom="1rem">
                  
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field
                      name="display_type"
                      component={Autocomplete}
                      options={['Interieur','Exterieur']}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => s}
                      onChange={(event, value) => {
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          display_type: value,
                        }));
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Display Type" sx={{width:200}} />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="Display Type" component={MzErrorText} />
                  </Box>

                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <TextField
                      placeholder="Title"
                      name="title"
                      onChange={(event, value) => {
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          title: event.target.value,
                        }));
                      }}
                      sx={{'::placeholder':{'fontWeight':600}}}
                    />
                    <ErrorMessage name="title" component={MzErrorText} />
                  </Box>

                  <Box marginRight="1rem" display={'flex'} alignItems={'center'} gap={1} mb>
                    <Button type="submit" onClick={()=> onSubmitSearchBdisplay(initialValues, access_token)} variant="primary">
                      <FilterList/>
                    </Button>
                    <Button type="submit" onClick={() => {
                       setFieldValue('display_type', '');
                       setFieldValue('title', '');
                       onSubmitSearchBdisplay(values,access_token)
                      }} variant="primary">
                      <Close/>
                    </Button>
                  </Box>


                </Box>
              </Box>
            </FormControl>
          )}
        </Formik>
      </Box>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
      
        {!pages ? (
          <div><CircularProgress/> </div>
        ) : pages?.length === 0 ? (
          <div>Aucun Bdisplay</div>
        )
        :
         (
          pages?.map((page) => {
            return (
              <div key={page.id} className="flex w-full flex-col relative drop-shadow-lg  rounded-xl bg-white">
                <div className="relative">
                  <img src={page.url_banner} className="page-banner object-cover w-full h-full" alt="banner" />
                  <div className="absolute top-0 flex justify-center items-center left-0 rounded-t-xl bg-slate-700 w-full h-full transition-all duration-300 ease-in-out opacity-0 hover:opacity-100 bg-opacity-0 hover:bg-opacity-60">
                  <div className="relative z-10">
                    <Button variant="secondary" sx={{ 'opacity': '1 !important', display:'flex', gap:0.5 }}>
                      <VisibilityOutlined/>
                      <NavLink to={"/admin/page/" + page.id}>
                        Voir Détails
                      </NavLink>
                    </Button>
                  </div>
                  </div>
                </div>

                <div className="my-4 p-2">
                  <h3 className="font-bold">{page?.identification} {page.title}</h3>
                  <p className="page-description">{page.display_type === 'INTERNAL' ? 'Interieur' : 'Exterieur'}</p>
                  <div className="flex gap-2 mt-6 mb-0 items-center justify-between px-2">
                    <Tooltip title="Voir Statistiques" arrow>
                      <NavLink
                            to={"/admin/page/" + page.id+"/statistics"}
                            className='text-purple-700 font-bold border border-purple-600 rounded-md p-[0.2rem]'
                            sx={{border:'1px solid' + colors.bd_Purple, width:40, height:30, borderRadius:1}}
                          >
                            <BarChartIcon/>
                            <Typography variant="p" fontWeight={500}>Statistiques</Typography>
                      </NavLink>
                    </Tooltip>
                    <div className="flex items-center gap-1">
                    <NavLink
                      to={"/admin/" + page.id + "/services"}
                      style={{
                        backgroundColor: "#7B3FFF",
                        textDecoration: "none",
                        display: "flex",
                        justifyContent: "center",
                        justifyItems: "center",
                        color: "white",
                        gap:3,
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingLeft: 11,
                        paddingRight: 11,
                        borderRadius: 7,
                      }}
                      >
                        <CategoryIcon/>
                        <Typography variant="p" fontWeight={500}>Services</Typography>
                    </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default Pages;
