import React from "react";
import prodIcon from "../../imgs/INPROD.svg";

function InProdOpen() {
  return (
    <div>
      <img src={prodIcon} style={{ opacity: "0.5", height: "50px", width: "50px" }} />
    </div>
  );
}

export default InProdOpen;
