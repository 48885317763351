import React from "react";
import { Box, Typography, Card } from "@mui/material";
import FB from '@mui/icons-material/Facebook';
import IG from '@mui/icons-material/Instagram';
import YTB from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import LIN from '@mui/icons-material/LinkedIn';

function IconLink({ title, hide, value }) {

  const iconPicker = () => {
    switch (title) {
      case 'Facebook':
        return <FB sx={{color: '#1877F2'}}/>;
      case 'Instagram':
        return <IG sx={{color: '#D300C5'}}/>;
      case 'Youtube':
        return <YTB sx={{color: '#FF0000'}}/>;
      case 'LinkedIn':
        return <LIN sx={{color: '#0072B1'}}/>;
      case 'Twitter':
        return <XIcon sx={{color: '#000000'}}/>
    }
  };
  // Ensure the URL has a protocol
  const fullUrl = value.startsWith('http://') || value.startsWith('https://')
    ? value
    : `https://${value}`;

  return (
    <Box sx={{ display: `${hide ? "none" : "flex"}` }} flexDirection="row" gap="0.5rem" alignItems="center">
      <a target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "black" }} href={fullUrl}>
        <Box className="well" display="flex" sx={{ alignItems: "center", justifyContent: "center", border:'.3px solid #E0E0E0', padding: "12px", borderRadius: "50%" }}>
          {iconPicker(title)}
        </Box>
      </a>
      <Box>
      </Box>
    </Box>
  );
}

export default IconLink;
