import React from "react";
import deliveredIcon from "../../imgs/DELIVERED.svg";
function DeliveredClose() {
  return (
    <div>
      <img src={deliveredIcon} style={{ height: "50px", width: "50px" }} />
    </div>
  );
}

export default DeliveredClose;
