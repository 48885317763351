import React from "react";
import deliveredIcon from "../../imgs/DELIVERED.svg";

function DeliveredOpen() {
  return (
    <div>
      <img src={deliveredIcon} style={{ opacity: "0.5", height: "50px", width: "50px" }} />
    </div>
  );
}

export default DeliveredOpen;
