import React from "react";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Autocomplete,
  StepButton,
  CircularProgress,
  Stack,
} from "@mui/material";
import { colors } from "../../theme/Colors";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import { Formik, Form, Field, useFormik } from "formik";
import DeliveredClose from "../../components/ui/DeliveredClose";
import DeliveredOpen from "../../components/ui/DeliveredOpen";
import DelieryClose from "../../components/ui/DelieryClose";
import DeliveryOpen from "../../components/ui/DeliveryOpen";
import InProdClose from "../../components/ui/InProdClose";
import InProdOpen from "../../components/ui/InProdOpen";
import AlertTitle from "@mui/material/AlertTitle";
import AlertError from "../../components/ui/AlertError";
import AlertSuccess from "../../components/ui/AlertSuccess";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation, Link, useNavigate } from "react-router-dom";
import FileIcon from "../../imgs/file.svg";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Close } from "@mui/icons-material";
import { chipSelector } from "../../utils/chipSelector";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";

function CommandeDetails() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  //navigate to create enterprise
  const location = useLocation();
  const id = location?.pathname?.split("/")[3];
  const idEntreprise = user?.user?.enterprise?.id;
  const [data, setData] = useState();
  const [change, setChange] = useState(false);
  //File config
  const FILE_SIZE = 5000 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/webp",
    "application/pdf",
  ];
  const navigate = useNavigate();
  //validation schema first form
  const validationSchemaFirstForm = Yup.object({
    number_of_cards: Yup.number()
      .typeError("ce champs doit etre un nombre")
      .required("ce champ est obligatoire"),
  });
  //deis singer
  const [devisSigned, setDevisSigned] = useState("");
  //validation schema add bon de commande file
  const validationSchemaBonCommande = Yup.object({
    purchase_order_file: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test(
        "fileSize",
        "Fichier volumineux",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Format du fichier invalide",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    estimate_file_signed: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test(
        "fileSize",
        "Fichier volumineux",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Format du fichier invalide",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  });

  //bon de commande name
  const [bonDeCommande, setBonDeCommande] = useState("");

  //formik first form
  const formikFirstForm = useFormik({
    initialValues: {
      number_of_cards: "",
    },
    validationSchema: validationSchemaFirstForm,
    onSubmit: () => {
      HandleCreateOrder(formikFirstForm.values.number_of_cards);
    },
  });

  const status = [
    { id: "COMMANDED", name: "Commandé" },
    { id: "SUSPENDED", name: "Suspendue" },
    { id: "ACTIVE", name: "Active" },
  ];

  const formRef = useRef();

  //formik second form
  const formikUploadBonDeCommande = useFormik({
    initialValues: {
      purchase_order_file: "",
      estimate_file_signed: "",
    },
    validationSchema: validationSchemaBonCommande,
    onSubmit: () => {
      UploadBonDeCommande();
    },
  });

  //config upload fichier bon de commande
  const [progressBon, setProgressBon] = useState();
  const [loadingDocBon, setLoadingDocBon] = useState(false);
  var configsBon = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgressBon(percentCompleted);
    },
  };

  //upload bon de commande
  const UploadBonDeCommande = () => {
    setLoadingDocBon(true);
    Api.post(
      Urls.UPLOAD_BON_DE_COMMANDE + data?.id + "/purchase_order_file",
      {
        purchase_order_file:
          formikUploadBonDeCommande.values.purchase_order_file,
        estimate_file_signed:
          formikUploadBonDeCommande.values.estimate_file_signed,
      },
      configsBon
    )
      .then((res) => {
        setLoadingDocBon(false);
        toast.success(res.data.message);
        formikUploadBonDeCommande.values.purchase_order_file = "";
        formikUploadBonDeCommande.values.estimate_file_signed = "";
        setChange(!change);
        setBonDeCommande("");
        setDevisSigned("");
      })
      .catch((err) => {
        //console.log(err);
        setLoadingDocBon(false);
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message); ////console.log(err);
        setChange(!change);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const [docToAddName, setDocToAddName] = useState();

  //create order
  const HandleCreateOrder = (number_of_cards) => {
    Api.post(
      Urls.CREATE_ORDER + idEntreprise + "/orders",
      {
        number_of_cards: number_of_cards,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((res) => {
        setChange(!change);
        toast.success(res.data.message);
      })
      .catch((err) => {
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  //modal config
  const [openActive, setOpenActive] = useState(false);
  const handleCloseActive = () => setOpenActive(false);

  //onPageChange

  const [loadingDoc, setLoadingDoc] = useState(false);
  const [progress, setProgress] = useState(null);
  //add docs
  const steps = [
    {
      label: "Etape 1",
      description: `  Demande de devis.`,
    },
    {
      label: "Etape 2",
      description: `Validation de devis.`,
    },
    {
      label: "Etape 3",
      description: `Commande.`,
    },
    {
      label: "Etape 4",
      description: `Création des comptes collaborateurs.`,
    },
    {
      label: "Etape 5",
      description: `Facturation.`,
    },
    {
      label: "Etape 6",
      description: `Paiement.`,
    },
    {
      label: "Etape 7",
      description: `Production.`,
    },
  ];
  const stepsProgression = [
    {
      label: "Etape 1",
      description: `En production`,
      status: "IN_PRODUCTION",
    },
    {
      label: "Etape 2",
      description: `Livraison`,
      status: "DELIVERY",
    },
    {
      label: "Etape 3",
      description: `Livrée`,
      status: "DELIVERED",
    },
  ];
  //state for modal confirmation
  const [confirmBonDeCommande, setConfirmBonDeCommande] = useState(false);
  const [confirmListeCollabs, setConfirmListeCollabds] = useState(false);
  const [confirmPayement, setConfirmPayement] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const stepperStyle = {
    ".MuiSvgIcon-root:not(.Mui-completed)": {
      color: colors.light,
    },
    ".MuiSvgIcon-root.Mui-active": {
      color: colors.main_purple,
      marginY: "-3px",
    },
  };
  const stepperStyleProgression = {
    ".css-z7uhs0-MuiStepConnector-line": {
      margin: "12px 8px",
    },
  };
  // donc name paiment
  const [paiement, setPaiement] = useState("");
  //Get order details
  useEffect(() => {
    Api.get(Urls.GET_ORDER_DETAILS + id, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((res) => {
        //console.log(res);
        setData(res?.data?.order);
        formikFirstForm.setFieldValue(
          "number_of_cards",
          res?.data?.order?.number_of_cards
            ? res?.data?.order?.number_of_cards
            : ""
        );
        setActiveStep(
          res?.data?.order?.status === "INIT"
            ? 0
            : res?.data?.order?.status === "ESTIMATE_UPLOADED"
            ? 1
            : res?.data?.order?.status === "PURCHASE_ORDER_UPLOADED"
            ? 2
            : res?.data?.order?.status === "PURCHASE_ORDER_CONFIRMED"
            ? 3
            : res?.data?.order?.status === "PURCHASE_ORDER_REFUSED"
            ? 2
            : res?.data?.order?.status === "LIST_COLLABORATORS_UPLOADED"
            ? 3
            : res?.data?.order?.status === "LIST_COLLABORATORS_ACCEPTED"
            ? 3
            : res?.data?.order?.status === "INVOICE_UPLOADED"
            ? 4
            : res?.data?.order?.status === "PAYMENT_MADE"
            ? 5
            : res?.data?.order?.status === "PAYMENT_REFUSED"
            ? 5
            : 6
        );
      })
      .catch((err) => {
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, [change]);
  //add fichier excel
  //check if email is unique
  Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
    return this.test("unique", message, function (list) {
      return list?.length === new Set(list?.map(mapper)).size;
    });
  });

  //File config
  const SUPPORTED_FORMATS_EXCEL = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/xlsx",
    "application/xls",
  ];

  //validation schema for submit
  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("Veuillez télécharger un fichier")
      .test(
        "fileSize",
        "Fichier volumineux",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Format du fichier invalide",
        (value) => value && SUPPORTED_FORMATS_EXCEL.includes(value.type)
      ),
    collaborators: Yup.array().required(),
  });

  //validation schema for user
  const schema = Yup.object().shape({
    NOM: Yup.string()
      .required("Nom manquant")
      .matches(
        /^[a-zA-ZàâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑù',ÉÈÀÙ/& .-]*$/,
        "Le nom doit contenir des caractères alphabétiques\n"
      ),
    Prénom: Yup.string()
      .required("Prénom manquant")
      .matches(
        /^[a-zA-ZàâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ',ÉÈÀÙ/& .-]*$/,
        "Le prenom doit contenir des caractères alphabétiques\n"
      ),
    Département: Yup.string()
      .required("Département manquant")
      .matches(
        /^[a-zA-Z0-9àâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ',ÉÈÀÙ/& .-]*$/,
        "Le département doit contenir des caractères alphabétiques\n"
      ),
    Fonction: Yup.string()
      .required("Fonction manquante")
      .matches(
        /^[a-zA-Z0-9àâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ'ÉÈÀÙ/& .-]*$/,
        "La fonction doit contenir des caractères alphabétiques\n"
      ),
    Email: Yup.string().email("Email invalide").required("Email manquant"),
  });

  const schemaListEmails = Yup.object().shape({
    listEmails: Yup.array().of(Yup.string()).unique("Emails dupliqués"),
  });
  const formRefPayement = useRef();
  const [listEmails, setListEmails] = useState([]);

  const [json, setJson] = useState();
  const [fileName, setFileName] = useState();
  const [excellErrors, setExcellErrors] = useState([]);
  const [validFile, setValidFile] = useState(null);

  const handleChange = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const pageExcell = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(pageExcell);

    setExcellErrors([]);
    setListEmails([]);

    jsonData.map((j) => {
      //add field expiration date
      j["expiration_date"] = formRef.current.values.expiration_date;

      //gather emails
      listEmails.push(j.Email);

      //is json valide ??
      schema.isValid(j).then((valid) => valid);
      schema
        .isValid(j)
        .then((valid) => (valid ? setValidFile(true) : setValidFile(false)));

      //create errors excel
      schema.validate(j).catch((error) => {
        setExcellErrors((oldarray) => [
          ...oldarray,
          `• Ligne ${j.__rowNum__ + 1} : ${error.message}`,
        ]);
        // //console.log(`error at line ${j.__rowNum__ + 1} : ${error.message}`);
      });
    });

    // //console.log(listEmails);

    //is list emails valide ??
    schemaListEmails.isValid({ listEmails }).then((valid) => valid);
    schemaListEmails
      .isValid({ listEmails })
      .then((valid) => (valid ? setValidFile(true) : setValidFile(false)));

    //are emails unique
    schemaListEmails.validate({ listEmails }).catch((error) => {
      setExcellErrors((oldarray) => [...oldarray, `•  ${error.message}`]);
      // //console.log(`${error.message}`);
    });

    setJson(jsonData);

    // //console.log("Excell data in JSON : ", jsonData);
  };

  const [realCollaborators, setRealCollaborators] = useState([]);
  const [progressExcel, setProgressExcel] = useState(0);
  const [loadingExcel, setLoadingExcel] = useState(false);
  var configsExcel = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgressExcel(percentCompleted);
    },
  };
  const onSubmitAddCollabs = (values) => {
    setRealCollaborators([]);

    // //console.log("values from onsubmit", values);

    json.map((collab) => {
      //add field expiration date
      realCollaborators.push({
        first_name: (
          collab.Prénom.charAt(0).toUpperCase() +
          collab.Prénom.substring(1).toLowerCase()
        ).trim(),
        last_name: collab.NOM.toUpperCase().trim(),
        email: collab.Email.trim(),
        department: collab.Département.trim(),
        function: collab.Fonction.trim(),
        phone_number: collab.Téléphone,
      });
    });
    setLoadingExcel(true);
    // //console.log("data to send gherbi", realCollaborators);
    Api.post(
      Urls.UPLOAD_LIST_COLLABORATORS_ORDER + `${data?.id}/orderedCollaborators`,
      {
        collaborators: realCollaborators,
        file: values.file,
      },
      configsExcel
    )
      .then((response) => {
        // //console.log("RESPONSE GENERATE COLLABS", response);
        formRef.current.values.file = "";
        formRef.current.values.expiration_date = "";
        formRef.current.values.collaborators = [];
        setDocToAddName("");
        if (response.status === 201) {
          // //console.log(response);
          setChange(!change);

          toast.success(response.data.message);
        }
        setExcellErrors([]);
        setLoadingExcel(false);
        setValidFile(null);
      })
      .catch((error) => {
        // //console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        setChange(!change);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();

  //doc name estimate
  const [docEstimate, setDocEstimate] = useState("");
  //document name facture
  const [facture, setFacture] = useState("");
  //validationSchemaStep3

  const validationSchemaStep3 = Yup.object({
    payment_date: Yup.date().required("ce champ est obligatoire"),
    payment_mode: Yup.string().required("ce champ est obligatoire"),
    amount: Yup.number()
      .typeError("entrer une valeur valide")
      .required("ce champ est obligatoire"),
    payment_file: Yup.mixed()
      .required("Veuillez télécharger un fichier")
      .test(
        "fileSize",
        "Fichier volumineux",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Format du fichier invalide",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  });

  const paymentMode = {
    ELECTRONIC_BANK_TRANSFERS: "VIREMENTS BANCAIRES",
  };

  //make payment
  const [loadingPayement, setLoadingPayement] = useState(false);
  const [progressPayement, setprogressPayement] = useState(0);
  var configsPayement = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setprogressPayement(percentCompleted);
    },
  };
  const makePayment = (values) => {
    setLoadingPayement(true);
    ////console.log(values);
    Api.post(
      Urls.CREATE_PAYMENT + data?.invoice?.id + "/payments",
      {
        payment_date: values.payment_date,
        payment_mode: values.payment_mode,
        amount: values.amount,
        payment_file: values.payment_file,
      },
      configsPayement
    )
      .then((res) => {
        setChange(!change);
        ////console.log(res);
        formRefPayement.current.values = formRefPayement.current.initialValues;
        ////console.log("Form ref", formRefPayement.current.initialValues);
        setFacture("");
        toast.success("Paiement effectuer");
        setLoadingPayement(false);
      })
      .catch((err) => {
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        setChange(!change);
        formRefPayement.current.values = formRefPayement.current.initialValues;
        setFacture("");
        setLoadingPayement(false);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  //update payement
  const UpdatePayment = (values) => {
    //console.log(values);
    setLoadingPayement(true);
    ////console.log(values);
    Api.post(
      "/payments/" + data?.invoice?.payment?.id,
      {
        payment_date: values.payment_date,
        payment_mode: values.payment_mode,
        amount: values.amount,
        payment_file: values.payment_file,
      },
      configsPayement
    )
      .then((res) => {
        setChange(!change);
        ////console.log(res);
        formRefPayement.current.values = formRefPayement.current.initialValues;
        ////console.log("Form ref", formRefPayement.current.initialValues);
        setFacture("");
        toast.success("Paiement effectuer");
        setLoadingPayement(false);
      })
      .catch((err) => {
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        setChange(!change);
        formRefPayement.current.values = formRefPayement.current.initialValues;
        setFacture("");
        setLoadingPayement(false);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  //hover
  const [hover, setHover] = useState(false);
  const renderSwitch = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography
              sx={{
                marginBottom: "1.5rem",
                fontSize: "0.8rem",
                fontWeight: "600",
                color: `${colors.main_purple}`,
              }}
            >
              {step.description}
            </Typography>
            <Box>
              {/* NOMBRE DE CARTES */}
              <form onSubmit={formikFirstForm.handleSubmit}>
                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography mb>Nombre d’abonnements</Typography>
                  {data?.number_of_cards ? (
                    <Box display="flex" alignItems="center" gap="0.5rem">
                      <Typography>Nombre d’abonnements commandées :</Typography>
                      <Typography color={colors.bd_Purple} fontWeight="bold">
                        {data?.number_of_cards}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="row"
                      sx={{ alignItems: "center" }}
                      gap="0.5rem"
                    >
                      <TextField
                        value={formikFirstForm.values.number_of_cards}
                        name="number_of_cards"
                        onChange={formikFirstForm.handleChange}
                        label="Nombre de carte"
                        size="small"
                      ></TextField>
                      <Button variant="primary">
                        <Typography>Enregistrer</Typography>
                      </Button>
                    </Box>
                  )}
                </Box>
              </form>
            </Box>
          </>
        );
      case 1:
        return (
          <>
            {/* DEVIS */}
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography sx={{ display: "inline", marginRight: "1rem" }} mb>
                Devis
              </Typography>
              <Typography sx={{ display: "inline" }} mb variant="secondary">
                (en attente du devis Bdigitall)
              </Typography>

              {/* devis fields */}
              <Box
                display="flex"
                marginTop="0.5rem"
                flexDirection="column"
                gap="0.5rem"
                maxWidth={350}
                mb
              >
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="secondary">Prix U HT :</Typography>
                  <Typography color={colors.bd_Purple} fontWeight="bold">
                    {data?.estimate?.unit_price
                      ? data?.estimate?.unit_price
                      : "-"}{" "}
                    DZD
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="secondary">Montant HT :</Typography>
                  <Typography color={colors.bd_Purple} fontWeight="bold">
                    {data?.estimate?.amount_excluding_tax
                      ? data?.estimate?.amount_excluding_tax
                      : "-"}{" "}
                    DZD
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="secondary">Montant TTC :</Typography>
                  <Typography color={colors.bd_Purple} fontWeight="bold">
                    {data?.estimate?.amount_including_tax
                      ? data?.estimate?.amount_including_tax
                      : "-"}{" "}
                    DZD
                  </Typography>
                </Box>
              </Box>
              {/* telecharger devis */}
              <Box margin="1rem 0rem">
                <Button
                  disabled={data?.estimate ? false : true}
                  onClick={() => {
                    window.open(data?.estimate?.url_estimate_file, "_blank");
                  }}
                  variant="primary"
                >
                  <Typography>Télécharger devis</Typography>
                </Button>
              </Box>
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setConfirmBonDeCommande(true);
              }}
            >
              {/* BON DE COMMANDE */}
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography mb>Bon de commande</Typography>
                {data?.status === "PURCHASE_ORDER_UPLOADED" ||
                data?.status === "ESTIMATE_UPLOADED" ||
                data?.status === "INIT" ||
                data?.status === "PURCHASE_ORDER_REFUSED" ? (
                  <Box display="flex" gap="1rem" alignItems="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Button
                        disabled={
                          data?.estimate?.unit_price &&
                          data?.estimate?.amount_excluding_tax &&
                          data?.estimate?.amount_including_tax
                            ? false
                            : true
                        }
                        variant="primary"
                      >
                        <input
                          style={{
                            opacity: "0",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            cursor: "pointer",
                          }}
                          type="file"
                          name="estimate_file_signed"
                          onChange={(e) => {
                            const doc = e.target.files[0];
                            formikUploadBonDeCommande.setFieldValue(
                              "estimate_file_signed",
                              e.target.files[0]
                            );
                            setDevisSigned(doc.name);
                          }}
                        />
                        <Typography>Déposer Devis et CGV signés</Typography>
                      </Button>
                      <MzErrorText>
                        {formikUploadBonDeCommande.errors.estimate_file_signed}
                      </MzErrorText>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Button
                        disabled={
                          data?.estimate?.unit_price &&
                          data?.estimate?.amount_excluding_tax &&
                          data?.estimate?.amount_including_tax
                            ? false
                            : true
                        }
                        variant="primary"
                      >
                        <input
                          style={{
                            opacity: "0",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            cursor: "pointer",
                          }}
                          type="file"
                          name="purchase_order_file"
                          onChange={(e) => {
                            const doc = e.target.files[0];
                            formikUploadBonDeCommande.setFieldValue(
                              "purchase_order_file",
                              e.target.files[0]
                            );
                            setBonDeCommande(doc.name);
                          }}
                        />
                        <Typography>Déposer bon de commande</Typography>
                      </Button>
                      <MzErrorText>
                        {formikUploadBonDeCommande.errors.purchase_order_file}
                      </MzErrorText>
                    </Box>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={
                        !formikUploadBonDeCommande.errors.purchase_order_file &&
                        formikUploadBonDeCommande.values.estimate_file_signed
                          ? false
                          : true
                      }
                    >
                      <Typography>Confirmer</Typography>
                    </Button>
                    <ConfirmationModal
                      title="Confirmer le bon de commande"
                      message="Êtes-vous sûr de bien vouloir confirmer le bon de commande ?"
                      acceptLabel="oui"
                      refuseLabel="non"
                      open={confirmBonDeCommande}
                      onClose={() => setConfirmBonDeCommande(false)}
                      acceptMethod={formikUploadBonDeCommande.handleSubmit}
                      acceptMethodParams="SUSPENDED"
                    />
                    {loadingDocBon && (
                      <Stack
                        margin="0rem 1rem"
                        gap="0.5rem"
                        direction="row"
                        sx={{ alignItems: "center", justifyContent: "center" }}
                      >
                        <Typography color={colors.main_purple}>
                          {progressBon ? progressBon + "%" : null}
                        </Typography>
                        <CircularProgress />
                      </Stack>
                    )}
                  </Box>
                ) : null}
                {data?.estimate?.url_estimate_file_signed ? (
                  <Box margin="0.5rem 0rem">
                    <Typography
                      onClick={() => {
                        window.open(
                          data?.estimate?.url_estimate_file_signed,
                          "_blank"
                        );
                      }}
                      variant="secondary"
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Devis et CGV signés
                    </Typography>
                  </Box>
                ) : null}
                {data?.url_purchase_order_file ? (
                  <Box margin="0.5rem 0rem">
                    <Typography
                      onClick={() => {
                        window.open(data?.url_purchase_order_file, "_blank");
                      }}
                      variant="secondary"
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Bon_de_commande
                    </Typography>
                  </Box>
                ) : null}

                {bonDeCommande &&
                formikUploadBonDeCommande.values.purchase_order_file ? (
                  <Box
                    margin="0.5rem 0rem"
                    display="flex"
                    alignItems="center"
                    gap="0.7rem"
                  >
                    <Typography
                      sx={{ textDecoration: "underline" }}
                      variant="secondary"
                    >
                      {bonDeCommande}
                    </Typography>
                    <Close
                      onClick={() => {
                        formikUploadBonDeCommande.setFieldValue(
                          "purchase_order_file",
                          ""
                        );
                        setBonDeCommande("");
                      }}
                    />
                  </Box>
                ) : null}
                {devisSigned &&
                formikUploadBonDeCommande.values.estimate_file_signed ? (
                  <Box
                    margin="0.5rem 0rem"
                    display="flex"
                    alignItems="center"
                    gap="0.7rem"
                  >
                    <Typography
                      sx={{ textDecoration: "underline" }}
                      variant="secondary"
                    >
                      {devisSigned}
                    </Typography>
                    <Close
                      onClick={() => {
                        formikUploadBonDeCommande.setFieldValue(
                          "estimate_file_signed",
                          ""
                        );
                        setDevisSigned("");
                      }}
                    />
                  </Box>
                ) : null}
                {data?.status === "PURCHASE_ORDER_REFUSED" && (
                  <Box
                    maxWidth="400px"
                    sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
                  >
                    <Typography sx={{ color: "red", fontWeight: "500" }}>
                      Motif de refus :
                    </Typography>
                    <Typography>{data?.reason_for_refusal}</Typography>{" "}
                  </Box>
                )}
              </Box>
            </form>
          </>
        );
      case 3:
        return (
          <>
            <Typography
              sx={{
                marginBottom: "1.5rem",
                fontSize: "0.8rem",
                fontWeight: "600",
                color: `${colors.main_purple}`,
              }}
            >
              {step.description}
            </Typography>
            <Box>
              {/* NOMBRE DE CARTES */}
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography mb>Liste des collaborateurs</Typography>
                {loadingExcel && (
                  <Stack
                    margin="0rem 1rem"
                    gap="0.5rem"
                    direction="row"
                    sx={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Typography color={colors.main_purple}>
                      {progressExcel ? progressExcel + "%" : null}
                    </Typography>
                    <CircularProgress />
                  </Stack>
                )}
                <Box
                  flexWrap="wrap"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="small">
                    Importation automatique
                  </Typography>
                  <Button variant="primary">
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to="/EMAILS.xlsx"
                      target="_blank"
                      download
                    >
                      Télécharger fichier exemplaire
                    </Link>{" "}
                  </Button>
                </Box>
              </Box>
              {/* form add doc */}
              <Formik
                innerRef={formRef}
                initialValues={{
                  expiration_date: new Date(year + 1, month, day)
                    .toISOString()
                    .slice(0, 10),
                  file: "",
                  collaborators: [],
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  setConfirmListeCollabds(true);
                }}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    {/* doc title */}
                    {loadingDoc && (
                      <Stack
                        margin="1rem 0rem"
                        gap="0.5rem"
                        direction="row"
                        sx={{ alignItems: "center", justifyContent: "center" }}
                      >
                        <Typography color={colors.main_purple}>
                          {progress ? progress + "%" : null}
                        </Typography>
                        <CircularProgress />
                      </Stack>
                    )}
                    {/* doc file */}

                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ marginBottom: "1rem" }}
                    >
                      <Button
                        disabled={
                          data?.status === "PURCHASE_ORDER_CONFIRMED" ||
                          data?.status === "LIST_COLLABORATORS_UPLOADED"
                            ? false
                            : true
                        }
                        variant="upload"
                        sx={{
                          height: "250px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        component="label"
                      >
                        <img
                          src={FileIcon}
                          width="50px"
                          alt=""
                          style={{ marginBottom: "0.5rem" }}
                        />
                        <input
                          style={{
                            opacity: "0%",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                          }}
                          id="file"
                          name="file"
                          type="file"
                          // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={(event) => {
                            const doc = event.target.files[0];
                            handleChange(event);

                            setFieldValue("file", event.target.files[0]);
                            setDocToAddName(doc.name);
                          }}
                        />
                        <Typography
                          sx={{ fontSize: "1rem", fontWeight: "400" }}
                        >
                          Déposer votre fichier
                        </Typography>
                      </Button>
                    </Box>
                    <MzErrorText>{errors.file}</MzErrorText>

                    {docToAddName && (
                      <Box>
                        <Typography
                          sx={{
                            margin: "1rem 0rem",
                            color: `${colors.main_purple}`,
                            fontWeight: "500",
                            textDecoration: "underline",
                          }}
                          fontSize="0.875rem"
                        >
                          {docToAddName}
                        </Typography>
                      </Box>
                    )}
                    {excellErrors.length !== 0 && (
                      <AlertError severity="error">
                        <AlertTitle>
                          Erreur dans votre fichier excell
                        </AlertTitle>
                        {excellErrors.map((e) => (
                          <Typography key={e}>{e}</Typography>
                        ))}
                      </AlertError>
                    )}
                    {validFile === true && excellErrors.length === 0 ? (
                      <AlertSuccess severity="error">
                        Votre fichier excel est prêt à être envoyé
                      </AlertSuccess>
                    ) : (
                      ""
                    )}

                    {/* confirm add doc */}
                    <Box display="flex" flexDirection="row" gap="0.5rem">
                      <Button
                        disabled={
                          values.file &&
                          !errors.file &&
                          validFile &&
                          excellErrors?.length === 0
                            ? false
                            : true
                        }
                        variant="primary"
                        type="submit"
                      >
                        Envoyer
                      </Button>
                      <ConfirmationModal
                        title="Confirmer la liste des collaborateurs"
                        message="Êtes-vous sûr de bien vouloir confirmer la liste des collaborateurs ?"
                        acceptLabel="oui"
                        refuseLabel="non"
                        open={confirmListeCollabs}
                        onClose={() => setConfirmListeCollabds(false)}
                        acceptMethod={() =>
                          onSubmitAddCollabs(values, access_token)
                        }
                        acceptMethodParams="SUSPENDED"
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
              {/* end form add DOC */}
              {/* DEVIS */}
              {data?.url_ordered_collaborators_file && (
                <Box>
                  <Typography
                    onClick={() => {
                      window.open(
                        data?.url_ordered_collaborators_file,
                        "_blank"
                      );
                    }}
                    sx={{
                      cursor: "pointer",
                      margin: "1rem 0rem",
                      color: `${colors.main_purple}`,
                      fontWeight: "500",
                      textDecoration: "underline",
                    }}
                    fontSize="0.875rem"
                  >
                    Liste_collaborateurs
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        );
      case 4:
        return (
          <>
            <Box margin="1rem 0rem">
              <Typography mb>Facture</Typography>
            </Box>
            <Box marginBottom="1rem">
              <Button
                onClick={() => {
                  window.open(data?.invoice?.url_invoice_file, "_blank");
                }}
                disabled={
                  data?.status === "INIT" ||
                  data?.status === "ESTIMATE_UPLOADED" ||
                  data?.status === "PURCHASE_ORDER_UPLOADED" ||
                  data?.status === "PURCHASE_ORDER_CONFIRMED" ||
                  data?.status === "LIST_COLLABORATORS_UPLOADED" ||
                  data?.status === "LIST_COLLABORATORS_ACCEPTED"
                    ? true
                    : false
                }
                variant="primary"
              >
                <Typography>Télécharger la facture</Typography>
              </Button>
            </Box>
          </>
        );
      case 5:
        return (
          <>
            <Box marginBottom="1rem">
              <Typography mb>Paiement</Typography>
            </Box>
            <Formik
              innerRef={formRefPayement}
              //initial values
              initialValues={{
                payment_date: data?.invoice?.payment?.payment_date
                  ? data?.invoice?.payment?.payment_date
                  : "",
                payment_mode: data?.invoice?.payment?.payment_mode
                  ? data?.invoice?.payment?.payment_mode
                  : "",
                amount: data?.invoice?.payment?.amount
                  ? data?.invoice?.payment?.amount
                  : "",
                payment_file: data?.invoice?.payment?.payment_file
                  ? data?.invoice?.payment.payment_file
                  : "",
              }}
              validationSchema={validationSchemaStep3}
              onSubmit={(values) => {
                setConfirmPayement(true);
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="0.5rem">
                    <Box alignItems="center" marginRight="1rem" mb>
                      <Field
                        value={values.payment_date}
                        disabled={
                          data?.status === "INVOICE_UPLOADED" ||
                          data?.status === "PAYMENT_REFUSED"
                            ? false
                            : true
                        }
                        show={false}
                        fullWidth
                        name="payment_date"
                        as={MyDPFullDate}
                        max={values.expirationDateMax}
                        setFieldValue={setFieldValue}
                        variant="outlined"
                        label="Date du paiement"
                        size="small"
                      />
                      {touched.payment_date && (
                        <MzErrorText>{errors.payment_date}</MzErrorText>
                      )}
                    </Box>
                    <Box alignItems="center" marginRight="1rem" mb>
                      <Field
                        disabled={
                          data?.status === "INVOICE_UPLOADED" ||
                          data?.status === "PAYMENT_REFUSED"
                            ? false
                            : true
                        }
                        sx={{ maxWidth: "350px" }}
                        name="payment_mode"
                        component={Autocomplete}
                        value={values.payment_mode ? values.payment_mode : null}
                        options={["ELECTRONIC_BANK_TRANSFERS"]}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        getOptionLabel={(s) => paymentMode[`${s}`]}
                        onChange={(event, value) => {
                          setFieldValue("payment_mode", value ? value : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{ maxWidth: "350px" }}
                            {...params}
                            name="autocomplete"
                            label="Méthode de paiement"
                          />
                        )}
                        size="small"
                      />
                      {touched.payment_mode && (
                        <MzErrorText>{errors.payment_mode}</MzErrorText>
                      )}
                    </Box>
                    <Box>
                      <Box
                        position="relative"
                        alignItems="center"
                        marginRight="1rem"
                        display="flex"
                        gap="0.5rem"
                        mb
                      >
                        <Field
                          value={values.amount}
                          disabled={
                            data?.status === "INVOICE_UPLOADED" ||
                            data?.status === "PAYMENT_REFUSED"
                              ? false
                              : true
                          }
                          label="Montant"
                          name="amount"
                          as={TextField}
                          type="input"
                          size="small"
                        />
                        <Typography>DZD</Typography>
                        <Box
                          onMouseEnter={() => setHover(true)}
                          onMouseLeave={() => setHover(false)}
                        >
                          <ReportGmailerrorredOutlinedIcon />
                        </Box>
                        {hover && (
                          <Box
                            sx={{
                              background: `${colors.paperLightGray}`,
                              border: `2px solid ${colors.main_purple}`,
                              borderRadius: "15px",
                              padding: "1rem",
                              maxWidth: "300px",
                              position: "absolute",
                              top: "-75px",
                              left: "370px",
                            }}
                          >
                            <Typography variant="text_gray">
                              Veuillez s'il vous plaît utiliser le point "." à
                              la place de la virgule "," pour saisir un chiffre
                              décimal
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      {touched.amount && (
                        <MzErrorText>{errors.amount}</MzErrorText>
                      )}
                    </Box>
                    <Box>
                      <Box
                        flexWrap="wrap"
                        display="flex"
                        alignItems="center"
                        gap="1rem"
                      >
                        <Button
                          disabled={
                            values.payment_date === "" ||
                            values.payment_mode === "" ||
                            values.amount === ""
                              ? true
                              : false
                          }
                          sx={{ cursor: "pointer" }}
                          variant="primary"
                        >
                          <input
                            //disabled={data?.status !== "INVOICE_UPLOADED" || data?.status !== "PAYMENT_REFUSED" || values.payment_date === "" || values.payment_mode === "" || values.amount === "" ? true : data?.status !== "PAYMENT_REFUSED" ? true : false}
                            type="file"
                            name="payment_file"
                            style={{
                              opacity: "0",
                              height: "100%",
                              width: "100%",
                              position: "absolute",
                              cursor: "pointer",
                            }}
                            onChange={(e) => {
                              const doc = e.target.files[0];
                              setFieldValue("payment_file", e.target.files[0]);
                              setFacture(doc.name);
                            }}
                          />
                          <Typography sx={{ cursor: "pointer" }}>
                            Télécharger preuve de paiement
                          </Typography>
                        </Button>
                        <Box>
                          <Button
                            disabled={
                              values.payment_date === "" ||
                              values.payment_mode === "" ||
                              values.amount === "" ||
                              values.payment_file === ""
                                ? true
                                : false
                            }
                            type="submit"
                            variant="primary"
                          >
                            Confirmer
                          </Button>
                        </Box>
                        <ConfirmationModal
                          title="Confirmer le paiement"
                          message="Êtes-vous sûr de bien vouloir confirmer le paiement ?"
                          acceptLabel="oui"
                          refuseLabel="non"
                          open={confirmPayement}
                          onClose={() => setConfirmPayement(false)}
                          acceptMethod={() =>
                            data?.status === "PAYMENT_REFUSED"
                              ? UpdatePayment(values)
                              : makePayment(values)
                          }
                          acceptMethodParams="SUSPENDED"
                        />

                        {loadingPayement && (
                          <Stack
                            margin="0rem 0rem"
                            gap="0.5rem"
                            direction="row"
                            sx={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography color={colors.main_purple}>
                              {progressPayement ? progressPayement + "%" : null}
                            </Typography>
                            <CircularProgress />
                          </Stack>
                        )}
                      </Box>
                      {touched.payment_file && (
                        <MzErrorText>{errors.payment_file}</MzErrorText>
                      )}
                      {facture && values.payment_file && (
                        <Box display="flex" alignItems="center" gap="0.5rem">
                          <Typography
                            sx={{
                              cursor: "pointer",
                              margin: "1rem 0rem",
                              color: `${colors.main_purple}`,
                              fontWeight: "500",
                              textDecoration: "underline",
                            }}
                            fontSize="0.875rem"
                          >
                            {facture}
                          </Typography>
                          <Close
                            onClick={() => {
                              setFieldValue("payment_file", "");
                              setFacture("");
                            }}
                          />
                        </Box>
                      )}
                      {data?.invoice?.payment?.url_payment_file && (
                        <Box display="flex" alignItems="center" gap="0.5rem">
                          <Typography
                            onClick={() =>
                              window.open(
                                data?.invoice?.payment?.url_payment_file,
                                "_blank"
                              )
                            }
                            sx={{
                              cursor: "pointer",
                              margin: "1rem 0rem",
                              color: `${colors.main_purple}`,
                              fontWeight: "500",
                              textDecoration: "underline",
                            }}
                            fontSize="0.875rem"
                          >
                            Preuve de paiement
                          </Typography>
                        </Box>
                      )}
                      {data?.status === "PAYMENT_REFUSED" && (
                        <Box
                          maxWidth="400px"
                          sx={{
                            whiteSpace: "pre-line",
                            wordBreak: "break-word",
                          }}
                        >
                          <Typography sx={{ color: "red", fontWeight: "500" }}>
                            Motif de refus :
                          </Typography>
                          <Typography>{data?.reason_for_refusal}</Typography>{" "}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        );
      case 6:
        return (
          <Box>
            <Box margin="1rem 0rem">
              <Typography mb>Etat de la commande</Typography>
            </Box>
            <Box>
              <Stepper
                alternativeLabel
                sx={stepperStyleProgression}
                orientation="horizontal"
              >
                {stepsProgression.map((step, index) => (
                  <Step
                    sx={{ margin: "0rem", maxWidth: "170px" }}
                    key={step.label}
                    active={
                      index === 2
                        ? data?.status === "DELIVERED"
                          ? true
                          : false
                        : index === 1
                        ? data?.status === "DELIVERY" ||
                          data?.status === "DELIVERED"
                          ? true
                          : false
                        : index === 0
                        ? data?.status === "IN_PRODUCTION" ||
                          data?.status === "DELIVERY" ||
                          data?.status === "DELIVERED"
                          ? true
                          : false
                        : null
                    }
                  >
                    <StepLabel
                      sx={{ padding: "0rem", margin: "0rem" }}
                      StepIconComponent={
                        index === 2
                          ? data?.status === "DELIVERED"
                            ? DeliveredClose
                            : DeliveredOpen
                          : index === 1
                          ? data?.status === "DELIVERY" ||
                            data?.status === "DELIVERED"
                            ? DelieryClose
                            : DeliveryOpen
                          : index === 0
                          ? data?.status === "IN_PRODUCTION" ||
                            data?.status === "DELIVERY" ||
                            data?.status === "DELIVERED"
                            ? InProdClose
                            : InProdOpen
                          : null
                      }
                    >
                      {" "}
                      <Box>
                        <Typography
                          color={
                            index === 2
                              ? data?.status === "DELIVERED"
                                ? colors.bd_Purple
                                : colors.light
                              : index === 1
                              ? data?.status === "DELIVERY" ||
                                data?.status === "DELIVERED"
                                ? colors.bd_Purple
                                : colors.light
                              : index === 0
                              ? data?.status === "IN_PRODUCTION" ||
                                data?.status === "DELIVERY" ||
                                data?.status === "DELIVERED"
                                ? colors.bd_Purple
                                : colors.light
                              : null
                          }
                          fontWeight={
                            index === 2
                              ? data?.status === "DELIVERED"
                                ? "600"
                                : "normal"
                              : index === 1
                              ? data?.status === "DELIVERY" ||
                                data?.status === "DELIVERED"
                                ? "600"
                                : "normal"
                              : index === 0
                              ? data?.status === "IN_PRODUCTION" ||
                                data?.status === "DELIVERY" ||
                                data?.status === "DELIVERED"
                                ? "600"
                                : "normal"
                              : null
                          }
                        >
                          {step.description}
                        </Typography>
                      </Box>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        );
    }
  };

  return (
    <Box>
      {/* Header Section */}
      <Box>
        <ToastContainer
          style={{ top: "5rem", width: "300px" }}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        <Typography sx={{ marginBottom: "1rem", fontWeight: "500" }}>
          Détail de la commande
        </Typography>
        <Box
          marginBottom="1rem"
          display="flex"
          alignItems="center"
          gap="0.5rem"
        >
          <Typography sx={{ fontWeight: "500" }}>
            Numéro de la commande
          </Typography>
          <Typography sx={{ color: `${colors.bd_Purple}`, fontWeight: "500" }}>
            #{data?.id}
          </Typography>
        </Box>
        <Box marginBottom="1rem" display="flex" alignItems="center" gap="1rem">
          <Typography sx={{ fontWeight: "500" }}>
            Etat de la commande
          </Typography>
          {chipSelector(data?.status)}
        </Box>

        {/* FORMULAIRE  */}
      </Box>

      {/* stepper */}
      <Box sx={{ maxWidth: "800px" }}>
        {/* <Stepper
          nonLinear
          activeStep={activeStep}
          orientation="vertical"
          sx={stepperStyle}
        >
          <Step key={steps[6].label} completed={completed[6]}>
            <StepButton disableRipple color="inherit" onClick={handleStep(6)}>
              <StepLabel sx={{ padding: "0rem" }}>
                <Box display="flex" flexDirection="column">
                  <Typography>{steps[6].label}</Typography>
                </Box>
                <Typography
                  color={colors.bd_Purple}
                  fontSize="0.7rem"
                  fontWeight="bold"
                >
                  {steps[6].description}
                </Typography>
              </StepLabel>
            </StepButton>
            <StepContent>{renderSwitch(6)}</StepContent>
          </Step>
        </Stepper> */}

        {/* ----------------- */}
        {/* ----------------- */}
        {/* ----------------- */}
        <Box>
          <Box>
            <Stepper
              alternativeLabel
              sx={stepperStyleProgression}
              orientation="horizontal"
            >
              {stepsProgression.map((step, index) => (
                <Step
                  sx={{ margin: "0rem", maxWidth: "170px" }}
                  key={step.label}
                  active={
                    index === 2
                      ? data?.status === "DELIVERED"
                        ? true
                        : false
                      : index === 1
                      ? data?.status === "DELIVERY" ||
                        data?.status === "DELIVERED"
                        ? true
                        : false
                      : index === 0
                      ? data?.status === "IN_PRODUCTION" ||
                        data?.status === "DELIVERY" ||
                        data?.status === "DELIVERED"
                        ? true
                        : false
                      : null
                  }
                >
                  <StepLabel
                    sx={{ padding: "0rem", margin: "0rem" }}
                    StepIconComponent={
                      index === 2
                        ? data?.status === "DELIVERED"
                          ? DeliveredClose
                          : DeliveredOpen
                        : index === 1
                        ? data?.status === "DELIVERY" ||
                          data?.status === "DELIVERED"
                          ? DelieryClose
                          : DeliveryOpen
                        : index === 0
                        ? data?.status === "IN_PRODUCTION" ||
                          data?.status === "DELIVERY" ||
                          data?.status === "DELIVERED"
                          ? InProdClose
                          : InProdOpen
                        : null
                    }
                  >
                    {" "}
                    <Box>
                      <Typography
                        color={
                          index === 2
                            ? data?.status === "DELIVERED"
                              ? colors.bd_Purple
                              : colors.light
                            : index === 1
                            ? data?.status === "DELIVERY" ||
                              data?.status === "DELIVERED"
                              ? colors.bd_Purple
                              : colors.light
                            : index === 0
                            ? data?.status === "IN_PRODUCTION" ||
                              data?.status === "DELIVERY" ||
                              data?.status === "DELIVERED"
                              ? colors.bd_Purple
                              : colors.light
                            : null
                        }
                        fontWeight={
                          index === 2
                            ? data?.status === "DELIVERED"
                              ? "600"
                              : "normal"
                            : index === 1
                            ? data?.status === "DELIVERY" ||
                              data?.status === "DELIVERED"
                              ? "600"
                              : "normal"
                            : index === 0
                            ? data?.status === "IN_PRODUCTION" ||
                              data?.status === "DELIVERY" ||
                              data?.status === "DELIVERED"
                              ? "600"
                              : "normal"
                            : null
                        }
                      >
                        {step.description}
                      </Typography>
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        {/* ----------------- */}
      </Box>
    </Box>
  );
}

export default CommandeDetails;
