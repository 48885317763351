import React from "react";
import { Box, Typography } from "@mui/material";
import Soon from "../../imgs/bientotDispobile.svg";

function ComingSoon() {
  return (
    <Box sx={{ height: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="1rem">
        <img src={Soon} alt="Bientot Disponible" style={{ width: "20rem" }} />
        <Typography fontSize="1.5rem">Bientot disponible</Typography>
      </Box>
    </Box>
  );
}

export default ComingSoon;
