import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import API from "../../api/Api_1_3";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const KPIsSettings = () => {
    const { user } = useAuthContext();
    const access_token = user?.access_token;
    const [stats, setStats] = useState({});
    const navigate = useNavigate();
    const [gram, setGram] = useState(200);
    const [gramBrochure, setGramBrochure] = useState(80);
    const [types, setTypes] = useState([]);
    const [nbrPages, setNbrPages] = useState(20);

    const initialValues = {
        visit_card_unit_price: stats.visit_card_unit_price ?? 0,
        brochure_unit_price: stats.brochure_unit_price ?? 0,
        nbr_scan_wanted_per_month: stats.nbr_scan_wanted_per_month ?? 0,
        grammage: stats.visit_card_weight ?? 200,
        brochure_weight: stats.brochure_weight ?? 200,
        bdisplay_brochure_format: stats.bdisplay_brochure_format ?? null
    };

    const validationSchema = Yup.object({
        visit_card_unit_price: Yup.number().nullable(),
        brochure_unit_price: Yup.number().nullable(),
        nbr_scan_wanted_per_month: Yup.number().nullable(),
        grammage: Yup.number().nullable()
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const filteredValues = Object.fromEntries(
                Object.entries(values).filter(([_, v]) => v != null && v !== '' && v !== 0)
            );

            API.post('/setting', filteredValues, {
                headers: {
                    "Content-type": "application/json",
                    accept: "application/json",
                    authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                toast.success('Operation Success');
                console.log(response.data);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.error(error);
            });
        },
    });

    const sendData = (values) => {
        
            const filteredValues = Object.fromEntries(
                Object.entries(values).filter(([_, v]) => v != null && v !== '' && v !== 0)
            );

            delete filteredValues.grammage && delete filteredValues.brochure_weight

            API.post('/setting', filteredValues, {
                headers: {
                    "Content-type": "application/json",
                    accept: "application/json",
                    authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                toast.success('Operation Success');
                console.log(response.data);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.error(error);
            });
    }

    const formikWeight = useFormik({
        initialValues: { grammage: gram },
        validationSchema: Yup.object({
            grammage: Yup.number().nullable(),
            nbr_pages: Yup.number().nullable(),
            format: Yup.string().nullable(),
        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            sendData(formik.values)
            formikWeightBrochure.handleSubmit()
            API.post('/setting/visit-card-weight', { grammage: values.grammage }, {
                headers: {
                    "Content-type": "application/json",
                    accept: "application/json",
                    authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.error(error);
            });
        },
    });

    const formikWeightBrochure = useFormik({
      initialValues: { brochure_type_id: stats?.brochure_type?.id, brochure_page_nbr: stats?.brochure_page_nbr },
      validationSchema: Yup.object({
            brochure_type_id: Yup.number().nullable(),
            nbr_pages: Yup.number().nullable(),
      }),
      enableReinitialize: true,
      onSubmit: (values) => {
          API.post('/setting/brochure-weight', { brochure_type_id: values.brochure_type_id, brochure_page_nbr: parseInt(values.brochure_page_nbr) }, {
              headers: {
                  "Content-type": "application/json",
                  accept: "application/json",
                  authorization: `Bearer ${access_token}`,
              },
          })
          .then((response) => {
              toast.success('Operation Success');
              console.log(response.data);
          })
          .catch((error) => {
              toast.error(error.response.data.message);
              console.error(error);
          });
      },
    });

    const getStats = () => {
        API.get('/setting', {
            headers: {
                "Content-type": "application/json",
                accept: "application/json",
                authorization: `Bearer ${access_token}`,
            },
        })
            .then((response) => {
                setStats(response.data.data);
                initialValues.grammage = response.data.data.visit_card_weight
                initialValues.brochure_weight = response.data.data.brochure_weight
            })
            .catch((error) => {
                if (error.response.status === 403) toast.error("Une erreur est survenue");
                else toast.error(error.response.data.message);
                if (error.response.status === 401) {
                    localStorage.removeItem("user");
                    navigate("/login", { replace: true });
                    navigate(0, { replace: true });
                }
            });
    };

    const getTypes = () => {
        API.get('/setting/brochure-types', {
            headers: {
                "Content-type": "application/json",
                accept: "application/json",
                authorization: `Bearer ${access_token}`,
            },
        })
            .then((response) => {
                setTypes(response.data.data);
            })
            .catch((error) => {
                if (error.response.status === 403) toast.error("Une erreur est survenue");
                else toast.error(error.response.data.message);
                if (error.response.status === 401) {
                    localStorage.removeItem("user");
                    navigate("/login", { replace: true });
                    navigate(0, { replace: true });
                }
            });
    };

    const BROCHURE_FORMATS = [
      { label: 'A0', size: '84.1 cm x 118.9 cm' },
      { label: 'A1', size: '59.4 cm x 84.1 cm' },
      { label: 'A2', size: '42.0 cm x 59.4 cm' },
      { label: 'A3', size: '29.7 cm x 42.0 cm' },
      { label: 'A4', size: '29.7 cm x 42.0 cm' },
      { label: 'A5', size: '14.8 cm x 21.0 cm' },
      { label: 'A6', size: '10.5 cm x 14.8 cm' },
      { label: 'A7', size: '7.4 cm x 10.5 cm' },
      { label: 'A8', size: '5.2 cm x 7.4 cm' },
      { label: 'A9', size: '3.7 cm x 5.2 cm' },
      { label: 'A10', size: '2.6 cm x 3.7 cm' },
      { label: 'DL', size: '9.9 cm x 21 cm' }
  ];

    useEffect(() => {
        getStats();
        getTypes();
    }, []);

    return (
        <Box display={'flex'} flexDirection={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
            }} 
            width={'100%'} gap={{
                xs: 4,
                sm: 4,
                md: 4,
                lg: 8,
                xl: 8,
            }} 
            justifyContent={'start'} py={3}>
            <ToastContainer
                style={{ top: "5rem", width: "300px" }}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            {/* bcard weight */}
            <Box>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Typography variant="h6" fontSize={15} fontWeight={600} my={4}>
                  Paramètres du Bcard: 
                  </Typography>
                </Box>
                <form
                    style={{
                        display:'flex',
                        flexDirection: 'column',
                        alignItems:'start',
                        gap:22
                    }}  
                    onSubmit={formikWeight.handleSubmit}
                >

                        <TextField
                            id="nbr_scan_wanted_per_month"
                            fullWidth
                            name="nbr_scan_wanted_per_month"
                            label="Objective (scan/mois)"
                            type="number"
                            value={formik.values.nbr_scan_wanted_per_month}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.nbr_scan_wanted_per_month && Boolean(formik.errors.nbr_scan_wanted_per_month)}
                            helperText={formik.touched.nbr_scan_wanted_per_month && formik.errors.nbr_scan_wanted_per_month}
                        />
                        <TextField
                            id="grammageBrochure"
                            fullWidth
                            name="grammage"
                            label="Grammage du carte(g/m²)"
                            type="number"
                            value={formikWeight.values.grammage}
                            onChange={(event) => {
                                setGram(event.target.value);
                                formikWeight.setFieldValue('grammage', event.target.value);
                            }}
                        />
                        <Typography variant="subtitle2" fontSize={12} mt={-2}> poid actuel: {initialValues.grammage} g</Typography>
                        <TextField
                            id="visit_card_unit_price"
                            fullWidth
                            name="visit_card_unit_price"
                            label="prix du carte"
                            type="number"
                            value={formik.values.visit_card_unit_price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.visit_card_unit_price && Boolean(formik.errors.visit_card_unit_price)}
                            helperText={formik.touched.visit_card_unit_price && formik.errors.visit_card_unit_price}
                        />


                        <FormControl sx={{display:'flex', width:'100%'}}>

                           <Box display={'flex'} flexDirection={'column'} alignItems={'stretch'} gap={3}>
                            
                            {/* types */}
                            <Box>
                                <InputLabel id="type-du-brochure">Type du Brochure</InputLabel>
                                <Select
                                  labelId="type-du-brochure"
                                  label="Type de Brochure"
                                  name="brochure_type_id"
                                  sx={{width :'100%'}}
                                  value={formikWeightBrochure.values?.brochure_type_id}
                                  onChange={(event) => {
                                        formikWeightBrochure.setFieldValue('brochure_type_id', event.target.value);
                                    }}
                                    
                                >
                                {types.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.format} - ({type.grammage}g/m)
                                    </MenuItem>
                                ))}
                                </Select>
                            </Box>

                            {/* page numbers */}
                            <Box>
                                <TextField
                                    name="brochure_page_nbr"
                                    label="Nombre Du Pages"
                                    fullWidth
                                    size="small"
                                    type="number"
                                    onBlur={formikWeightBrochure.handleBlur}
                                    value={formikWeightBrochure.values.brochure_page_nbr}
                                    onChange={(event) => {
                                        setNbrPages(event.target.value);
                                        formikWeightBrochure.setFieldValue('brochure_page_nbr', event.target.value);
                                    }}
                                />
                            </Box>
                           </Box>

                        </FormControl>

                        <TextField
                            id="brochure_unit_price"
                            fullWidth
                            name="brochure_unit_price"
                            label="prix de brochure"
                            type="number"
                            value={formik.values.brochure_unit_price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.brochure_unit_price && Boolean(formik.errors.brochure_unit_price)}
                            helperText={formik.touched.brochure_unit_price && formik.errors.brochure_unit_price}
                        />

                        <Button variant="primary" type="submit">
                            Soumettre
                        </Button>
                </form>
            </Box>

            {/* brochure weight */}
            <Box>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Typography variant="h6" fontSize={15} fontWeight={600} my={4}>
                    Paramètres du Bdisplay: 
                  </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} alignItems={'start'} gap={2}>
                    <FormControl sx={{width:'100%'}}>
                            <InputLabel id="format-label">Format du brochure</InputLabel>
                            <Select
                              label="Format du brochure"
                              id="format-label"
                              name="bdisplay_brochure_format"
                              onBlur={formik.handleBlur}
                              value={formik.values.bdisplay_brochure_format}
                              onChange={(event) => {
                                  formik.setFieldValue('bdisplay_brochure_format', event.target.value);
                              }}
                            >
                                {BROCHURE_FORMATS.map((format) => (
                                    <MenuItem defaultChecked={format.label === 'A4'} key={format.label} value={format.label}>
                                        {format.label} ({format.size})
                                    </MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    <Button variant="primary" onClick={()=> sendData(formik.values)}>
                        Soumettre
                    </Button>
                </Box>
            </Box>

            
        </Box>
    );
};

export default KPIsSettings;
