import React from "react";
import { Box, TextField, Button, Typography } from "@mui/material";

function Welcome() {
  const val = "Bienvenue sur votre espace \nAdministrateur ";

  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ height: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="h4" component="h1" align="center" style={{ wordWrap: "break-word", fontWeight: 400, marginTop: "50px" }}>
          {val}
        </Typography>
      </Box>

      {/* Lottie section */}
      <Box className="actions"></Box>
    </Box>
  );
}

export default Welcome;
