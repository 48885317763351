import React from "react";
import { Box, Typography } from "@mui/material";


import IconPerson from "../../imgs/person.svg";
import IconEntreprise from "../../imgs/entreprise_purple.svg";

const icons = {};
icons["person"] = IconPerson;
icons["entreprise_purple"] = IconEntreprise;

function MainTitle({ title, size, icon, iconWidth, marginIcon }) {
  let mIcon = "15px";

  if (marginIcon) {
    mIcon = marginIcon;
  }

  return (
    <Box  display="flex" flexDirection="row" alignItems="center" marginBottom="1.5rem">
      {icon && <img style={{ width: `${iconWidth}px`, marginRight: `${mIcon}` }} src={icons[`${icon}`]} alt="title_image" />}
      <Typography variant={size} >
        {title}
      </Typography>
    </Box>
  );
}

export default MainTitle;
