import React from "react";
import DeliveryIcon from "../../imgs/DELIVERY.svg";

function DelieryClose() {
  return (
    <div>
      <img src={DeliveryIcon} style={{ height: "50px", width: "50px" }} />
    </div>
  );
}

export default DelieryClose;
